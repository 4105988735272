import * as React from 'react';
import ShareContainer, { IShareContainerProps } from './ShareContainer';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { GetStatisticByPeriod } from '../../service/ProgressService';
import { Statistic } from '../../types/types';
import { Period } from '../../reduxTypes';
import { abbrNum } from '../../utils/NumberUtils';

interface IShareStatisticProps extends IShareContainerProps {
    period: Period
}

const Info = (props: { title: string, value: string | number }) => <Paper sx={{
    height: "100px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
}}>
    <Typography variant='h4'>{props.value}</Typography>
    <Typography variant='caption'>{props.title}</Typography>
</Paper>

const ShareStatistic: React.FunctionComponent<IShareStatisticProps> = (props) => {

    const statistic: Statistic = GetStatisticByPeriod(props.period);

    return <ShareContainer {...props}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{
            width: "100%"
        }}>
            <Grid item xs={12}>
                <Typography align='center'>Результаты за {props.period == Period.week ? "неделю" : props.period == Period.month ? "месяц" : "год"}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Info title="Тренировки" value={statistic.workoutCount} />
            </Grid>
            <Grid item xs={6}>
                <Info title="Минут" value={statistic.fullDuration} />
            </Grid>
            <Grid item xs={6}>
                <Info title="Килограмм" value={abbrNum(statistic.fullVolume, 2)} />
            </Grid>
            <Grid item xs={6}>
                <Info title="Подхода" value={statistic.fullSets} />
            </Grid>
            <Grid item xs={12} sx={{
                textAlign: "center"
            }}>
                <Typography align='center' variant='caption'>Gymbro</Typography>
            </Grid>
        </Grid>
    </ShareContainer>;
};

export default ShareStatistic;
