import React, { useEffect, useState } from 'react';
import { AddTraningProgram, GetTraningPrograms, RemoveTraningProgram, UpdateTraningProgram } from '../../../api/TraningProgramAPI';
import Modal from '../../base/Modal';
import { DefaultModalState, DefaultTraningProgram, ModalTypeAdd, ModalTypeInfo } from '../../Constants';
import CreateTraningProgram from '../../program/CreateTraningProgram';
import TraningProgramInfo from '../../program/TraningProgramInfo';
import { useLocation, useNavigate } from 'react-router-dom';
import PopularProgramLine from '../../program/PopularProgramsLine';
import FavoritePrograms from '../../program/FavoritePrograms';

export const TraningProgramScene = () => {
    const [modalInfo, setModalInfo] = useState(DefaultModalState);
    const [selectedItem, setSelectedItem] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.search.includes("create")) {
            onChangeModal(ModalTypeAdd, DefaultTraningProgram())
        } else {
            closeModal();
        }
    }, [location.search])

    const handleChangeItem = (e, field) => {
        var item = selectedItem;
        item[field] = e.target.value;
        setSelectedItem({ ...item });
    }

    const removeItem = () => {
        RemoveTraningProgram(selectedItem.id)
        closeModal();
    }

    const closeModal = () => {
        setModalInfo(DefaultModalState);
        setSelectedItem(null)
        navigate("/traning-programs")
    }

    const onChangeModal = (type, item) => {
        if (modalInfo.open) {
            closeModal();
        } else {
            setModalInfo({
                open: true,
                type: type,
                title: type == ModalTypeAdd ? "Создание программы" : null
            });
            setSelectedItem({ ...item });
        }
    }

    const saveItem = () => {
        UpdateTraningProgram(selectedItem);
        closeModal();
    }

    const addItem = (e) => {
        AddTraningProgram(selectedItem);
        closeModal();
    }

    const setExercises = (exercises) => {
        console.debug("Exercises", exercises);
        setSelectedItem({ ...selectedItem, exercises: exercises })
    }

    return <div>
        <PopularProgramLine />

        <FavoritePrograms />
        {/* {FeatureExportProgramIsEnable && <ImportEntity title='Программа тренировок' entityName='TraningProgram' afterImport={() => loadPrograms()}/>} */}

        <Modal
            open={modalInfo.open}
            onClose={(e) => onChangeModal(null, null)}
            title={modalInfo.title}>
            {modalInfo.type == ModalTypeInfo && <TraningProgramInfo
                onChange={handleChangeItem}
                traningProgram={selectedItem}
                onRemove={removeItem}
                onSave={saveItem}
                setExercises={setExercises}
                onComplete={closeModal} />}
            {modalInfo.type == ModalTypeAdd && <CreateTraningProgram
                onChange={handleChangeItem}
                item={selectedItem}
                onSave={addItem}
                setExercises={setExercises}
                onComplete={closeModal} />}
        </Modal>
    </div>
}

export default TraningProgramScene;