import { Exercise, MuscleEnum } from "../types/types";

export const GetExercises = (): Exercise[] => {
    let items: string = localStorage.getItem("exercises") || "";
    let result: Exercise[] = items.length > 0 ? JSON.parse(items) : []
    return result;
}

export const SetExercises = (exercises: Exercise[]) => {
    console.debug("Update exercises in local storage...")
    localStorage.setItem("exercises", JSON.stringify(exercises))
}

export const GetExercisesByMuscle = (muscle: MuscleEnum): Exercise[] => {
    const items = GetExercises();
    return items.filter(exercise => {
        return exercise.muscle  == muscle
    });
}

export const AddExercises = (exercise: Exercise) => {
    const items = GetExercises();
    items.push(exercise);
    SetExercises(items);
}

export const RemoveExercises = (id: string) => {
    const items = GetExercises()
        .filter(exercise => exercise.id != id);
    SetExercises(items);
}

export const UpdateExercise = (exercise: Exercise) => {
    const items = GetExercises()
        .map(e => {
            if (e.id == exercise.id) {
                return exercise;
            }

            return e;
        })
    SetExercises(items);
}

export const CountExercisesByMuscle = (muscle:  MuscleEnum) => {
    return GetExercisesByMuscle(muscle).length;
}

export const GetExerciseById = (id: string) => {
    const exercises = GetExercises();
    return exercises.find(e => e.id == id);
}

export const SearchExcersises = (query: string) => {
    return GetExercises()
        .filter(exercise => {
            var regex = new RegExp(query, "i");
            return regex.test(exercise.title)
        })
}