import React from "react";
import { Box, Button, ButtonProps } from "@mui/material"
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Store";

interface Props extends ButtonProps {
    onClick(): void,
    text: string,
    sx?: object
}

const MainButton = (props: Props) => {
    const xmasThemeEnabled = useSelector((state: RootState) => state.themePalette.enableXmaxTheme)
    return <Box sx={{
        width: "100%",
        mt: 1
    }}>
        <Button
            {...props}
            variant="contained"
            fullWidth
            color="secondary"
            size="large"

            sx={{
                ...props.sx,
                backgroundImage: xmasThemeEnabled ? "url(https://firebasestorage.googleapis.com/v0/b/gym-tracker-8d7ac.appspot.com/o/emoji-d6001c-390x8443x.png?alt=media&token=02ead866-97ee-434e-a2bc-9e6c3c50cb78)" : "none",
                backgroundSize: "50%",
                textShadow: xmasThemeEnabled ? `rgb(0, 0, 0) 2px 0px 0px, 
                rgb(0, 0, 0) 1.75517px 0.958851px 0px,
                 rgb(0, 0, 0) 1.0806px 1.68294px 0px,
                  rgb(0, 0, 0) 0.141474px 1.99499px 0px,
                   rgb(0, 0, 0) -0.832294px 1.81859px 0px,
                    rgb(0, 0, 0) -1.60229px 1.19694px 0px,
                     rgb(0, 0, 0) -1.97998px 0.28224px 0px,
                      rgb(0, 0, 0) -1.87291px -0.701566px 0px,
                       rgb(0, 0, 0) -1.30729px -1.5136px 0px,
                        rgb(0, 0, 0) -0.421592px -1.95506px 0px,
                         rgb(0, 0, 0) 0.567324px -1.91785px 0px,
                          rgb(0, 0, 0) 1.41734px -1.41108px 0px,
                           rgb(0, 0, 0) 1.92034px -0.558831px 0px` : "none"
            }}
            onClick={props.onClick}>
            {props.text}
        </Button>
    </Box>
}

export default MainButton;