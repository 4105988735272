import { Button, Grid, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import ChartCard from '../../base/ChartCard';
import CountChart from '../../base/CountChart';
import MultilineChartCard from '../../base/MultilineChartCard';
import { GetStatisticByPeriod } from '../../../service/ProgressService';
import Modal from '../../base/Modal';
import ShareStatistic from '../../share/ShareStatictic';
import { MuscleEnum, Statistic } from '../../../types/types';
import { Period } from '../../../reduxTypes';
import { Share } from '@mui/icons-material';
import MainButton from '../../base/MainButton';

const ProgressScene = () => {
    const [period, updatePeriod] = useState<Period>(Period.week)
    const [fade, updateFade] = useState(true)
    const [isOpen, setIsOpen] = useState(false);
    const [statistic, updateStatistic] = useState<Statistic>({
        workoutCount: 0,
        fullDuration: 0,
        fullSets: 0,
        fullVolume: 0,
        fullCompleteCount: 0,
        rejectedCount: 0,
        tonnageData: {
            max: 0,
            data: []
        },
        durationData: {
            max: 0,
            data: []
        },
        setsData: [{
            muscle: MuscleEnum.BICEPS,
            data: []
        }],
        volumeData: [{
            muscle: MuscleEnum.BICEPS,
            data: []
        }],
        exerciseData: [{
            exercise: {
                id: "id",
                title:"Упражнение",
                muscle: MuscleEnum.BICEPS
            }
        }],
        avgDuration: 0
    })

    useEffect(() => {
        updateStatistic(GetStatisticByPeriod(period))
    }, [period])

    const handleChangeOpen = () => {
        setIsOpen(!isOpen)
    }

    const handleChangePeriod = (e: any) => {
        updateFade(false);
        setTimeout(() => {
            updatePeriod(e.target.value)
            updateFade(true);
        }, 150)
    }


    return <div>
        <Box sx={{ display: 'flex', justifyContent: "center", pt: 2, pb: 2 }}>
            <ToggleButtonGroup
                value={period}
                size='small'
                fullWidth
                onChange={handleChangePeriod}
                exclusive>
                <ToggleButton value={Period.year} sx={{ p: "8px" }}>
                    Год
                </ToggleButton>
                <ToggleButton value={Period.week}>
                    Неделя
                </ToggleButton>
                <ToggleButton value={Period.month}>
                    Месяц
                </ToggleButton>
            </ToggleButtonGroup>
        </Box>



        <Grid container spacing={2}>
            <Grid item xs={4}>
                <CountChart
                    title="Тренировок"
                    fade={fade}
                    count={statistic.workoutCount} />
            </Grid>
            <Grid item xs={4}>
                <CountChart
                    title="Завершено"
                    fade={fade}
                    count={statistic.fullCompleteCount} />
            </Grid>
            <Grid item xs={4}>
                <CountChart
                    title="Отменено"
                    fade={fade}
                    count={statistic.rejectedCount} />
            </Grid>

            <Grid item xs={12}>
                <MainButton
                color='secondary' 
                onClick={handleChangeOpen} 
                endIcon={<Share />} text='Поделиться'/>
            </Grid>

            <Grid item xs={12}>
                <ChartCard
                    title={"Тоннаж"}
                    maxValue={statistic.tonnageData.max}
                    dataKey="volume"
                    data={statistic.tonnageData.data} />
            </Grid>
            <Grid item xs={12}>
                <ChartCard
                    title={"Длительность"}
                    maxValue={statistic.durationData.max}
                    dataKey="duration"
                    data={statistic.durationData.data} />
            </Grid>
            <Grid item xs={12}>
                <MultilineChartCard
                    title="Подходы"
                    data={statistic.setsData}
                />
            </Grid>
            <Grid item xs={12}>
                <MultilineChartCard
                    title="Объем"
                    data={statistic.volumeData}
                />
            </Grid>
        </Grid>

        <Modal
            title=''
            open={isOpen}
            onClose={handleChangeOpen}
        >
            <ShareStatistic period={period}/>
        </Modal>

    </div>
}

export default ProgressScene;