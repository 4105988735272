import { MuscleEnum } from "./types/types"

export type ThemeInitialState = {
    palette: ThemePalette,
    accent: ThemeAccent,
    enableXmaxTheme: boolean
}

export enum ThemePalette {
    light = "light", dark = "dark"
}

export enum ThemeAccent {
    yellow = "yellow", red = "red", blue = "blue", purple = "purple", green = "green", orange = "orange"
}

export type User = {
    tokenPair: TokenPair
}

export type TokenPair = {
    accessToken: string,
    refreshToken: string
}


export type IRouterSlice = {
    path: string,
    title: string,
    needBack: boolean
    actionLink?: string
}

export enum Period {
    week = "week", year = "year", month = "month" 
}


