import { FindRecipeByFilters } from "../api/RecipeAPI";
import { Nutrition, Recipe, RecipeFilter, Unit } from "../types/RecipeTypes";
import { ParseInt, ToFixed } from "../utils/NumberUtils";

var PREV_INDEXES: number[] = [];

export const FindRandomRecipe = (filters: RecipeFilter[]): Promise<Recipe> => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const recipes = FindRecipeByFilters(filters);
            if (recipes.length == 0) {
                reject("Not found")
            } else {
                let index = Math.floor(Math.random() * recipes.length);
                if (PREV_INDEXES.length >= recipes.length) {
                    PREV_INDEXES = [];
                }
                while (PREV_INDEXES.includes(index)) {
                    index = Math.floor(Math.random() * recipes.length);
                }

                PREV_INDEXES.push(index)
                resolve(recipes[index])
            }
        }, 2000)

    })
}

export const CalculateNutrition = (recipe: Recipe): Nutrition => {
    const nutrition: Nutrition = {
        calories: ToFixed(recipe.ingridients.reduce((sum, b) => sum + CalcByUnit(b.foodProduct.nutrition.calories, b.quantity, b.unit), 0) / recipe.portions, 2),
        fats: ToFixed(recipe.ingridients.reduce((sum, b) => sum + CalcByUnit(b.foodProduct.nutrition.fats, b.quantity, b.unit), 0) / recipe.portions, 2),
        proteins: ToFixed(recipe.ingridients.reduce((sum, b) => sum + CalcByUnit(b.foodProduct.nutrition.proteins, b.quantity, b.unit), 0) / recipe.portions,2),
        carbs: ToFixed(recipe.ingridients.reduce((sum, b) => sum + CalcByUnit(b.foodProduct.nutrition.carbs, b.quantity, b.unit), 0) / recipe.portions, 2)
    };
    return nutrition;
}

const CalcByUnit = (value: number, quantity: number, unit: Unit): number => {
    if (unit == Unit.GRAMMS) {
        return ToFixed(value/100 * quantity, 2);
    }

    if (unit == Unit.PIECE) {
        return ToFixed(value * quantity, 2)
    }

    return 0;
} 