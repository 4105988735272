import { ClassicSplit, ClerkStory, FiveXFive, PushAndPull } from "../Store";
import { ProgramStoreItem } from "../types/ProgramStoreTypes";

const DATABASE_NAME = "store_traning_programs"

export const GetProgramStoreItems = () : ProgramStoreItem[] => {
    let items: string = localStorage.getItem(DATABASE_NAME) || "";
    let result: ProgramStoreItem[] = items.length > 0 ? JSON.parse(items) : []
    return result.length > 0 ? result : [FiveXFive, PushAndPull, ClassicSplit];
}

export const SetProgramStoreItems = (items: ProgramStoreItem[]) => {
    localStorage.setItem(DATABASE_NAME, JSON.stringify(items))
}

export const GetProgramStoreItemById = (id: string): ProgramStoreItem | undefined => {
    return GetProgramStoreItems().find((e: ProgramStoreItem) => e.id == id);
}

export const DeleteProgramStoreItem = (id: string) => {
    let items = GetProgramStoreItems().filter(schedule => schedule.id != id);
    SetProgramStoreItems(items);
}

export const SaveProgramStoreItem = (programStoreItem: ProgramStoreItem) => {
    let items: ProgramStoreItem[] = GetProgramStoreItems()
    let target = items.find(s => s.id == programStoreItem.id);

    if (!target) {
        items.push(programStoreItem)
    } else {
        items = items
            .map(s => {
                if (s.id == programStoreItem.id) {
                    return programStoreItem;
                }
                return s;
            });
    }
    SetProgramStoreItems(items);
}