import * as React from 'react';
import TraningProgramInfo from '../../program/TraningProgramInfo';
import { TraningProgram } from '../../../types/types';
import { useNavigate, useParams } from 'react-router-dom';
import { GetTraningProgramById } from '../../../service/TraningProgramService';
import { RemoveTraningProgram } from '../../../api/TraningProgramAPI';

interface ITraningProgramInfoSceneProps {
}

const TraningProgramInfoScene: React.FunctionComponent<ITraningProgramInfoSceneProps> = (props) => {
    const [program, setProgram] = React.useState<TraningProgram | undefined>(undefined)
    const params = useParams()
    const navigate = useNavigate();

    React.useEffect(() => {
        if (params.programId) {
            setProgram(GetTraningProgramById(params.programId))
        }

    }, [params.programId])

    const onRemove = () => {
        if (!program) {
            return;
        }
        RemoveTraningProgram(program.id)
        navigate("/traning-programs/favorite")
    }

    const onComplete = () => {
        
    }

    if (program)
        return <TraningProgramInfo traningProgram={program} onRemove={onRemove} onComplete={onComplete} />;

    return <div />
};

export default TraningProgramInfoScene;
