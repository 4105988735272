import { Box, Button, Grid, Slide, Zoom } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GetExerciseById } from '../../../api/ExercisesAPI';
import { stopTimer } from '../../../redux/TimerSlice';
import MainButton from '../../base/MainButton';
import Timer from '../../base/Timer';
import { DefaultSet, GenerateProgramExercise } from '../../Constants';
import SetsTable from '../../program/SetsTable';
import { GetTraningProgramById } from '../../../service/TraningProgramService';
import { CalcExerciseVolume, CompleteWorkout, GetWorkoutById, IsActive, IsNew, RejectWorkout, SaveWorkout, StartWorkout } from '../../../service/WorkoutService';
import ChangeExercisesModal from '../../traning/ChangeExercisesModal';
import RejectWorkoutModal from '../../traning/RejectWorkoutModal';
import WorkoutStats from '../../traning/WorkoutStats';
import _ from 'underscore';
import WorkoutProcessBar from '../../workoutProcess/WorkoutProcessBar';
import { completeWorkout as COMPLETE_WORKOUT } from '../../../redux/WorkoutSlice';
import WorkoutInfo from '../../traning/WorkoutInfo';
import Carousel from '../../base/Carousel';
import ReplaceExercisesModal from '../../traning/ReplaceExercisesModal';

const WorkoutProcessScene = () => {
    const { workoutId } = useParams();
    const [index, updateIndex] = useState(1)

    const [selected, setSelected] = useState([]);
    const [isOpen, setOpen] = useState(false)
    const [isOpenReplace, setOpenReplace] = useState(false)
    const [workout, updateWorkout] = useState({ exercises: [], status: "ACTIVE" });
    const [traningProgram, updateTraningProgram] = useState({ title: "" });
    const [rejectState, updateRejectState] = useState({
        open: false,
        rejectCause: "Устал"
    });
    const timerIsActive = useSelector((state) => state.workoutTimer.isActive, _.isEqual)
    const dispatch = useDispatch();

    useEffect(() => {
        let result = GetWorkoutById(workoutId)
        updateWorkout(result)
        setSelected(result.exercises);
        let traningProgram = GetTraningProgramById(result.traningProgramId)
        updateTraningProgram(traningProgram)
    }, [workoutId])

    useEffect(() => {
        setSelected(workout.exercises)
    }, [workout])

    const handleChangeReject = () => {
        updateRejectState({ ...rejectState, open: !rejectState.open })
    }

    const onCloseReject = () => {
        updateRejectState({
            open: false,
            rejectCause: ""
        })
    }

    const handleChangeOpen = () => {
        setOpen(!isOpen)
    }


    const onClose = () => {
        onSaveExercises()
    }

    const handleChangeInput = (e, exercise, index) => {
        let target = getTargetExerciseById(exercise.id)
        if (!e.target.value.match(/^([0-9]{1,})?(\.)?(,)?([0-9]{1,})?$/))
            return;

        let newValue = e.target.value
        target.sets[index][e.target.name] = newValue
        target.volume = CalcExerciseVolume(target);
        updateWorkoutFilling(target)
    }

    const handleChangeFloat = (e, exercise, index) => {
        let target = getTargetExerciseById(exercise.id)

        let newValue = parseFloat(e.target.value.replace(",", ".")) || 0
        target.sets[index][e.target.name] = newValue
        target.volume = CalcExerciseVolume(target);
        updateWorkoutFilling(target)
    }

    const handleChangeCompleted = (e, exercise, index) => {
        let target = getTargetExerciseById(exercise.id)
        target.sets[index].completed = !target.sets[index].completed;
        target.volume = CalcExerciseVolume(target);
        updateWorkoutFilling(target)

    }

    const getTargetExerciseById = id => {
        return workout.exercises.find(e => e.id == id);
    }

    const updateWorkoutFilling = (target) => {
        updateWorkout({
            ...workout,
            exercises: workout.exercises.map(e => {
                return e.id == target.id ? target : e;
            })
        })
        SaveWorkout(workout)
    }

    const addSet = (exercise) => {
        let target = getTargetExerciseById(exercise.id);
        target.sets.push(DefaultSet());
        updateWorkoutFilling(target)
    }

    const completeWorkout = () => {
        updateWorkoutFilling(CompleteWorkout(workout));
        dispatch(stopTimer())
        dispatch(COMPLETE_WORKOUT())
        let element = document.getElementsByTagName("main")[0];
        element.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    const rejectWorkout = () => {
        updateWorkoutFilling(RejectWorkout(workout))
        onCloseReject();
        dispatch(stopTimer())
        dispatch(COMPLETE_WORKOUT())
    }

    const handleCheckItem = id => {
        if (!isSelected(id)) {
            setSelected([...selected, GenerateProgramExercise(id)])
        } else {
            setSelected(selected.filter(e => e.id != id))
        }
    }

    const onSaveExercises = () => {
        workout.exercises = selected;
        updateWorkoutFilling(workout);
        handleChangeOpen();
    }

    const isSelected = id => {
        return selected.find(e => e.id == id) || false
    }
    let sliderRef = React.createRef()

    const onReplaceExercise = (newExerciseId) => {
        workout.exercises[index - 1] = GenerateProgramExercise(newExerciseId)
        updateWorkoutFilling(workout);
        setOpenReplace(false)
    }

    const onCloseReplace = () => {
        setOpenReplace(false)
        setSelected(workout.exercises)
    }



    return <Box sx={{
        mb: 2
    }}>
        {timerIsActive && <WorkoutProcessBar onEdit={handleChangeOpen} onFinish={completeWorkout} onCancel={handleChangeReject} />}

        <Grid container spacing={2}>
            {!IsActive(workout) && <WorkoutInfo workout={workout} />}
            {!IsActive(workout) && <WorkoutStats workout={workout} />}

        </Grid>
        <Box sx={{
            pt: 2
        }}>
            <Carousel index={index} updateIndex={updateIndex}>
                {workout.exercises.map((exercise, idx) => {
                    const item = GetExerciseById(exercise.id);
                    item.volume = exercise.volume
                    return <Grid item xs={12}>
                        <SetsTable
                            infoMode={false}
                            inputMode={true}
                            item={item}
                            sets={exercise.sets}
                            handleChangeCompleted={handleChangeCompleted}
                            handleChangeInput={handleChangeInput}
                            handleChangeFloat={handleChangeFloat}
                            addSet={addSet}
                            replaceExercise={(e) => setOpenReplace(true)} />
                    </Grid>


                })}
            </Carousel>
        </Box>


        <ChangeExercisesModal
            isOpen={isOpen}
            onClose={onClose}
            handleCheckItem={handleCheckItem}
            selectedExercises={workout.exercises}
            isSelected={isSelected}
            selected={selected}
            onSaveExercises={onSaveExercises}
        />

        <ReplaceExercisesModal
            isOpen={isOpenReplace}
            onClose={onCloseReplace}
            handleCheckItem={onReplaceExercise}
            isSelected={isSelected}
            selected={selected}
        />

        <RejectWorkoutModal
            isOpen={rejectState.open}
            rejectCause={rejectState.rejectCause}
            onClose={onCloseReject}
            handleChange={(e) => updateRejectState({ ...rejectState, rejectCause: e.target.value })}
            rejectWorkout={rejectWorkout} />

        {/* {(IsNew(workout) || IsActive(workout)) && <Button onClick={handleChangeReject}>Отменить тренировку</Button>} */}
    </Box >
}

export default WorkoutProcessScene;