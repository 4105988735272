import { Box } from '@mui/material';
import * as React from 'react';
import RandomRecipe from '../../recipe/RandomRecipe';

interface IFoodSceneProps {
}

const FoodScene: React.FunctionComponent<IFoodSceneProps> = (props) => {
    return <Box sx={{
        height: "100%"
    }}>
        <RandomRecipe />
    </Box>;
};

export default FoodScene;
