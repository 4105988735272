import { Box, Button, Chip, Typography } from '@mui/material';
import * as React from 'react';
import { Recipe, RecipeFilter } from '../../types/RecipeTypes';
import { GetRecipeFiltersArray } from '../../utils/EnumUtils';
import FullscreeenLoader from '../base/FullscreenLoader';
import RandomizeRecipeLoader from './RandomizeRecipeLoader';
import { FindRandomRecipe } from '../../service/RecipeService';
import { useModal } from '../../hooks/AppHooks';
import { ModalType } from '../../appTypes';
import Modal from '../base/Modal';
import RecipeInfo from './RecipeInfo';

interface IRandomRecipeProps {

}

interface IRandomRecipeButtonProps {
    onClick(): void
}

const RandomRecipeButton = (props: IRandomRecipeButtonProps) => {
    return <Button
        onClick={props.onClick}
        color='secondary'
        variant='contained'
        sx={{
            borderRadius: "50%",
            height: "200px",
            width: "200px"
        }}>Случайный рецепт</Button>
}

const RandomRecipe: React.FunctionComponent<IRandomRecipeProps> = (props) => {
    const [selectedFilters, setSelectedFilters] = React.useState<RecipeFilter[]>([])
    const [filters, setFilters] = React.useState<RecipeFilter[]>([])
    const [loading, setLoading] = React.useState(false);
    const [recipe, setRecipe] = React.useState<Recipe | undefined>()
    const { modal, handleOpen, handleClose } = useModal();

    React.useEffect(() => {
        setFilters(GetRecipeFiltersArray().map(v => RecipeFilter[v]))
    }, [])

    const addFilter = (item: RecipeFilter) => {
        setSelectedFilters(current => [...current, item])
    }

    const deleteFilter = (item: RecipeFilter) => {
        setSelectedFilters(current => current.filter(i => i !== item))
    }

    const findRecipe = () => {
        setLoading(true);
        FindRandomRecipe(selectedFilters)
            .then(recipe => {
                setRecipe(recipe)
                handleOpen(recipe.title, ModalType.INFO);
                setLoading(false)
            })
            .catch(e => {
                handleOpen("Ничего не найдено", ModalType.INFO);
                setLoading(false);
            })
    }

    const onClose = () => {
        setRecipe(undefined);
        handleClose();
    }

    return <Box sx={{
        display: 'flex',
        justifyContent: "space-between",
        height: "100%",
        width: "100%",
        flexDirection: 'column',
        gap: 1
    }}>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{
                display: "flex",
                gap: 1,
                width: "100%",
                flexWrap: "wrap",
                flex: "1 0 50%"
            }}>
                {filters.map((item, idx) => selectedFilters.find(i => i === item)
                    ? <Chip onDelete={() => deleteFilter(item)} label={item} key={idx} />
                    : <Chip variant='outlined' onClick={() => addFilter(item)} label={item} key={idx} />)}
            </Box>
        </Box>

        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: "1 0 50%"
        }}>
            <RandomRecipeButton onClick={findRecipe} />
        </Box>

        {loading && <FullscreeenLoader loader={<RandomizeRecipeLoader />} />}

        <Modal
            open={modal.open}
            onClose={onClose}
            title={modal.title}>
            {recipe && <RecipeInfo recipe={recipe} />}
        </Modal>
    </Box>;
};

export default RandomRecipe;