import { Box, Button, Link, Typography } from '@mui/material';
import * as React from 'react';
import FeedItem from './FeedItem';
import FullscreenFeed from './FullscreenFeed';
import { Feed } from '../../types/types';
import { FeatureDonateIsEnable, FeatureFoodIsEnable, FeatureSchedulesIsEnable, FeatureXmasThemeIsEnable } from '../../utils/ProfileUtils';
import DonateButton from '../donate/DonateButton';
import MainButton from '../base/MainButton';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { TraningProgramStore } from '../../Feeds';

interface IFeedLineProps {
}

const DefaultFeeds = (navigate: NavigateFunction): Feed[] => {
    return [
        TraningProgramStore(navigate),
        {
            id: 9,
            title: "Раздел еда",
            text: <Box sx={{
                background: "rgba(0, 0, 0, 0.75)",
                p: 1,
                textAlign: "justify",
                color: 'white'
            }}>
                <Typography paragraph>
                    Процесс построения тела - это не только тренировки. Для достижения желаемых результатов необходимо качественно и разнообразно питаться.
                    В Gymbro появился раздел "Еда" и первая фича в нем - случайное блюдо. Все просто: нажимаем кнопку и получаем случайное блюдо по выбранным фильтрам.
                    Позже, данный раздел будет расширяться.
                </Typography>

                <Typography paragraph>
                    Попробуй <Link onClick={() => navigate("/food/random-recipe")}>новую функцию</Link> прямо сейчас.
                </Typography>

                <Typography>С наступающим и хорошей тренировки!</Typography>
            </Box>,
            img: "https://images.unsplash.com/photo-1555939594-58d7cb561ad1?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            imgFull: "https://images.unsplash.com/photo-1555939594-58d7cb561ad1?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            enable: FeatureFoodIsEnable,
            useGrayFilter: false
        },
        {
            id: 7,
            color: "#000",
            title: "Донаты",
            text: <Typography sx={{
                background: "rgba(0, 0, 0, 0.75)",
                p: 1,
                textAlign: "justify",
                color: 'white'
            }}>Отправив донат автору проекта, вы помогаете в поддержке и развитии Gymbro.
                Все полученные донаты будут расходоваться на улучшение приложения.
                В планах сделать Gymbro полноценным помощником не только в тренировках, но и в ежедневной спортивной рутине.
                Более подробная информация об обновлениях и работе проекта будет публиковаться в <Link href="https://t.me/gymbro_app" target="_blank">телеграм канале</Link>.
                Спасибо за поддержку и хорошей тренировки!</Typography>,
            action: <DonateButton />,
            enable: FeatureDonateIsEnable
        },
        {
            id: 6,
            title: "Расписания",
            text: <Typography sx={{
                background: "rgba(0, 0, 0, 0.75)",
                p: 1,
                textAlign: "justify",
                color: 'white'
            }}>
                В Gymbro появилась возможность создавать расписания! Теперь, можно создавать привязку определеннык тренировочных сетов к дням недели. Создав расписание, вы будете получать напоминание о тренировке а также возможность быстро начать запланированную тренировку
            </Typography>,
            img: "https://images.unsplash.com/photo-1632077804406-188472f1a810?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2670&q=80",
            imgFull: "https://images.unsplash.com/photo-1632077804406-188472f1a810?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2670&q=80",
            action: <MainButton text={"Попробовать"} onClick={() => {
                navigate(`/schedules`)
            }} />,
            enable: FeatureSchedulesIsEnable
        },
        {
            id: 1,
            title: "Статистика",
            text: <Typography sx={{
                background: "rgba(0, 0, 0, 0.75)",
                p: 1,
                textAlign: "justify",
                color: 'white'
            }}>Поделись статистикой своих тренировок в социальных сетях</Typography>,
            img: "https://images.unsplash.com/photo-1614236224416-9a88c2e195e1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2273&q=80",
            imgFull: "https://images.unsplash.com/photo-1614236224416-9a88c2e195e1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2273&q=80",
            action: <MainButton text={"Поделиться"} onClick={() => {
                navigate(`/progress`)
            }} />,
            enable: true
        }
    ]
}

const defaultFeed: Feed = {
    id: 0,
    title: "",
    color: "#000",
    enable: true
}

const FeedLine: React.FunctionComponent<IFeedLineProps> = (props) => {
    const navigate = useNavigate();
    const [feeds, setFeeds] = React.useState<Feed[]>([])
    const [open, setOpen] = React.useState(false)
    const [targetFeed, setTargetFeed] = React.useState<{ feed: Feed }>({
        feed: defaultFeed
    })

    React.useEffect(() => {
        setFeeds(DefaultFeeds(navigate).filter(f => f.enable))
    }, [])

    const onClick = (index: number) => {
        setTargetFeed({ ...targetFeed, feed: feeds[index] })
        setOpen(true)
    }

    const onClose = () => {
        setTargetFeed({
            feed: defaultFeed
        });
        setOpen(false);
    }

    return <Box sx={{
        height: "140px",
        width: "100%",
        overflow: "auto",
        gap: 1,
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        mb: 2,
        pb: 1,
        alignContent: "start"

    }}>
        {feeds.map((feed, idx) => <FeedItem key={idx} feed={feed} onClick={() => onClick(idx)} />)}

        <FullscreenFeed feed={targetFeed.feed} open={open} onClose={onClose} ttl={5000} />
    </Box>;
};

export default FeedLine;
