import { Box, Divider, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { GetLastWorkout } from '../../service/WorkoutService';
import { FormatDate } from '../../utils/DateUtils';
import { Workout } from '../../types/types';
import { GetStatisticByPeriod } from '../../service/ProgressService';
import { Period } from '../../reduxTypes';
import WeeklyWorkoutsStats from './WeeklyWorkoutsStats';

interface IHomeStatisticsProps {
}

interface IItemProps {
  value?: number | string | null,
  title: string
}

const Item: React.FunctionComponent<IItemProps> = (props) => {
  return <Grid item xs={5} sx={{
    pt: "0 !important",
    pl: "0 !important"
  }}>
    <Box sx={{
      display: 'flex',
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      pt: 1,
      pb:1
    }}>
      <Typography variant='caption'>{props.title}</Typography>
      <Typography variant='h5'>{props.value ? props.value : "-"}</Typography>
    </Box>
  </Grid>
}

const HomeStatistics: React.FunctionComponent<IHomeStatisticsProps> = (props) => {

  const lastWorkout = GetLastWorkout();
  const awgDuration = GetStatisticByPeriod(Period.week).avgDuration;
  return <Box>
    <Box sx={{
      display: "flex",
      justifyContent: "center"
    }}>
      <Grid container spacing={2} sx={{
        justifyContent: "center",
        ml: 0,
        width: "100% !important"
      }}>
        <Item value={`${awgDuration} мин`} title='Ср. продолжительность' />
        <Divider orientation="vertical" flexItem />
        <Item value={lastWorkout ? FormatDate(new Date(lastWorkout.startAt)) : null} title='Последняя тренировка' />
      </Grid>
    </Box>
    <WeeklyWorkoutsStats />
  </Box>;
};

export default HomeStatistics;
