import { Box } from '@mui/material';
import * as React from 'react';

interface IHorizontalFeedLineProps {
    children: string | JSX.Element | JSX.Element[] 
    height: number
}

const HorizontalFeedLine: React.FunctionComponent<IHorizontalFeedLineProps> = ({ children, height }) => {
    return <Box sx={{
        height: `${height}px`,
        width: "100%",
        overflow: "auto",
        overflowY: "hidden",
        gap: 1,
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        alignContent: "start",
        justifyContent: "center"

    }}>
        {children}
    </Box>;
};

export default HorizontalFeedLine;
