import * as React from 'react';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import RowingIcon from '@mui/icons-material/Rowing';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { TypeOfActivity } from '../../types/types';

interface IProgramIconProps {
    typeOfActivity?: TypeOfActivity
}

const ProgramIcon: React.FunctionComponent<IProgramIconProps> = ({ typeOfActivity }) => {
    if (typeOfActivity == TypeOfActivity.CARDIO)
        return <DirectionsRunIcon color='secondary' />;
    else if (typeOfActivity == TypeOfActivity.FUNCTIONAL_TRANING) {
        return <RowingIcon color='secondary' />
    }
    else if (typeOfActivity == TypeOfActivity.STRECHING)
        return <SelfImprovementIcon color='secondary' />
    else
        return <FitnessCenterIcon color='secondary' />
};

export default ProgramIcon;
