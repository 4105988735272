import { Button } from '@mui/material';
import * as React from 'react';
import { DanateURL } from '../../utils/ProfileUtils';

interface IDonateButtonProps {
}

const DonateButton: React.FunctionComponent<IDonateButtonProps> = (props) => {


  return <Button
    onClick={() => window.open(`${DanateURL}`)}
    fullWidth
    variant='contained'
    sx={{
      p:2,
      backgroundImage: `linear-gradient(
      70deg,
      hsl(0deg 100% 49%) 0%,
      hsl(8deg 95% 68%) 7%,
      hsl(15deg 95% 76%) 14%,
      hsl(24deg 96% 82%) 21%,
      hsl(38deg 98% 87%) 29%,
      hsl(47deg 100% 89%) 36%,
      hsl(36deg 100% 84%) 43%,
      hsl(29deg 100% 78%) 50%,
      hsl(26deg 100% 71%) 57%,
      hsl(26deg 100% 61%) 64%,
      hsl(27deg 87% 51%) 71%,
      hsl(24deg 73% 48%) 79%,
      hsl(19deg 63% 43%) 86%,
      hsl(9deg 49% 37%) 93%,
      hsl(327deg 61% 20%) 100%
    )`
    }}>Поблагодарить автора</Button>;
};

export default DonateButton;
