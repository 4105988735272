import { Button, Grow } from '@mui/material';
import * as React from 'react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

interface IShopingButtonProps {
    price: number,
    onClick(): void,
    disable?: boolean
}

const ShopingButton: React.FunctionComponent<IShopingButtonProps> = ({ price, onClick, disable = false }) => {
    return <Grow in timeout={250}>
        <Button
            disabled={disable}
            fullWidth
            variant='contained'
            color='secondary'
            onClick={onClick}
            endIcon={<ShoppingCartIcon />}>
            {price == 0 ? "Получить" : "Купить"}
        </Button>
    </Grow>;
};

export default ShopingButton;
