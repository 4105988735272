import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AddExercises, RemoveExercises, SearchExcersises, UpdateExercise } from "../../../api/ExercisesAPI";
import MainButton from "../../base/MainButton";
import Modal from "../../base/Modal";
import { DefaultExercise, ModalTypeAdd, ModalTypeInfo } from "../../Constants";
import CreateExercise from "../../exercise/CreateExercise";
import ExerciseInfo from "../../exercise/ExerciseInfo";
import ExerciseList from "../../exercise/ExerciseList";
import { useLocation, useNavigate } from "react-router-dom";
import { FeatureExportProgramIsEnable } from "../../../utils/ProfileUtils";
import ImportEntity from "../../base/ImportEntity";

const defaultModalState = {
    open: false,
    type: null,
    title: null
};

const ExercisesScene = props => {
    const [modalInfo, setModalInfo] = useState(defaultModalState);
    const [selectedItem, setSelectedItem] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.search.includes("create")) {
            onChangeModal(ModalTypeAdd, DefaultExercise())
        } else {
            closeModal();
        }
    }, [location.search])

    const handleChangeExercise = (e, field) => {
        var item = selectedItem;
        item[field] = e.target.value;
        setSelectedItem({ ...item });
    }

    const removeExercise = () => {
        RemoveExercises(selectedItem.id)
        closeModal();
        loadData()
    }

    const closeModal = () => {
        setModalInfo(defaultModalState);
        setSelectedItem(null)
        navigate("/exercises")
    }

    const onChangeModal = (type, item) => {
        if (modalInfo.open) {
            closeModal();

        } else {
            setModalInfo({
                open: true,
                type: type,
                title: type == ModalTypeAdd ? "Создание упражнения" : null
            });
            setSelectedItem({ ...item });
        }
    }

    const saveExercise = (e) => {
        UpdateExercise(selectedItem);
        closeModal();
        loadData();
    }

    const addExeecise = (e) => {
        AddExercises(selectedItem);
        closeModal();
        loadData()
    }

    const handleChange = (e) => {
        setQuery(e.currentTarget.value)
    }

    const loadData = () => {
        var allExercises = SearchExcersises(query);
        var exercises = allExercises.reduce((group, exercice) => {
            const muscle = exercice.muscle;
            group[muscle] = group[muscle] !== undefined ? group[muscle] : []
            group[muscle].push(exercice);
            group[muscle].sort((a, b) => {
                if (a.title < b.title) {
                    return -1;
                  }
                  if (a.title > b.title) {
                    return 1;
                  }
                  return 0;
                })
            return group;
        }, {})

        setExercisesByMuscle(exercises);
    }

    const [query, setQuery] = useState("");
    const [exercisesByMuscle, setExercisesByMuscle] = useState({})

    useEffect(() => {
        loadData()
    }, [query])

    return <div>

        {FeatureExportProgramIsEnable && <ImportEntity title='Упражнение' entityName='Exercise' afterImport={() => {loadData()}} />}

        <ExerciseList onClick={onChangeModal}  exercisesByMuscle={exercisesByMuscle} query={query} handleChange={handleChange}/>
        <Modal
            open={modalInfo.open}
            onClose={(e) => onChangeModal(null, null)}
            title={modalInfo.title}>
            {modalInfo.type == ModalTypeInfo && <ExerciseInfo
                onChange={handleChangeExercise}
                exercise={selectedItem}
                onRemove={removeExercise}
                onSave={saveExercise} />}
            {modalInfo.type == ModalTypeAdd && <CreateExercise
                onChange={handleChangeExercise}
                exercise={selectedItem}
                onSave={addExeecise} />}
        </Modal>
    </div>
}

export default ExercisesScene;