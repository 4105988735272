import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app"
import { getMessaging, getToken, MessagePayload, onMessage } from "firebase/messaging"
import { getDatabase } from "firebase/database";

const firebaseConfig: FirebaseOptions = {
    apiKey: "AIzaSyBs1y8_VNzBhumzLjS75qZRxi7VILD1dro",
    authDomain: "gym-tracker-8d7ac.firebaseapp.com",
    databaseURL: "https://gym-tracker-8d7ac-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "gym-tracker-8d7ac",
    storageBucket: "gym-tracker-8d7ac.appspot.com",
    messagingSenderId: "991416478354",
    appId: "1:991416478354:web:c002d9bb8178eec0db544b",
    measurementId: "G-J3YNK1KTE7"
}

export const app: FirebaseApp = initializeApp(firebaseConfig)

export const messaging = getMessaging();

export const database = getDatabase();

export const RequestForToken = () => {
    return getToken(messaging, { vapidKey: "BDOR-PQCBwf3DcHL4515G3iIEPZZGXCcIiUcz1jyhpsRDCP7_0rQBcZtzQjU0EayViFxtBKYnnYXUwmfdSQQzIM" })
        .then((currentToken) => {
            if (currentToken) {
                console.debug('current token for client: ', currentToken);
                // Perform any other neccessary action with the token
                return currentToken;
            } else {
                // Show permission request UI
                console.debug('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            console.debug('An error occurred while retrieving token. ', err);
        });
};

type IOnMessageListeneer = () => Promise<MessagePayload>;

export const onMessageListener: IOnMessageListeneer = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload: MessagePayload) => {
            console.debug("payload", payload)
            resolve(payload);
        });
    });
