import * as Recipes from "../Recipe"
import { Recipe, RecipeFilter } from "../types/RecipeTypes"

export const GetAllRecipe = ():Recipe[] => {
    return [
        Recipes.PASTA_CARBONARA,
        Recipes.VISOKOBELKOVAYA_SHAURMA,
        Recipes.CREAMY_CHESE_PASTA,
        // Recipes.PIROZJOK_S_MIASOM,
        // Recipes.BURATTA_S_POMIDORAMI,
        // Recipes.PASTA_BALANIEZA, 
        Recipes.RICE_S_KALIMARY
        // Recipes.RICE_S_KURITCEI,
        // Recipes.PANCAKE_S_PROTEIN,
        // Recipes.UDON_S_INDEIKA,
        // Recipes.YAICHNIZA_S_POMIDORAMI,
        // Recipes.BRUSKETTA_S_STRACHATELLA
    ]
}

export const FindRecipeByFilters = (filters: RecipeFilter[]) => {
    return GetAllRecipe().filter(r => Checker(r.filters, filters))
}

const Checker = (arr: any[], target: any[]) => target.every(v => arr.includes(v));