import { configureStore } from "@reduxjs/toolkit"
import FirebaseSlice, { FirebaseInitialState } from "./FirebaseSlice";
import { listenerMiddleware } from "./MiddlewareListener";
import ThemeSlice from "./ThemeSlice"
import TimerSlice, { TimerInitialState } from "./TimerSlice";
import RouterSlice, { RouterSliceInitialState } from "./RouterSlice";
import AuthSlice, { AuthInitialState } from "./AuthSlice";
import AppSlice, { AppInitialState } from "./AppSlice";
import WorkoutSlice, {WorkoutInitialState} from "./WorkoutSlice";
import UserSlice, {UserInitialState} from "./UserSlice";

const loadState = () => {
    try {
        const state = localStorage.getItem("app-settings");
        if (state != undefined)
            return JSON.parse(state);
        else
            return {
                themePalette: {
                    palette: 'light',
                    accent: "green",
                    enableXmaxTheme: false
                },
                workoutTimer: {
                    ...TimerInitialState,
                },
                firebase: {
                    ...FirebaseInitialState
                },
                router: {
                    ...RouterSliceInitialState
                },
                auth: {
                    ...AuthInitialState
                },
                app: {
                    ...AppInitialState
                },
                workout: {
                    ...WorkoutInitialState
                },
                user: {
                    ...UserInitialState
                }
            }
    } catch (e) {
        return {
            themePalette: {
                palette: 'light',
                accent: "green",
                enableXmaxTheme: false
            },
            workoutTimer: {
                ...TimerInitialState
            },
            firebase: {
                ...FirebaseInitialState
            },
            router: {
                ...RouterSliceInitialState
            },
            auth: {
                ...AuthInitialState
            },
            app: {
                ...AppInitialState
            },
            workout: {
                ...WorkoutInitialState
            },
            user: {
                ...UserInitialState
            }
        }
    }
}

const store = configureStore({
    reducer: {
        themePalette: ThemeSlice,
        workoutTimer: TimerSlice,
        firebase: FirebaseSlice,
        router: RouterSlice,
        auth: AuthSlice,
        app: AppSlice,
        workout: WorkoutSlice,
        user: UserSlice
    },
    preloadedState: loadState(),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(listenerMiddleware.middleware)
})

export default store;

export type RootState = ReturnType<typeof store.getState>;