import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { TokenPair } from "../reduxTypes"

export type AuthState = {
    authorized: boolean,
    tokenPair?: TokenPair
}

export const AuthInitialState : AuthState= {
    tokenPair: undefined,
    authorized: false
}

export const AuthSlice = createSlice({
    name: "Auth",
    initialState: AuthInitialState,
    reducers: {
        login: (state: AuthState, payload: PayloadAction<TokenPair>) => {
            state.authorized = true;
            state.tokenPair = payload.payload
        },
        logout: (state: AuthState, payload) => {
            state.authorized = false;
            state.tokenPair = undefined
        }
    }
}) 

export const { login, logout } = AuthSlice.actions;
export default AuthSlice.reducer;