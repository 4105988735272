import { Box } from '@mui/material';
import * as React from 'react';
import StoreTraningProgramsList from '../../../programStore/StoreTraningProgramsList';

interface IStoreTraningProgramSceneProps {
}

const StoreTraningProgramScene: React.FunctionComponent<IStoreTraningProgramSceneProps> = (props) => {
  return <Box>
    <StoreTraningProgramsList/>
  </Box>;
};

export default StoreTraningProgramScene;
