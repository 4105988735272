import React, { ReactNode, useEffect, useState, SyntheticEvent } from "react";
import { BottomNavigation, BottomNavigationAction, Box, Paper, styled, useTheme } from "@mui/material";

import HomeIcon from '@mui/icons-material/Home';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { Settings, Restaurant } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { FeatureFoodIsEnable } from "../../utils/ProfileUtils";
import StartWorkoutNavButton from "./StartWorkoutNavButton";

const Actions = [
    {
        value: "food/random-recipe",
        icon: <Restaurant />,
        enable: FeatureFoodIsEnable
    },
    {
        value: "progress",
        icon: <QueryStatsIcon />,
        enable: true
    },
    {
        value: "start-workout",
        icon: null,
        enable: true,
        customComponent: <StartWorkoutNavButton key={10}/>
    },
    {
        value: "home",
        icon: <HomeIcon />,
        enable: true
    },
    {
        value: "settings",
        icon: <Settings />,
        enable: true
    }
]

const Action = styled(BottomNavigationAction)(({ theme }) => ({
    color: theme.palette.mode === 'dark' ? "rgba(255,255,255,0.8)" : "rgba(1,1,1,0.6)",
    '&.Mui-selected': {
        color: theme.palette.secondary.main
    }
}))

const Navigation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedScene, setSelectedScene] = useState("home")
    useEffect(() => {
        const path = !Actions.map(a => a.value).find(a => a == location.pathname.substring(1)) ? "home" : location.pathname.substring(1);
        setSelectedScene(path)
    }, [location])

    const handleChange = (e: SyntheticEvent, newValue: string) => {
        setSelectedScene(newValue);
        navigate(`/${newValue}`)
    };

    return <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} id="navbar">
        <BottomNavigation value={selectedScene} onChange={handleChange} showLabels>
            {Actions.filter(e => e.enable).map((action, idx) => {
                return action.customComponent ? action.customComponent : <Action
                    key={idx}
                    value={action.value}
                    icon={action.icon}

                />
            })}
        </BottomNavigation>
    </Box>
}

export default Navigation;