const DATABASE_NAME = "workout";
import { Workout } from "../types/types";

export const GetWorkouts = (): Workout[] => {
    var items: string = localStorage.getItem(DATABASE_NAME) || "" ;
    var result: Workout[] = items.length > 0 ? JSON.parse(items) : [];
    return result;
}


export const SetWorkouts = (items: Workout[]) => {
    localStorage.setItem(DATABASE_NAME, JSON.stringify(items))
}

export const GetWorkoutById = (id: string): Workout | undefined => {
    return GetWorkouts().find((e: Workout) => e.id == id);
}