import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from 'react-router-dom';

interface ISeeAllProps {
    navigateTo: string
}

const SeeAll: React.FunctionComponent<ISeeAllProps> = ({navigateTo}) => {
    const navigate = useNavigate();
    return <Stack direction="row" alignItems="center" onClick={() => {navigate(navigateTo)}}>
        <Typography pb="3px">Все</Typography>
        <NavigateNextIcon />
    </Stack>

};

export default SeeAll;
