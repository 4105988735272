import * as React from 'react';
import { ProgramStoreItem } from '../../types/ProgramStoreTypes';
import { Box, Grow, Paper, Slide, Typography, useTheme } from '@mui/material';
import { GetProgramStoreItems } from '../../service/ProgramStoreService';
import { useNavigate } from 'react-router-dom';
import PriceBadge from '../base/PriceBadge';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { FindProgramByStoreId } from '../../service/TraningProgramService';

interface IStoreTraningProgramsProps {
}

interface IStoreTraningProgramsItemProps {
    item: ProgramStoreItem,
    index: number
}

const StoreTraningProgramsItem: React.FunctionComponent<IStoreTraningProgramsItemProps> = ({ item, index }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const [purchased, setPurchased] = React.useState(false)

    React.useEffect(() => {
        setPurchased(FindProgramByStoreId(item.id) != undefined)
    }, [item])

    return <Slide in direction='up' timeout={250}>
        <Paper onClick={() => navigate(`/store/traning-programs/${item.id}`)}
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: 1,
                height: "480px",
                backgroundImage: item.imgUrl ? `url(${item.imgUrl})` : 'none',
                backgroundSize: "cover",
                backgroundPosition: "center",
                color: theme.palette.secondary.contrastText,
            }}>
            <Box sx={{
                background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.95), transparent);",
                p: 1,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <Typography variant='h4'>{item.title}</Typography>
                    <PriceBadge price={item.price} purchased={purchased}/>
                </Box>

                <Typography variant='caption'>{item.shortDescription}</Typography>
            </Box>

            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center", 
                p: 1
            }}>
                <Typography variant='caption'>Кол-во сетов: {item.programs.length}</Typography>

                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1
                }}>
                    <AccessTimeIcon />
                    <Typography variant='caption'>{item.duration} мин.</Typography>
                </Box>
            </Box>

        </Paper>
    </Slide>
}

const StoreTraningProgramsList: React.FunctionComponent<IStoreTraningProgramsProps> = (props) => {
    const [items, setItems] = React.useState<ProgramStoreItem[]>([]);

    React.useEffect(() => {
        GetProgramStoreItems()
            .then(res => setItems(res))
            .catch(e => console.error(e))
    }, [])

    return <Box sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2
    }}>
        {items.map((item, idx) => <StoreTraningProgramsItem key={idx} item={item} index={idx}/>)}
    </Box>;
};

export default StoreTraningProgramsList;
