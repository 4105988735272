import * as React from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

interface IFavoriteProps {
    isFavorite: boolean,
    onClick(): void
}

const Favorite: React.FunctionComponent<IFavoriteProps> = ({isFavorite, onClick}) => {
  return isFavorite ? <FavoriteIcon color='secondary' onClick={onClick}/> : <FavoriteBorderIcon color='secondary' onClick={onClick}/>
};

export default Favorite;
