import React from 'react';
import Modal from "../base/Modal";
import ExercisesSelector from "../exercise/ExercisesSelector";
import { ExerciseItem } from '../../types/types';

interface ReplaceExercisesModalProps {
    isOpen: boolean,
    onClose(): void,
    handleCheckItem(id: string): void,
    isSelected(id: string): boolean, selected: ExerciseItem[],
}

const ReplaceExercisesModal = ({ isOpen, onClose, handleCheckItem, isSelected, selected }: ReplaceExercisesModalProps) => {
    return <Modal
        open={isOpen}
        onClose={onClose}
        title="Замена упражнения">
        <ExercisesSelector
            oneElementSelector
            handleCheckItem={handleCheckItem}
            isSelected={isSelected}
            selected={selected} />
    </Modal>
}

export default ReplaceExercisesModal;