import { Box } from '@mui/system';
import React from 'react';
import TraningProgramForm from './TraningProgramForm';
import { Kind } from '../base/EntityActionButtonGroup';
import { ICreateTraningProgram } from '../../service/TraningProgramService';
import { CreateTraningProgram as create } from '../../service/TraningProgramService';
import { TraningProgram } from '../../types/types';

interface ICreateTraningProgramProps {
    onComplete(): void
}

const CreateTraningProgram = ({ onComplete }: ICreateTraningProgramProps) => {

    const onSave = (command: ICreateTraningProgram) => {
        create(command)
            .then((program: TraningProgram) => {
                console.debug("Created", program)
                onComplete()
            })
            .catch(e => {
                console.log("Creating program error", e)
            })
    }
    return <div>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: "column", pb: 16 }}>
            <TraningProgramForm
                onSave={onSave}
                kind={Kind.CREATE}/>
        </Box>
    </div>
}
 
export default CreateTraningProgram;