import { Box, Tab, Tabs } from '@mui/material';
import * as React from 'react';
import { Ingridient, Recipe, Step } from '../../types/RecipeTypes';
import RecipeCommonInfo from './RecipeCommonInfo';
import IngridientsTable from './IngridientsTable';
import Steps from './Steps';

interface IRecipeInfoProps {
    recipe: Recipe
}

const RecipeInfo: React.FunctionComponent<IRecipeInfoProps> = (props) => {
    const [value, setValue] = React.useState("ingridients")

    const handleChangeValue = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return <Box>
        <RecipeCommonInfo recipe={props.recipe} />
        <Tabs
            onChange={handleChangeValue}
            value={value}
            variant='fullWidth'
            indicatorColor='secondary'>

            <Tab label="Ингридиенты" value="ingridients" />
            <Tab label="Приготовление" value="steps" />
        </Tabs>
        <Box sx={{
            display: "flex",
            pt: 1
        }}>

        </Box>
        {value == "ingridients" && <IngridientsTable ingridients={props.recipe.ingridients} />}
        {value == "steps" && <Steps steps={props.recipe.steps} />}
    </Box>
};

export default RecipeInfo;
