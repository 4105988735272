import { SelectChangeEvent } from "@mui/material";
import { ChangeEvent, SelectHTMLAttributes, useCallback, useState } from "react";

export const handleChangeInput = <T>(initialValue: T) => {
    const [values, setValues] = useState(initialValue);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
        setValues({ ...values, [event.target.name]: event.target.value });

    const handleChangeSelect = async (event: SelectChangeEvent) =>
        setValues({ ...values, [event.target.name]: event.target.value });

    const handleChangeCheck = (event: ChangeEvent<HTMLInputElement>) =>
        setValues({ ...values, [event.target.name]: event.target.checked })


    return { values, handleChange, handleChangeSelect,handleChangeCheck, setValues };
}