import { Box, List, ListItem, Typography } from '@mui/material';
import * as React from 'react';

interface IProfileSceneProps {
}

const ProfileScene: React.FunctionComponent<IProfileSceneProps> = (props) => {
  return <Box>
    <List>
      <ListItem>
        <Typography>Рост: </Typography>
      </ListItem>
      <ListItem>
        <Typography>Вес: </Typography>
      </ListItem>
    </List>
  </Box>;
};

export default ProfileScene;
