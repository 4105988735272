import React from 'react';
import { Fade, Paper, Slide, Typography, useTheme } from "@mui/material";
import Chart from "./Chart";
import { Box } from '@mui/system';

const ChartCard = ({ title, maxValue, data, dataKey }) => {
    const theme = useTheme();
    return <Slide direction='up' in timeout={250}>
        <Paper sx={{ p: 1, height: 220 }}>
            <Box sx={{ display: 'flex', gap: 1 }}>
                <Typography variant="h6">{title}</Typography>
                <Typography variant='h6'>Макс. {maxValue}</Typography>
            </Box>
            <Chart data={data} dataKey={dataKey} />
        </Paper>
    </Slide>
}

export default ChartCard;