import { GetExerciseById } from "../api/ExercisesAPI";
import { AddTraningProgram, GetTraningPrograms, SaveTraningProgram } from "../api/TraningProgramAPI"
import { Exercise, ExerciseItem, MuscleEnum, TraningProgram, TypeOfActivity } from "../types/types";
import { v4 as uuidv4 } from 'uuid';
import { MuscleGroupsInfo } from "../components/Constants";
import { ExerciseStoreItem, ProgramStoreItem, StoreItem, StoredExerciseItem, StoredTraningProgram } from "../types/ProgramStoreTypes";
import { CreateOrReturnExerciseFromStore } from "./ExerciseService";
import { Period } from "../reduxTypes";
import { GetWorkoutsByPeriod } from "./WorkoutService";


export const PROGRAM_NOT_FOUND = "Traning program not found";

export interface ICreateTraningProgram {
    title: string,
    description?: string,
    exercises: ExerciseItem[],
    typeOfActivity?: TypeOfActivity
}

export interface IEditTraningProgram extends ICreateTraningProgram {
    id: string
}

export const CreateTraningProgram = (command: ICreateTraningProgram): Promise<TraningProgram> => {
    let promise: Promise<TraningProgram> = new Promise((resolve, reject) => {

        try {
            var program: TraningProgram = {
                id: uuidv4(),
                title: command.title,
                description: command.description,
                exercises: command.exercises,
                typeOfActivity: command.typeOfActivity
            }

            SaveTraningProgram(program);
            resolve(program);
        } catch (e) {
            reject(e)

        }
    })

    return promise;
}

export const EditTraningProgram = (command: IEditTraningProgram): Promise<TraningProgram> => {
    return new Promise((resolve, reject) => {
        let program = GetTraningProgramById(command.id);
        if (program) {
            program.title = command.title
            program.description = command.description
            program.exercises = command.exercises
            program.typeOfActivity = command.typeOfActivity

            SaveTraningProgram(program)
            resolve(program);
        } else {
            reject(PROGRAM_NOT_FOUND)
        }
    })
}

export const GetTraningProgramById = (id: string) => {
    return GetTraningPrograms().find((e: TraningProgram) => e.id == id);
}

export const GetUniqueMuscleGroups = (exercises: ExerciseItem[]): MuscleEnum[] => {
    const res = [...new Set(exercises
        .map((item: ExerciseItem) => {
            let exercise = GetExerciseById(item.id);
            return exercise ? exercise.muscle : null
        })
        .filter(notEmpty))]
    return res;
}

export const GetUniqueMuscleGroupsFromStoredExerciseItem = (exercises: StoredExerciseItem[]): MuscleEnum[] => {
    const res = [...new Set(exercises
        .map((item: StoredExerciseItem) => {
            return item.exercise.muscle
        })
        .filter(notEmpty))]
    return res;
}

export const JoinUniqueMuscleGroups = (exercises: ExerciseItem[]): string => {
    return GetUniqueMuscleGroups(exercises)
        .map((item: MuscleEnum) => MuscleGroupsInfo[item] ? MuscleGroupsInfo[item].locale : '')
        .join(", ");
}

export const JoinUniqueMuscleGroupsFromStoredExerciseItem = (exercises: StoredExerciseItem[]): string => {
    return GetUniqueMuscleGroupsFromStoredExerciseItem(exercises)
        .map((item: MuscleEnum) => MuscleGroupsInfo[item] ? MuscleGroupsInfo[item].locale : '')
        .join(", ");
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

export const GetPopularProgramsFormStore = (limit: number) => {
    return GetTraningPrograms().slice(0, limit);
}

export const GetFavoritePrograms = (limit: number) => {
    return GetTraningPrograms().slice(0, limit);
}

export const CreateProgramsByStoreItem = (storeItem: ProgramStoreItem): Promise<TraningProgram[]> => {
    const promise: Promise<TraningProgram[]> = new Promise((resolve, reject) => {
        try {
            const existedProgram = FindProgramByStoreId(storeItem.id);
            if (existedProgram) {
                reject("Program already bayed")
                return;
            }
            const programs = storeItem.programs.map((item) => CreateProgramByStore(item, storeItem.id));

            resolve(programs);
        } catch (e) {
            reject("Programs not created")
        }

    });

    return promise;
}

const CreateProgramByStore = (item: StoredTraningProgram, storeItemId: string): TraningProgram => {
    const program = {
        id: uuidv4(),
        title: item.title,
        description: item.description,
        typeOfActivity: item.typeOfActivity,
        duration: item.duration,
        exercises: item.exercises.map((e) => {
            const exercise: Exercise = CreateOrReturnExerciseFromStore(e);
            return {
                id: exercise.id,
                sets: e.sets,
                volume: 0
            }
        }),
        storeItemId: storeItemId
    };
    SaveTraningProgram(program)
    return program;
}

export const FindProgramByStoreId = (storeItemId: string) => {
    return GetTraningPrograms().find(p => p.storeItemId == storeItemId);
}

export const GetGroupedPrograms = (): { [key in string]: TraningProgram[] } => {
    return GetFavoritePrograms(1000)
        .reduce((result: { [key in string]: TraningProgram[] }, value) => {
            const storeItemId = value.storeItemId ? value.storeItemId : "default"
            result[storeItemId] = result[storeItemId] ? result[storeItemId] : []
            result[storeItemId].push(value)
            return result;
        }, {})
}

export const GetAllProgramsSortedByPopular = (): TraningProgram[] => {
    return GetTraningPrograms().map(program => {
        const usage: ProgramUsage = {
            count: CountUsageProgramInPeriod(Period.month, program.id),
            program: program

        } 
        return usage;
    })
    .sort((a, b) => a.count > b.count ? -1 : 1)
    .map(p => p.program)
}

const CountUsageProgramInPeriod = (period: Period, programId: string) => {
    return GetWorkoutsByPeriod(period).filter(w => w.traningProgramId == programId).length;
}

type ProgramUsage = {
    count: number,
    program: TraningProgram
}