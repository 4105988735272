import { AddExercises, GetExercises } from "../api/ExercisesAPI";
import { Exercise, ExerciseItem, MuscleEnum } from "../types/types"
import { StoredExerciseItem } from "../types/ProgramStoreTypes"
import { v4 as uuidv4 } from 'uuid';

export const GenerateExercise = (id: string): ExerciseItem => {
    return {
        id: id,
        volume: 0,
        sets: [
            {
                repeats: 10,
                completed: false,
                weight: 0
            },
            {
                repeats: 10,
                completed: false,
                weight: 0
            },
            {
                repeats: 10,
                completed: false,
                weight: 0
            }
        ]
    }
}


export const CreateOrReturnExerciseFromStore = (item: StoredExerciseItem): Exercise => {
    let exercise = FindByTitleAndMuscle(item.exercise.title, item.exercise.muscle);
    if (!exercise) {
        exercise = {
            id: uuidv4(),
            title: item.exercise.title,
            muscle: item.exercise.muscle,
            imgUrls: item.exercise.imgUrls,
            description: item.exercise.description
        };
        AddExercises(exercise);
    }
    return exercise;
}

export const FindByTitleAndMuscle = (title: string, muscle: MuscleEnum): Exercise | undefined => {
    return GetExercises().find(e => e.title == title && e.muscle == muscle);
}
