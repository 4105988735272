import * as React from 'react';
import HorizontalFeedLine from '../base/HorizontalElementLine';
import { TraningProgram } from '../../types/types';
import TraningProgramCard from './TraningProgramCard';
import { GetPopularProgramsFormStore } from '../../service/TraningProgramService';
import { Box, Typography } from '@mui/material';
import SeeAll from '../base/SeeAll';
import { useNavigate } from 'react-router-dom';
import StoreTraningProgramCard from '../programStore/StoreTraningProgramCard';
import { GetProgramStoreItems } from '../../service/ProgramStoreService';
import { ProgramStoreItem } from '../../types/ProgramStoreTypes';

interface IPopularProgramListProps {
}

const PopularProgramLine: React.FunctionComponent<IPopularProgramListProps> = (props) => {
    const [programs, setPrograms] = React.useState<ProgramStoreItem[]>([])
    const navigate = useNavigate();

    React.useEffect(() => {
        GetProgramStoreItems(3).then(res => setPrograms(res))
    }, [])

    return <Box sx={{
        display: 'flex',
        gap: 1,
        flexDirection: "column"
    }}>
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between'
        }}>
            <Typography variant='h6'>Популярные программы</Typography>
            <SeeAll navigateTo='/store/traning-programs'/>
        </Box>
        <HorizontalFeedLine height={180}>
            {programs.map((program, idx) =>
                <StoreTraningProgramCard
                    key={idx}
                    item={program}
                    onClick={() => { navigate(`/store/traning-programs/${program.id}`) }} />)}
        </HorizontalFeedLine>
    </Box>

};

export default PopularProgramLine;
