import { Box, } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { Outlet, useLocation } from 'react-router-dom';
import Navigation from './Navigation'
import { RootState } from '../../redux/Store';
import { tickTimer, TimerState } from '../../redux/TimerSlice';
import { changePath } from '../../redux/RouterSlice';
import NotificationComponent from './NotificationComponent';
import { NavBar } from './NavBar';
import { AppState } from '../../redux/AppSlice';
import FullscreeenLoader from './FullscreenLoader';
import ActiveWorkoutBar from './ActiveWorkoutBar';
import InstallPWA from './InstallPWA';

const Layout = () => {
    const dispatch = useDispatch();
    const timerState: TimerState = useSelector((state: RootState) => state.workoutTimer);
    const appState: AppState = useSelector((state: RootState) => state.app);
    const activeWorkoutId = useSelector((state: RootState) => state.workout.activeWorkoutId);
    const [navbarHeight, setNavbarHeight] = React.useState(56)
    const location = useLocation();
    useEffect(() => {
        dispatch(changePath(location.pathname))
    }, [location.pathname])

    useEffect(() => {
        let interval: NodeJS.Timer;
        if (timerState.isActive) {
            interval = setInterval(() => {
                dispatch(tickTimer(1000))
            }, 1000);
        }
        return () => clearInterval(interval)
    }, [timerState])

    React.useEffect(() => {
        let element = document.getElementById("active-workout-bar");
        if (element != null) {
            let height = element.clientHeight;
            setNavbarHeight(height || 56)
        }
    }, [])

    const hasActiveWorkout = activeWorkoutId != undefined && !location.pathname.includes(`workout-process/${activeWorkoutId}`)
    

    return <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', pb: 7, pt: 0 }}>
        {appState.loading && <FullscreeenLoader />}
        <NavBar />
        <Box component={"main"} sx={{
            p: 1,
            width: '100%',
            height: `calc(100%)`,
            overflowY: "scroll",
            pt: `${navbarHeight + 8}px`,
            bgcolor: 'background.default',
            color: 'text.primary',
            mb: hasActiveWorkout ? `${navbarHeight}px` : 0,
            pb: hasActiveWorkout ? `${navbarHeight}px` : 0,
            scrollbarWidth: 0,
            
        }}>
            <Outlet />
            {hasActiveWorkout && <ActiveWorkoutBar />}
            <Navigation />
        </Box>
        <NotificationComponent />
    </Box>
}

export default Layout;