import { addDays, eachDayOfInterval, format, subDays, subHours, startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear } from "date-fns";
import { ru } from "date-fns/locale";

export const GetHHmmSSFromDuration = (duration) => {
    const seconds = Math.floor((duration / 1000) % 60);
    const minutes = Math.floor((duration / 1000 / 60) % 60);
    const hours = Math.floor((duration / 1000 / 3600) % 24)

    const humanized = [
        PadTo2Digits(hours.toString(), 2),
        PadTo2Digits(minutes.toString(), 2),
        PadTo2Digits(seconds.toString(), 2),
    ].join(':');

    return humanized;
}

export const PadTo2Digits = (num) => {
    return num.toString().padStart(2, '0');
}

export const FormatDateTime = (date) => {
    return (
        [
            PadTo2Digits(date.getUTCDate()),
            PadTo2Digits(date.getUTCMonth() + 1),
            date.getFullYear()
        ].join('.') +
        ' ' +
        [
            PadTo2Digits(date.getUTCHours()),
            PadTo2Digits(date.getUTCMinutes()),
            PadTo2Digits(date.getUTCSeconds()),
        ].join(':')
    );
}

export const FormatDate = (date) => {
    return (
        [
            PadTo2Digits(date.getUTCDate()),
            PadTo2Digits(date.getUTCMonth() + 1),
            date.getFullYear()
        ].join('.')
    );
}

export const FormatTime = (date) => {
    return (
        [
            PadTo2Digits(date.getHours()),
            PadTo2Digits(date.getMinutes())
        ].join(':')
    );
}

export const SecondsBetweenDates = (from, to) => {
    console.debug(`From ${from} - to ${to}`)
    return (to.getTime() - from.getTime());
}

export const GetDateRangeByPeriod = period => {
    let date = new Date();
    if (period == "week") {
        date = StartOfWeek()
    }
    if (period == "year") {
        date = StartOfYear()
    }
    if (period == "month") {
        date = StartOfMonth()
    }

    return { to: new Date(), from: date }
}

export const GetDaysByRange = (startDaysCount, endDaysCount) => {
    const now = new Date(); // get current date
    const buffer = subDays(now, startDaysCount)

    const result = [];

    for (let i = 0; i <= startDaysCount + endDaysCount; i++) {
        result.push(addDays(buffer, i))
    }

    return result;

}

export const DatesIsEquals = (dateLeft, dateRight) => {
    return FormatDate(dateLeft) == FormatDate(dateRight);
}

export const GetMonthName = (date) => {
    return format(date, "LLLL", { locale: ru });
}

export const GetDaysOfWeek = () => {
    const daysOfWeek = eachDayOfInterval({
        start: new Date(), end: new Date(new
            Date().setDate(new Date().getDate() + 6))
    });

    const weekdays = daysOfWeek.map((day) => format(day, 'EEE', { locale: ru }).substring(0, 2));
    return weekdays;
}

export const StartOfMonth = () => {
    return startOfMonth(new Date(), {weekStartsOn: 1});
}

export const EndOfMonth = () => {
    return endOfMonth(new Date(), {weekStartsOn: 1})
}

export const StartOfWeek = () => {
    return startOfWeek(new Date(), {weekStartsOn: 1})
}

export const EndOfWeek = () => {
    return endOfWeek(new Date(), {weekStartsOn: 1})
}

export const StartOfYear = () => {
    return startOfYear(new Date(), {weekStartsOn: 1})
}

export const EndOfYear = () => {
    return endOfYear(new Date(), {weekStartsOn: 1})
}