import { Recipe, RecipeFilter, Unit } from "./types/RecipeTypes";

export const CREAMY_CHESE_PASTA: Recipe = {
    id: "12",
    title: "Сливочная паста с индейкой и камамбером",
    portions: 8,
    mainImgUrl: "https://cdn.inmyroom.ru/uploads/food_recipe/teaser/67/6735/6735bd3f-f4ed-4121-bf47-41fb9a004987.jpg",
    ingridients: [
        {
            quantity: 450,
            unit: Unit.GRAMMS,
            foodProduct: {
                id: "1",
                title: "Паста",
                nutrition: {
                    proteins: 14,
                    fats: 2,
                    carbs: 69.7,
                    calories: 359
                }
            }
        },
        {
            quantity: 700,
            unit: Unit.GRAMMS,
            foodProduct: {
                id: "2",
                title: "Филе бедра индейки",
                nutrition: {
                    proteins: 18,
                    fats: 10,
                    carbs: 0,
                    calories: 160
                }
            }
        },
        {
            quantity: 500,
            unit: Unit.GRAMMS,
            foodProduct: {
                id: "3",
                title: "Сливки 20%",
                nutrition: {
                    proteins: 2.7,
                    fats: 20,
                    carbs: 4,
                    calories: 207
                }
            }
        },
        {
            quantity: 100,
            unit: Unit.GRAMMS,
            foodProduct: {
                id: "4",
                title: "Сыр камамбер",
                nutrition: {
                    proteins: 15.3,
                    fats: 28.8,
                    carbs: 0.1,
                    calories: 324
                }
            }
        },
        {
            quantity: 1,
            unit: Unit.PIECE,
            foodProduct: {
                id: "5",
                title: "Лук",
                nutrition: {
                    proteins: 0,
                    fats: 0,
                    carbs: 0,
                    calories: 0
                }
            }
        },
        {
            quantity: 3,
            unit: Unit.PIECE,
            foodProduct: {
                id: "6",
                title: "Зубчики чеснока",
                nutrition: {
                    proteins: 0,
                    fats: 0,
                    carbs: 0,
                    calories: 0
                }
            }
        },
        {
            quantity: 1,
            unit: Unit.PIECE,
            foodProduct: {
                id: "7",
                title: "Соль, перец, базилик",
                nutrition: {
                    proteins: 0,
                    fats: 0,
                    carbs: 0,
                    calories: 0
                }
            }
        },
    ],
    steps: [
        {
            description: `Филе бедра нарезать кубиками. Кусочки не должны быть слишком маленькими, чтобы мясо осталось сочным. Посолить и поперчить. 
            Перемешать и оставить в стороне.`
        },
        {
            description: `Поставить воду для пасты`
        },
        {
            description: `Почистить и нарезать лук и чеснок.`
        },
        {
            description: `В разогретой скоророде обжарить лук на сливочном масле до легкой залотистости. После добавить нарезаный чеснок и мясо.`
        },
        {
            description: `Обжариваем мясо до появления корочки, после чего снижаем огонь до 1/3 мощности`
        },
        {
            description: `В это время немного подогреваем сливки в микроволновой печи. Чтобы сливки сразу не свернулись, необходимо чтобы их тембература была выше комнатной - градусов 40`
        },
        {
            description: `Разогретые сливки добавляем к мясу. Мощность комфорки должна быть выставлена так, чтобы сливки люши немного "булькали"`
        },
        {
            description: `Нарезаем камамбер на пластинки и добавляем в сливки`
        },
        {
            description: `Примерно в это время должна закипеть вода для пасты. Подсаливаем и варим указанное на упаковке время до состояние al dente.`
        },
        {
            description: `Томим мясо в сливках пока варится паста. Примерно 10-12 минут.`
        },
        {
            description: `По готовности пасты выключаем плиту и смешиваем с мясом и сливками. Тчательно перемешиваем`
        },
        {
            description: `Блюдо готово. Для подачи можно посыпать нарезанной петрушкой. Приятного аппетита`
        }
    ],
    duration: 60,
    filters: [RecipeFilter.CHEAT_MEAL]
}

export const VISOKOBELKOVAYA_SHAURMA: Recipe = {
    id: "11",
    title: "Высокобелковая шаурма",
    mainImgUrl: "https://karsi-kotelniki.ru/image/cache/catalog/Shaurma/shaurma-iz-kuricy-500x500.jpeg",
    portions: 1,
    ingridients: [
        {
            quantity: 1,
            unit: Unit.PIECE,
            foodProduct: {
                id: "1",
                title: "Лаваш",
                nutrition: {
                    proteins: 8.5,
                    fats: 1,
                    carbs: 56,
                    calories: 267
                }
            }
        },
        {
            quantity: 200,
            unit: Unit.GRAMMS,
            foodProduct: {
                id: "2",
                title: "Филе индейки",
                nutrition: {
                    proteins: 20,
                    fats: 4,
                    carbs: 0,
                    calories: 116
                }
            }
        },
        {
            quantity: 140,
            unit: Unit.GRAMMS,
            foodProduct: {
                id: "2",
                title: "Греческий йогурт",
                nutrition: {
                    proteins: 8,
                    fats: 2,
                    carbs: 4.2,
                    calories: 67
                }
            }
        },
        {
            quantity: 10,
            unit: Unit.GRAMMS,
            foodProduct: {
                id: "3",
                title: "Чеснок",
                nutrition: {
                    proteins: 0,
                    fats: 0,
                    carbs: 0,
                    calories: 0
                }
            }
        },
        {
            quantity: 100,
            unit: Unit.GRAMMS,
            foodProduct: {
                id: "4",
                title: "Овощи (помидоры, огурцы)",
                nutrition: {
                    proteins: 0,
                    fats: 0,
                    carbs: 0,
                    calories: 0
                }
            }
        },
        {
            quantity: 10,
            unit: Unit.GRAMMS,
            foodProduct: {
                id: "5",
                title: "Специи (соль, перец, паприка капченая)",
                nutrition: {
                    proteins: 0,
                    fats: 0,
                    carbs: 0,
                    calories: 0
                }
            }
        }
    ],
    steps: [
        {
            description: `(соус) Боковой стороной ножа раздавливаем зубчики чеснока и мелко шенкуем. 
            Смешиваем греческий йогурт, чеснок, соль, перец и паприку. Так же можно дополнительно добавить сущеный чеснок. Все специи добавляем по вкусу. 
            Перемешиваем до однородной текстуры и оставляем настаиваться.`
        },
        {
            description: `На грилле или сковороде(с небольшим кол-вом масла) обжариваем индейку до полной готовности. 
            После приготовления нарезаем на мелкие кусочки.`
        },
        {
            description: `Пока индейка обжаривается нарезаем огурци и помидоры кубиками. Так же можно добавить зеленый лучок.`
        },
        {
            description: `Раскладываем лаваш. На более короткую сторону выкладываем мясо, добавляем соус и овощи. Подгибаем с длинных сторон и сворачиваем в колбаску. 
            Количество слоев на ваше усмотрение. Если лаваша много - просто отрываем лишнее.`
        },
        {
            description: `На раскаленном гриле или сковороде(без масла) обжариваем получившуюся шаурму до желаемого состояния.`
        },
        {
            description: `Блюдо готово. Приятного аппетита`
        }
    ],
    duration: 20,
    filters: [RecipeFilter.HIGHT_PROTEIN]
}

export const PASTA_CARBONARA: Recipe = {
    id: "1",
    portions: 2,
    title: "Паста карбонара",
    mainImgUrl: "https://s23209.pcdn.co/wp-content/uploads/2014/03/IMG_2626edit.jpg",
    ingridients: [
        {
            quantity: 2,
            unit: Unit.PIECE,
            foodProduct: {
                id: "1",
                title: "Яичный желток",
                nutrition: {
                    proteins: 3.2,
                    fats: 6,
                    carbs: 0,
                    calories: 70
                }
            }
        },
        {
            quantity: 200,
            unit: Unit.GRAMMS,
            foodProduct: {
                id: "2",
                title: "Спагетти",
                nutrition: {
                    proteins: 14,
                    fats: 2,
                    carbs: 69.7,
                    calories: 359
                }
            }
        },
        {
            quantity: 50,
            unit: Unit.GRAMMS,
            foodProduct: {
                id: "3",
                title: "Сыр пармезан",
                nutrition: {
                    proteins: 29,
                    fats: 34,
                    carbs: 3,
                    calories: 434
                }
            }
        },
        {
            quantity: 1,
            unit: Unit.TASTE,
            foodProduct: {
                id: "4",
                title: "Соль",
                nutrition: {
                    proteins: 10,
                    fats: 10,
                    carbs: 5,
                    calories: 70
                }
            }
        },
        {
            quantity: 1,
            unit: Unit.TASTE,
            foodProduct: {
                id: "5",
                title: "Перец",
                nutrition: {
                    proteins: 10,
                    fats: 10,
                    carbs: 5,
                    calories: 70
                }
            }
        }
    ],
    steps: [
        {
            description: `Разогреваем воду для варки спагетти. Когда вода закипит подсаливаем воду и закладываем пасту. 
            Варим указанное на упаковке время до состояние al dente.`
        },
        {
            description: `Пока вода нагревается нарезаем бекон мелкими кубиками. 
            На небольшом огне обжареваем бекон и вытапливаем жир. 
            Сделим, чтобы бекон не подгорал. Когда большая часть жира выпотипся можно увеличить огонь и поджарить до грусткости. 
            Жир не сливаем.`
        },
        {
            description: `Для соуса отделяем желтки от белков. Добавляем соль, перец и тертый сыр(в идеале пармезан). Чательно перемешиваем и отставляем`
        },
        {
            description: `Когда паста будет готова перекладываем ее к бекону. Оставляем один стакан воды от варки. 
            Добавляем ранее приготовленный соус, немного воды и интенсивными движениями все перемешиваем. Добавляем воду от пасты до момента получения нужно консистенции.`
        },
        {
            description: `Выкладываем готовое блюдо на тарелку и по желанию посыпаем сыром.`
        }
    ],
    duration: 30,
    filters: [RecipeFilter.CHEAT_MEAL]
}

export const PIROZJOK_S_MIASOM: Recipe = {
    id: "2",
    title: "Пирожки с мясом",
    portions: 2,
    ingridients: [],
    steps: [],
    duration: 1000,
    filters: [RecipeFilter.CHEAT_MEAL]
}

export const BURATTA_S_POMIDORAMI: Recipe = {
    id: "3",
    title: "Буратта с помидорами",
    portions: 1,
    ingridients: [],
    steps: [],
    duration: 1000,
    filters: [RecipeFilter.CHEAT_MEAL, RecipeFilter.GLUTEN_FREE]
}

export const PASTA_BALANIEZA: Recipe = {
    id: "4",
    portions: 4,
    title: "Паста баланьезе",
    ingridients: [],
    steps: [],
    duration: 1000,
    filters: [RecipeFilter.CHEAT_MEAL]
}

export const RICE_S_KURITCEI: Recipe = {
    id: "5",
    portions: 1,
    title: "Рис с курицей",
    ingridients: [],
    steps: [],
    duration: 1000,
    filters: [RecipeFilter.GLUTEN_FREE, RecipeFilter.LACTOSE_FREE, RecipeFilter.HIGHT_PROTEIN]
}

export const PANCAKE_S_PROTEIN: Recipe = {
    id: "6",
    title: "Протеиновые панкейки",
    portions: 4,
    ingridients: [],
    steps: [],
    duration: 1000,
    filters: [RecipeFilter.HIGHT_PROTEIN, RecipeFilter.SWEET]
}

export const UDON_S_INDEIKA: Recipe = {
    id: "7",
    title: "Удон с индейкой",
    portions: 4,
    ingridients: [],
    steps: [],
    duration: 1000,
    filters: [RecipeFilter.LACTOSE_FREE]
}

export const YAICHNIZA_S_POMIDORAMI: Recipe = {
    id: "8",
    title: "Яичница с помидорами",
    portions: 1,
    ingridients: [],
    steps: [],
    duration: 1000,
    filters: [RecipeFilter.HIGHT_PROTEIN]
}

export const BRUSKETTA_S_STRACHATELLA: Recipe = {
    id: "9",
    title: "Брускетта со страчателлой",
    portions: 2,
    mainImgUrl: "https://img.iamcook.ru/2023/upl/recipes/cat/u-7e7bfca7e8c7c18bec1be156e99614e5.jpg",
    ingridients: [
        {
            quantity: 100,
            unit: Unit.GRAMMS,
            foodProduct: {
                id: "1",
                title: "Брускетта",
                nutrition: {
                    proteins: 8.2,
                    fats: 1.5,
                    carbs: 0,
                    calories: 53.2
                }
            }
        },
    ],
    steps: [],
    duration: 1000,
    filters: [RecipeFilter.CHEAT_MEAL]
}

export const RICE_S_KALIMARY: Recipe = {
    id: "10",
    title: "Рис с тушками кальмара",
    mainImgUrl: "https://aif-s3.aif.ru/images/017/468/ae3cd9c716ae0eb243842dbe67287a8a.JPG",
    portions: 1,
    ingridients: [
        {
            quantity: 200,
            unit: Unit.GRAMMS,
            foodProduct: {
                id: "1",
                title: "Филе кальмара",
                nutrition: {
                    proteins: 18,
                    fats: 2.2,
                    carbs: 0,
                    calories: 92
                }
            }
        },
        {
            quantity: 100,
            unit: Unit.GRAMMS,
            foodProduct: {
                id: "2",
                title: "Рис жасмин",
                nutrition: {
                    proteins: 7.2,
                    fats: 0.4,
                    carbs: 76.3,
                    calories: 343
                }
            }
        },
        {
            quantity: 20,
            unit: Unit.GRAMMS,
            foodProduct: {
                id: "3",
                title: "Масло сливочное",
                nutrition: {
                    proteins: 0.85,
                    fats: 81.11,
                    carbs: 0.06,
                    calories: 717
                }
            }
        },
        {
            quantity: 3,
            unit: Unit.PIECE,
            foodProduct: {
                id: "4",
                title: "Зубчики чеснока",
                nutrition: {
                    proteins: 0,
                    fats: 0,
                    carbs: 0,
                    calories: 0
                }
            }
        },
        {
            quantity: 10,
            unit: Unit.GRAMMS,
            foodProduct: {
                id: "5",
                title: "Сок лимона",
                nutrition: {
                    proteins: 0,
                    fats: 0,
                    carbs: 0,
                    calories: 0
                }
            }
        },
        {
            quantity: 1,
            unit: Unit.TASTE,
            foodProduct: {
                id: "6",
                title: "Соль, перец",
                nutrition: {
                    proteins: 0,
                    fats: 0,
                    carbs: 0,
                    calories: 0
                }
            }
        },
    ],
    steps: [
        {
            description: `Цельные тушки кальмара промываем, отчищаем от внутренностей и хитина. Выкладываем на доску и обсушиваем бумажными полотенцами.
            Так как в кальмаре очень много воды, для образования вкусной корочки необходимо обсушить внешнюю поверхность.`
        },
        {
            description: `Ставим воду для риса. Если варим в пакетике - варим по инструкции. Иначе, хорошо промываем рис в холодной воде. Перекладываем в кастрюлю и заливаем водой. Соотношение воды и риса 1/1.5.
            Когда вода закипает, убавляем огонь до половины мощности и оставляем на 10 минут. После выключаем и оставляем под крышкой. Солим по вкусу.`
        },
        {
            description: `Хорошо разогреваем сковороду, но не "докрасна". Добавляем сливочное масло, мелко нарезанный чеснок и цельные тушки кальмара. Обжариваем по 1.5 - 2 минуты.
            Если кальмара передержать, то он станет "резиновым". Желательно этого избежать.`
        },
        {
            description: `Подаем с рисом. При подаче поливаем тушки соком лимона, солим и перчим по вкусу`
        },
        {
            description: `Блюдо готово. Приятного аппетита!`
        }
    ],
    duration: 30,
    filters: [RecipeFilter.HIGHT_PROTEIN, RecipeFilter.GLUTEN_FREE, RecipeFilter.LACTOSE_FREE]
}

export const GRIBNAYA_PASTA_S_KURITSEY: Recipe = {
    id: "11",
    title: "Паста с курицей под сливочно-грибным соусом",
    mainImgUrl: "https://www.patee.ru/r/x6/11/07/a5/960m.jpg",
    portions: 8,
    ingridients: [
        {
            quantity: 1000,
            unit: Unit.GRAMMS,
            foodProduct: {
                id: "1",
                title: "Филе бедра куриное",
                nutrition: {
                    proteins: 21.3,
                    fats: 11,
                    carbs: 0.1,
                    calories: 185
                }
            }
        },
        {
            quantity: 450,
            unit: Unit.GRAMMS,
            foodProduct: {
                id: "2",
                title: "Паста",
                nutrition: {
                    proteins: 14,
                    fats: 2,
                    carbs: 69.7,
                    calories: 359
                }
            }
        },
        {
            quantity: 300,
            unit: Unit.GRAMMS,
            foodProduct: {
                id: "3",
                title: "Шампиньоны",
                nutrition: {
                    proteins: 4.3,
                    fats: 1,
                    carbs: 0.1,
                    calories: 27
                }
            }
        },
        {
            quantity: 100,
            unit: Unit.GRAMMS,
            foodProduct: {
                id: "4",
                title: "Грибы сушеные",
                nutrition: {
                    proteins: 35.4,
                    fats: 5.4,
                    carbs: 33.2,
                    calories: 315
                }
            }
        },
        {
            quantity: 0.5,
            unit: Unit.LITER,
            foodProduct: {
                id: "7",
                title: "Сливки 20%",
                nutrition: {
                    proteins: 2.7,
                    fats: 20,
                    carbs: 4,
                    calories: 207
                }
            }
        },
        {
            quantity: 1,
            unit: Unit.PIECE,
            foodProduct: {
                id: "5",
                title: "Лук",
                nutrition: {
                    proteins: 0,
                    fats: 0,
                    carbs: 0,
                    calories: 0
                }
            }
        },
        {
            quantity: 3,
            unit: Unit.PIECE,
            foodProduct: {
                id: "6",
                title: "Зубчики чеснока",
                nutrition: {
                    proteins: 0,
                    fats: 0,
                    carbs: 0,
                    calories: 0
                }
            }
        },
        {
            quantity: 1,
            unit: Unit.TASTE,
            foodProduct: {
                id: "8",
                title: "Соль, перец, размарин, темьян, сушеный чеснок",
                nutrition: {
                    proteins: 0,
                    fats: 0,
                    carbs: 0,
                    calories: 0
                }
            }
        },
        {
            quantity: 30,
            unit: Unit.GRAMMS,
            foodProduct: {
                id: "3",
                title: "Масло сливочное",
                nutrition: {
                    proteins: 0.85,
                    fats: 81.11,
                    carbs: 0.06,
                    calories: 717
                }
            }
        },

    ],
    steps: [
        {
            description: `Сущеные грибы заливаем кипятком и оставляем на 20-30 мин`
        },
        {
            description: `Филе бедра нарезаем на кубики. Солим, перчим. Добавляем розмарин и сушеный чеснок. Оставляем мариноваться.`
        },
        {
            description: `Замеченные сушеные грибы промыть под проточной водой.`
        },
        {
            description: `Нарезаем лук, чеснок и грибы.`
        },
        {
            description: `На сливочном масле обжариваем лук до золотистости. Добавляем грибы и обжариваем все до момента, когда вся влага испарится.`
        },
        {
            description: `Добавляем чеснок`
        },
        {
            description: `В это время немого подогреваем сливки.`
        },
        {
            description: `Убавляем огонь до 1/3 мощности и вливаем сливки. Томим 10-15 минут.`
        },
        {
            description: `Параллельно с этим ставм воду для пасты. Когда вода закипит отвариваем пасту согласно инструкции на упаковке до сотояния al dente.`
        },
        {
            description: `Отдельно от лука обжариваем курицу любым удобным способом до полной готовности - гриль, сковорода.`
        },
        {
            description: `Когда все ингридиенты будут готовы - смешиваем их в кастрюле из под пасты.`
        },
        {
            description: `Блюдо готово. Приятного аппетита!`
        }
    ],
    duration: 30,
    filters: [RecipeFilter.CHEAT_MEAL]
}


