import { GetExerciseById, GetExercises, GetExercisesByMuscle } from "../api/ExercisesAPI";
import { Period } from "../reduxTypes";
import { MuscleEnum, MuscleData, SetsInfo, Statistic, VolumeData, VolumeInfo, Workout, WorkoutStatus, ExerciseData, ExerciseStatistic, WorkoutStatistic } from "../types/types";
import { FormatDate, GetDateRangeByPeriod } from "../utils/DateUtils";
import { GetMuscleEnumArray } from "../utils/EnumUtils";
import { MuscleGroupsEnum } from "../components/Constants";
import { GetPreviousWorkout, GetWorkoutsByDateRange, IsRejected } from "./WorkoutService";

export const GetStatisticByPeriod = (period: Period): Statistic => {
    let dateRange = GetDateRangeByPeriod(period);
    const workouts = GetWorkoutsByDateRange(dateRange) || [];
    const maxVolume = CalcMaxVolume(workouts);
    const maxDuration = workouts.length && Math.floor((Math.max(...workouts.map(workout => workout.duration || 0)) / 1000 / 60))
    const setsData = GetSetsData(workouts);
    const volumesData = GetVolumeByExerciseData(workouts);

    console.log("Date range", dateRange)

    return {
        workoutCount: workouts.length,
        fullCompleteCount: workouts.filter(workout => workout.status == WorkoutStatus.COMPLETED).length,
        rejectedCount: workouts.filter(IsRejected).length,
        fullDuration: CalcFullDuration(workouts),
        fullSets: CalcFullSets(workouts),
        fullVolume: CalcFullVolume(workouts),
        tonnageData: {
            max: maxVolume,
            data: workouts.map(workout => {
                return {
                    date: workout.endAt ? FormatDate(new Date(workout.endAt)) : undefined,
                    volume: workout.volume
                }
            })
        },
        durationData: {
            max: maxDuration,
            data: workouts.filter(workout => workout.duration != undefined).map(workout => {
                return {
                    date: workout.endAt ? FormatDate(new Date(workout.endAt)) : undefined,
                    duration: workout.duration ? Math.floor(workout.duration / 1000 / 60) : undefined
                }
            })
        },
        setsData: setsData,
        volumeData: volumesData,
        exerciseData: GetVolumeByExercise(workouts),
        avgDuration: GetAvgDuration(workouts)
    }
}

export const GetWorkoutStatistic = (workout: Workout): WorkoutStatistic => {

    const workouts = [workout];

    let exercisesStats: ExerciseStatistic[] = workout.exercises
        .map(e => GetExerciseById(e.id))
        .map(exercise => {
            if (exercise == undefined) {
                return {
                    muscle: MuscleEnum.BICEPS,
                    volume: 0,
                    setsCount: 0
                }
            }
            return {
                muscle: exercise ? exercise.muscle : MuscleEnum.BICEPS,
                volume: workout.exercises.filter(e => e.id == exercise.id).map(e => e.volume).reduce((sum, volume) => sum + volume, 0),
                setsCount: workout.exercises.filter(e => e.id == exercise.id).flatMap(e => e.sets).filter(s => s.completed).length
            }
        })

    exercisesStats = exercisesStats.reduce((group: any, stats: ExerciseStatistic) => {
        const muscle = stats.muscle;
        group[muscle] = group[muscle] !== undefined ? group[muscle] : {
            muscle: muscle,
            volume: 0,
            setsCount: 0
        }
        group[muscle] = {
            ...group[muscle],
            volume: group[muscle].volume + stats.volume,
            setsCount: group[muscle].setsCount + stats.setsCount}

        return group;
    }, {})

    return {
        muscles: exercisesStats
    }

}

export const GetPreviousWorkoutStatistic = (workout: Workout): WorkoutStatistic => {
    const prevWorkout = GetPreviousWorkout(workout);
    return prevWorkout ? GetWorkoutStatistic(prevWorkout) : {
        muscles: []
    }
}

const CalcMaxVolume = (workouts: Workout[]) => {
    let allVolumes: number[] = workouts.filter(workout => workout.volume !== undefined).map(workout => workout.volume ? workout.volume : 0);
    return workouts.length && Math.max(...allVolumes)
}

const CalcFullVolume = (workouts: Workout[]) => {
    console.debug("workoutns", workouts)
    return workouts.map(workout => workout.exercises.filter(e => e.volume != undefined).reduce((sum, item) => sum + item.volume, 0))
        .reduce((sum, item) => sum + item, 0)
}

const CalcFullSets = (workouts: Workout[]) => {
    return workouts.map(workout => workout.exercises.flatMap(item => item.sets).filter(set => set.completed).length)
        .reduce((sum, item) => sum + item, 0)
}

const CalcFullDuration = (workouts: Workout[]) => {
    return workouts.map(workout => workout.duration || 0)
        .reduce((sum, item) => sum + Math.floor(item / 1000 / 60), 0)
}

const GetSetsData = (workouts: Workout[]): MuscleData[] => {
    const result: MuscleData[] = GetMuscleEnumArray().map((muscle) => {
        const res: SetsInfo[] = workouts.map(workout => {
            const allExercisesByMuscleIds = GetExercisesByMuscle(MuscleEnum[muscle])
                .map(item => item.id);
            const targetSets = workout.exercises.filter(item => allExercisesByMuscleIds.includes(item.id))
                .flatMap(item => item.sets)
                .filter(set => set.completed)
            let setsInfo: SetsInfo = {
                date: workout.endAt ? FormatDate(new Date(workout.endAt)) : undefined,
                value: targetSets.length
            }
            return setsInfo;
        })
        return {
            muscle: MuscleEnum[muscle],
            data: res.filter(item => item.value > 0)
        }
    })
    return result;
}

const GetVolumeByExerciseData = (workouts: Workout[]): VolumeData[] => {
    const result: VolumeData[] = GetMuscleEnumArray().map(muscle => {
        const res = workouts.map(workout => {
            const allExercisesByMuscleIds = GetExercisesByMuscle(MuscleEnum[muscle])
                .map(item => item.id);

            const targetVolume = workout.exercises.filter(item => allExercisesByMuscleIds.includes(item.id))
                .reduce((sum, item) => sum + item.volume, 0)
            let r: VolumeInfo = {
                date: workout.endAt ? FormatDate(new Date(workout.endAt)) : undefined,
                value: targetVolume
            }
            return r;
        })
        return {
            muscle: MuscleEnum[muscle],
            data: res.filter(item => item.value > 0)
        }
    })
    return result;
}

const GetVolumeByExercise = (workouts: Workout[]): ExerciseData[] => {
    const result: ExerciseData[] = GetExercises().map(e => {
        const workoutsWithExercise = workouts.filter(w => w.exercises.map(ex => ex.id).includes(e.id));

        const volumeByExercise = workoutsWithExercise.flatMap(w => w.exercises)

        let r: ExerciseData = {
            exercise: e,
            data: workoutsWithExercise.map((w, index) => {
                const volume = w.exercises.filter(e => e.id).map(e => e.volume).reduce((sum, volume) => sum + volume, 0)
                const result: VolumeInfo = {
                    value: volume,
                    date: FormatDate(new Date(w.startAt))
                }
                return result;
            })
        }
        return r;
    })

    return result;
}

const GetAvgDuration = (workouts: Workout[]): number => {
    if (workouts.length == 0)
        return 0;
    return Math.floor(workouts.map(workout => workout.duration || 0)
        .reduce((sum, item) => sum + Math.floor(item / 1000 / 60), 0) / workouts.length)
}