import { useState } from "react"

export const useSnackbar = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("")
    const [hideDuration, setHideDuration] = useState(0)

    const handleOpen = (message: string, hideDuration: number) => {
        setOpen(true);
        setMessage(message);
        setHideDuration(hideDuration);
    }

    const handleClose = () => {
        setOpen(false);
        setMessage("");
        setHideDuration(0);
    }

    return { open, handleClose, handleOpen, message, hideDuration }
}