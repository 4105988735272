import { Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux/Store';
import { useSelector } from 'react-redux/es/exports';

const DebugScene = () => {
    const navigate = useNavigate();
    const firebaseToken = useSelector((state: RootState) => state.firebase.token);
    const reboot = () => {
        navigate("/");
        window.location.reload();
    }

    return <div>
        <Button fullWidth color="secondary" onClick={reboot}>Перезагрузить</Button>
        <Button fullWidth color="secondary">Отчистить кеш</Button>
        <Button fullWidth color="secondary">Отчистить данные</Button>
        {/* <Typography width={"100%"} sx={{wordWrap: 'break-word'}}>Firebase token: {firebaseToken}</Typography> */}
    </div>
}
 
export default DebugScene;