import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type TimerState = {
    startAt?: string,
    isActive: boolean,
    value: string
}

export const TimerInitialState: TimerState = {
    startAt: undefined,
    isActive: false,
    value: "00:00"
}

export const TimerSlice = createSlice({
    name: "WorkoutTimer",
    initialState: TimerInitialState,
    reducers: {
        startTimer: (state: TimerState) => {
            state.isActive = true;
            state.startAt = new Date().toISOString()
        },
        stopTimer: (state: TimerState) => {
            state.isActive = false;
            state.value = "00:00"
        },
        tickTimer: (state: TimerState, action: PayloadAction<number>) => {
            if (state.startAt == undefined)
                return;
            const startAt = new Date(state.startAt);
            const now = new Date();
            const newDate = new Date(now.getTime() - startAt.getTime())
            const hours = newDate.getUTCHours() == 0 ? "" : newDate.getUTCHours().toString();
            const minutes = newDate.getUTCMinutes() < 10 ? `0${newDate.getUTCMinutes()}` : newDate.getUTCMinutes().toString();
            const seconds = newDate.getUTCSeconds() < 10 ? `0${newDate.getUTCSeconds()}` : newDate.getUTCSeconds().toString();

            state.value = [hours, minutes, seconds].filter(v => v !== "").join(":")
        }
    }

})

export const { startTimer, stopTimer, tickTimer } = TimerSlice.actions;
export default TimerSlice.reducer;