import { useState } from "react"
import { ModalInfo, ModalType } from "../appTypes"
import { useNavigate } from "react-router-dom"

const InititalState: ModalInfo = {
    title: "",
    open: false,
    type: ModalType.INFO
}

export const useModal = () => {
    const [modal, setModal] = useState<ModalInfo>(InititalState)
    const navigation = useNavigate();

    const handleOpen = (title: string, type: ModalType) => {
        setModal({
            title: title,
            type: type,
            open: true
        })
    }

    const handleClose = (redirectUrl?: string | undefined) => {
        setModal(InititalState)
        if (redirectUrl) {
            navigation(redirectUrl)
        }
    }

    return { modal, handleOpen, handleClose }
}

export const useDialog = () => {
    const [openDialog, setOpenDialog] = useState(false)

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    return { openDialog, handleOpenDialog, handleCloseDialog }
}