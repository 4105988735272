import { Box, Grid, Paper } from '@mui/material';
import * as React from 'react';
import Modal from '../base/Modal';
import { GetTraningPrograms } from '../../api/TraningProgramAPI';
import TraningProgramCard from '../program/TraningProgramCard';
import MainButton from '../base/MainButton';
import { TraningProgram } from '../../types/types';
import { CreateWorkout } from '../../service/WorkoutService';
import { startTimer } from '../../redux/TimerSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FeedLine from '../feed/Feedline';
import HomeMenu from '../home/HomeMenu';
import HomeStatistics from '../home/Statistics';
import { startWorkout } from '../../redux/WorkoutSlice';
import InstallPWA from '../base/InstallPWA';
import { GetAllProgramsSortedByPopular } from '../../service/TraningProgramService';

interface IMainSceneProps {
}

const HomeScene: React.FunctionComponent<IMainSceneProps> = (props) => {
  return <Box>
    <InstallPWA />
    <FeedLine />
    <HomeStatistics />

    <HomeMenu />

  </Box>
};

export default HomeScene;