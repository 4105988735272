import { AppRoutes } from "../components/Constants"
import { IRouterSlice } from "../reduxTypes";

export const getRouteInfo = (path: string): IRouterSlice => {
    var result = AppRoutes.find((routeInfo) => {
        return path.match(routeInfo.path);
    })

    return result || {
        path: "/workout",
        title: "undefined",
        needBack: false
    }
}

