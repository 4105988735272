import { Button, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { ExerciseItem, TraningProgram } from '../../types/types';
import MainButton from '../base/MainButton';
import TraningProgramForm from './TraningProgramForm';

import ShareProgram from './ShareProgram';
import { Kind } from '../base/EntityActionButtonGroup';
import { ICreateTraningProgram, IEditTraningProgram } from '../../service/TraningProgramService';
import { EditTraningProgram as edit } from '../../service/TraningProgramService';

interface Props {
    traningProgram: TraningProgram,
    onRemove(): void,
    onComplete(): void
}

const TraningProgramInfo = ({ traningProgram, onRemove, onComplete }: Props) => {

    const onSave = (command: ICreateTraningProgram) => {

        var editCommand: IEditTraningProgram = {
            id: traningProgram.id,
            ...command
        }

        edit(editCommand)
            .then((program: TraningProgram) => {
                console.debug("Edited", program)
                onComplete()
            })
            .catch(e => {
                console.error("Error editing", e)
            })
    }


    return <div>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: "column" }}>
            <TraningProgramForm
                traningProgram={traningProgram}
                onRemove={onRemove}
                onSave={onSave}
                kind={Kind.INFO}/>

        </Box>
    </div>
}

export default TraningProgramInfo;