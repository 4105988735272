import { Box, CircularProgress } from '@mui/material';
import * as React from 'react';

interface IFullscreeenLoaderProps {
  loader?: any
}

const FullscreeenLoader: React.FunctionComponent<IFullscreeenLoaderProps> = (props) => {
  return <Box sx={{
    height: "100%",
    width: "100%",
    position: "absolute",
    bottom: 0,
    left:0,
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    zIndex: 999999999999,
    backdropFilter: "blur(5px)"
  }}>
    {props.loader ? props.loader : <CircularProgress color="inherit" />}
  </Box>
};

export default FullscreeenLoader;
