import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Slide } from '@mui/material';
import * as React from 'react';
import { Feed } from '../../types/types';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import { UploadImage } from '../../utils/ImageUtils';
import { useNavigate } from 'react-router-dom';

var paused = false;

interface IFullscreenFeedProps {
    feed: Feed,
    open: boolean,
    onClose(): void,
    ttl: number
}

const Transition = React.forwardRef<unknown, TransitionProps & {
    children: React.ReactElement
}>((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FullscreenFeed: React.FunctionComponent<IFullscreenFeedProps> = (props) => {
    const [progress, setProgress] = React.useState(0);
    const intervalref = React.useRef<number | null>(null);
    const navigate = useNavigate();

    const [bgImage, setBgImage] = React.useState(null)

    React.useEffect(() => {
        UploadImage(props.feed.img)
            .then((path) => setBgImage(path))
            .catch(console.debug)
        return () => { setBgImage(null) }
    }, [props.feed.imgFull])

    const onPauseTimer = () => {
        paused = true;
    }

    const onResumeTimer = () => {
        paused = false;
    }

    const tick = () => {
        if (!paused) {
            setProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    onClose()
                }
                return prevProgress + 100 / (props.ttl / 100);
            });
        }
    }

    const startTimer = () => {
        if (intervalref.current !== null) return;
        intervalref.current = window.setInterval(() => {
            tick();
        }, 100);
    }

    const stopTimer = () => {
        if (intervalref.current) {
            window.clearInterval(intervalref.current);
            intervalref.current = null;
        }
        setProgress(0);
    }

    React.useEffect(() => {
        if (props.feed.id == 0)
            return;

        startTimer();

        return () => {
            stopTimer();
        };
    }, [props.feed.id]);

    const onClose = () => {
        stopTimer();
        setBgImage(null)
        props.onClose();
        onResumeTimer();
    }

    return <Dialog
        onTouchStart={onPauseTimer}
        onTouchEnd={onResumeTimer}
        open={props.open}
        TransitionComponent={Transition}
        PaperProps={{
            sx: {
                height: "100%",
                width: "100%",
                m: 0,
                position: "fixed",
                backgroundImage: bgImage ? `url(${bgImage})` : "none",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundColor: props.feed.imgFull ? "none" : props.feed.color,
                filter: props.feed.imgFull && props.feed.useGrayFilter == undefined || props.feed.useGrayFilter == true ? "brightness(1) saturate(0%)" : "none",
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
                maxHeight: "none"
            }
        }}>
        <DialogTitle sx={{
            pb: 4,
            background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.75), transparent)",
            color: 'white'
        }}>
            {props.feed.title}
            <IconButton
                aria-label="close"
                onClick={props.onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                    zIndex: 99999999
                }}
            >
                <CloseIcon />
            </IconButton>
            <CircularProgress variant="determinate" value={progress} sx={{
                position: 'absolute',
                right: 8,
                top: 8,

            }} />
        </DialogTitle>
        <DialogContent sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 1
        }}>

            {props.feed.text}
            {props.feed.action}
        </DialogContent>
    </Dialog>
};

export default FullscreenFeed;
