import { BottomNavigationAction, Box, Button, Grid, IconButton, Slide, useTheme } from '@mui/material';
import * as React from 'react';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import Modal from './Modal';
import { useModal } from '../../hooks/AppHooks';
import { ModalType } from '../../appTypes';
import { GetAllProgramsSortedByPopular } from '../../service/TraningProgramService';
import TraningProgramCard from '../program/TraningProgramCard';
import { TraningProgram } from '../../types/types';
import { CreateWorkout } from '../../service/WorkoutService';
import { useDispatch, useSelector } from 'react-redux';
import { startTimer } from '../../redux/TimerSlice';
import { startWorkout } from '../../redux/WorkoutSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../redux/Store';

interface IStartWorkoutNavButtonProps {

}

const StartWorkoutNavButton: React.FunctionComponent<IStartWorkoutNavButtonProps> = (props) => {
    const theme = useTheme()
    const { handleOpen, handleClose, modal } = useModal()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const activeWorkoutId = useSelector((state: RootState) => state.workout.activeWorkoutId);
    const location = useLocation();

    const onStart = async (e: React.SyntheticEvent, traningProgram: TraningProgram) => {
        const workout = await CreateWorkout(traningProgram);
        dispatch(startTimer());
        dispatch(startWorkout(workout))
        navigateToWorkout(workout.id)
        handleClose()
    }

    const navigateToWorkout = (id: string) => {
        navigate(`/workout-process/${id}`)
    }

    if (activeWorkoutId || location.pathname.includes("workout-process"))
        return <div></div>


    return <Box key={10} sx={{
        flex: 2
    }}>

        <Slide in direction='up'>
            <IconButton sx={{
                position: 'absolute',
                bottom: 14, left: 0, right: 0,
                marginLeft: "auto",
                marginRight: "auto",
                backgroundColor: theme.palette.secondary.main,
                height: 70,
                width: 70,
                border: `solid 4px ${theme.palette.secondary.dark}`
            }}
                onClick={() => handleOpen("Выберите программу тренировок", ModalType.INFO)}>
                <FitnessCenterIcon htmlColor={theme.palette.secondary.contrastText} />
            </IconButton>
        </Slide>

        <Modal
            open={modal.open}
            onClose={() => handleClose()}
            title="Выбери программу тренировок">
            <Grid container spacing={2}>
                {GetAllProgramsSortedByPopular().map((item, idx) => <Grid item xs={12} key={idx}>
                    <TraningProgramCard
                        onClick={onStart}
                        key={idx}
                        item={item}
                        sx={{ minHeight: "80px" }} />
                </Grid>
                )}
            </Grid>
        </Modal>
    </Box>;
};

export default StartWorkoutNavButton;
