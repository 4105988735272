import { GetProgramStoreItemById as BY_ID, GetProgramStoreItems as GET_ALL } from "../api/ProgramStoreAPI"
import { ProgramStoreItem } from "../types/ProgramStoreTypes"

export const GetProgramStoreItemById = (id: string):Promise<ProgramStoreItem | undefined> => {
    const promise: Promise<ProgramStoreItem | undefined> = new Promise((resolve, reject) => {
        try {
            resolve(BY_ID(id))
        } catch(e) {
            reject(e)
        } 
        
    })

    return promise;
}

export const GetProgramStoreItems = (limit?: number): Promise<ProgramStoreItem[]> => {
    const promise: Promise<ProgramStoreItem[]> = new Promise((resolve, reject) => {
        try {
            const items = GET_ALL()
            resolve(limit ? items.splice(0, limit) : items);
        } catch(e) {
            reject(e)
        }
    })

    return promise;
}

