export const IsProd = process.env.NODE_ENV === "production";
export const IsTest = process.env.NODE_ENV === "test";
export const IsDev = process.env.NODE_ENV === "development";

export const FeatureAuthIsEnable = process.env.REACT_APP_FEATURE_AUTH_ENABLE === "true"
export const FeatureSchedulesIsEnable = process.env.REACT_APP_FEATURE_SCHEDULES_ENABLE === "true"
export const FeatureDonateIsEnable = process.env.REACT_APP_FEATURE_DONATE_ENABLE === "true"
export const FeatureXmasThemeIsEnable = process.env.REACT_APP_FEATURE_XMAX_THEME_ENABLE === "true"
export const FeatureFoodIsEnable = process.env.REACT_APP_FEATURE_FOOD_ENABLE === "true"
export const FeatureExportProgramIsEnable = process.env.REACT_APP_FEATURE_EXPORT_PROGRAM === "true"

export const DanateURL = process.env.REACT_APP_DONATE_URL;