import { AddExercises } from "../api/ExercisesAPI";
import { AddTraningProgram } from "../api/TraningProgramAPI"
import { Exercise, TraningProgram } from "../types/types"
import { v4 as uuidv4 } from 'uuid';

export const ENTITY_NOT_DETECTED_MESSAGE = "Entity not detected";
export const INVALID_DATA = "Переданы данные, не соответствующие структуре"

export type ImportResult = {
    entity?: any,
    errorMessage?: string
}

export const ImportByEntityName = (entityName: string, json: string): Promise<ImportResult> => {
    if (entityName === "TraningProgram") {
        const promise: Promise<ImportResult> = new Promise((resolve, reject) => {
            try {
                const program: TraningProgram = JSON.parse(json)
                program.id = uuidv4();
                if (!program.exercises) {
                    throw new Error(INVALID_DATA)
                }
                AddTraningProgram(program);
                resolve({ entity: program })
            } catch (e) {
                reject({ errorMessage: e })
            }
        })
        return promise;
    } else if (entityName === "Exercise") {
        const promise: Promise<ImportResult> = new Promise((resolve, reject) => {
            try {
                const exercise: Exercise = JSON.parse(json)
                exercise.id = uuidv4();

                if (!exercise.muscle) {
                    throw new Error(INVALID_DATA)
                }

                AddExercises(exercise);
                resolve({ entity: exercise })
            } catch (e) {
                reject(e)
            }
        })
        return promise;
    }

    let defaultPromise: Promise<ImportResult> = new Promise((resolve, reject) => {
        reject(ENTITY_NOT_DETECTED_MESSAGE)
    })

    return defaultPromise;
}

