import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { Nutrition } from '../../types/RecipeTypes';

interface INutritionTableProps {
    nutrition: Nutrition
}

const NutritionTable: React.FunctionComponent<INutritionTableProps> = ({nutrition}) => {
    return <TableContainer component={Paper}>
        <Table style={{ tableLayout: 'fixed' }}>
            <TableHead>
                <TableRow>
                    <TableCell>Ккал</TableCell>
                    <TableCell align="center">Белки</TableCell>
                    <TableCell align="center">Жиры</TableCell>
                    <TableCell align="right">Углеводы</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>{nutrition.calories}</TableCell>
                    <TableCell align="center">{nutrition.proteins}</TableCell>
                    <TableCell align="center">{nutrition.fats}</TableCell>
                    <TableCell align="right">{nutrition.carbs}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>;
};

export default NutritionTable;
