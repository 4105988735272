import React from 'react';
import { Grow, Paper, Typography, useTheme } from "@mui/material"
import { Box } from '@mui/system';
import { ProgramStoreItem } from '../../types/ProgramStoreTypes';

interface StoreTraningProgramCardProps {
    item: ProgramStoreItem,
    onClick(e: any, item: ProgramStoreItem): void,
}

const StoreTraningProgramCard = (props: StoreTraningProgramCardProps) => {
    const theme = useTheme();

    return <Grow in timeout={250}>
        <Paper
            sx={{
                display: "flex",
                width: "280px",
                minHeight: "160px",
                maxHeight: "160px",
                flexDirection: 'column',
                backgroundImage: props.item.imgUrl ? `url(${props.item.imgUrl})` : "none",
                backgroundSize: "cover",
                backgroundPosition: "center",
                color: theme.palette.secondary.contrastText
            }}
            onClick={(e) => props.onClick(e, props.item)}>
            <Box sx={{ flexGrow: 1, background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.95), transparent);",
                    p: 1,
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8}}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: "space-between",
                    flexDirection: "column"
                }}>
                    <Typography variant='subtitle2'>{props.item.title}</Typography>
                    <Typography variant='caption'>{props.item.shortDescription}</Typography>
                </Box>

            </Box>
        </Paper>
    </Grow>
}

export default StoreTraningProgramCard;