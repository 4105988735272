import { Box, Grid, Paper, Table, TableBody, TableCell, TableCellProps, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { GetPreviousWorkoutStatistic, GetWorkoutStatistic } from '../../service/ProgressService';
import { MuscleGroupInfo, MuscleGroupsEnum, MuscleGroupsInfo } from '../Constants';
import { ExerciseStatistic, MuscleEnum, Workout, WorkoutStatistic } from '../../types/types';
import { green, red, yellow } from '@mui/material/colors';

import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

interface WorkoutStatsProps {
    workout: Workout
}

interface CellProps extends TableCellProps {
    value: number,
    prevValue: number
}

const Cell = ({ value, prevValue, align }: CellProps) => {

    const diff = value - prevValue

    return <TableCell align={align} sx={{

    }}>
        <Box sx={{
            display: 'flex',
            flexDirection: "row",
            alignItems: "center",
            justifyContent: align == "center" ? "center" : "right"
        }}>
            <Typography>{value}</Typography>
            {diff > 0 ? <KeyboardDoubleArrowUpIcon sx={{ color: green[300] }} fontSize='small' /> : (diff < 0 ? <KeyboardDoubleArrowDownIcon fontSize='small' sx={{ color: red[300] }} /> : <div></div>)}

        </Box>
    </TableCell>
}

const WorkoutStats = ({ workout }: WorkoutStatsProps) => {
    const [stats, setStats] = useState<ExerciseStatistic[]>([])
    const [previousStats, setPreviousStats] = useState<{ [key: string]: any }>({})

    useEffect(() => {
        setStats(GetWorkoutStatistic(workout).muscles);
        setPreviousStats(GetPreviousWorkoutStatistic(workout).muscles)
    }, [workout])

    return <Grid item xs={12} sx={{ display: 'flex', gap: 1, flexDirection: 'column', mb: 1 }}>

        <TableContainer component={Paper}>
            <Table padding="normal">
                <TableHead>
                    <TableRow>
                        <TableCell>Мыш. группа</TableCell>
                        <TableCell align='center'>Похдоды</TableCell>
                        <TableCell align='right'>Объем</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {Object.entries(stats).map((key: any, value: any) => {
                        var muscle: keyof typeof MuscleEnum = key[0];
                        var eStats = key[1] as ExerciseStatistic;
                        var prevStats = previousStats[muscle];
                        return <TableRow key={muscle}>
                            <TableCell>{MuscleGroupsInfo[muscle].locale}</TableCell>
                            <Cell align='center' value={eStats.setsCount} prevValue={prevStats ? prevStats.setsCount : 0}></Cell>
                            <Cell align='right' value={eStats.volume} prevValue={prevStats ? prevStats.volume : 0}></Cell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>

    </Grid>
}

export default WorkoutStats;