import { Box } from '@mui/material';
import * as React from 'react';
import { Schedule } from '../../../types/types';
import { useModal } from '../../../hooks/AppHooks';
import Modal from '../../base/Modal';
import { ModalType } from '../../../appTypes';
import CreateSchedule from '../../schedules/CreateSchedule';
import { useLocation, useNavigate } from 'react-router-dom';
import NoData from '../../base/NoData';
import { DeleteSchedule, FindAllSchedules } from '../../../service/ScheduleService';
import SchedulesList from '../../schedules/SchedulesList';
import EditSchedule from '../../schedules/EditSchedule';

interface ISchedulesSceneProps {
}

const SchedulesScene: React.FunctionComponent<ISchedulesSceneProps> = (props) => {
    const [schedules, setSchedules] = React.useState<Schedule[]>([]);
    const { modal, handleOpen, handleClose } = useModal();
    const [targetSchedule, setTargetSchedule] = React.useState<Schedule>()
    const location = useLocation();

    React.useEffect(() => {
        setSchedules(FindAllSchedules())
    }, [])

    React.useEffect(() => {
        if (location.search.includes("create")) {
            handleOpen("Создание расписания", ModalType.CREATE)
        } else {
            handleClose();
        }
    }, [location.search])

    const onClose = () => {
        handleClose("/schedules")
        setSchedules(FindAllSchedules())
        setTargetSchedule(undefined)
    }

    const onClickToSchedule = async (schedule: Schedule) => {
        setTargetSchedule(schedule);
        handleOpen("Изменение расписания", ModalType.INFO)
    }

    const onDeleteSchedule = (schedule: Schedule) => {
        console.debug()
        DeleteSchedule(schedule.id)
            .then(() => {
                onClose();
            })
    }

    return <Box sx={{
        height: "100%"
    }}>
        {schedules.length == 0 && <NoData title='Расписания пока не добавлены' />}
        {schedules.length > 0 && <SchedulesList onClick={onClickToSchedule} schedules={schedules} />}

        <Modal
            open={modal.open}
            onClose={onClose}
            title={modal.title}>
            {modal.type == ModalType.CREATE && <CreateSchedule onComplete={onClose} />}
            {modal.type == ModalType.INFO && targetSchedule != undefined
                && <EditSchedule
                    onComplete={onClose}
                    schedule={targetSchedule}
                    onDelete={onDeleteSchedule} />}
        </Modal>
    </Box>;
};

export default SchedulesScene;
