import { FormControl, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import * as React from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface ISelectorProps {
    label: string
    items: any[],
    handleChange(e: SelectChangeEvent): void,
    value: any,
    name: string
}

const Selector: React.FunctionComponent<ISelectorProps> = (props) => {
    return <FormControl
        variant='standard'
        sx={{ m: 1, ml: 0, mr: 0, pt: 1, pb: 1 }}
        fullWidth>
        <InputLabel id={`${props.name}-selector-label`}>{props.label}</InputLabel>
        <Select
            onChange={props.handleChange}
            labelId={`${props.name}-selector-label`}
            id={`${props.name}-selector`}
            name={props.name}
            value={props.value}
            MenuProps={MenuProps}>
                {props.items}
        </Select>
    </FormControl>
};

export default Selector;
