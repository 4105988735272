import { List, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';

interface Props {
    title: string,
    children: React.ReactNode
}

const MenuBox = ({ title, children }: Props) => {
    return <Box sx={{
        padding: 0
    }}>
        <Typography variant="h6">{title}</Typography>
        <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 1 }}>
            <List>
                {children}
            </List>
        </Paper>
    </Box>
}

export default MenuBox;