import { Box, Paper, Slide, Typography } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { UploadImage } from '../../utils/ImageUtils';
import { FeatureSchedulesIsEnable } from '../../utils/ProfileUtils';


interface IHomeMenuProps {
}

interface IMenuItem {
    title: string,
    path: string,
    backgroundImageSrc?: string,
    enable: boolean
}

const items: IMenuItem[] = [
    {
        title: "Программы тренировок",
        path: "/traning-programs",
        backgroundImageSrc: `${process.env.PUBLIC_URL}/assets/programs.jpg`,
        enable: true
    },
    {
        title: "Упражнения",
        path: "/exercises",
        backgroundImageSrc: `${process.env.PUBLIC_URL}/assets/exercises.jpg`,
        enable: true
    },
    {
        title: "История тренировок",
        path: "/traning",
        backgroundImageSrc: `${process.env.PUBLIC_URL}/assets/history.jpg`,
        enable: true
    }, 
    {
        title: "Расписания",
        path: "/schedules",
        backgroundImageSrc: `${process.env.PUBLIC_URL}/assets/schedule.jpg`,
        enable: FeatureSchedulesIsEnable
    }
]

const MenuItem: React.FunctionComponent<IMenuItem> = ({ title, path, backgroundImageSrc }) => {

    const [bgImage, setBgImage] = React.useState(null)
    UploadImage(backgroundImageSrc)
        .then((path) => setBgImage(path))
        .catch(console.debug)

    const navigation = useNavigate();
    return <Paper onClick={() => { navigation(path) }}
        sx={{
            width: "100%",
            height: "120px",
            display: 'flex',
            alignItems: "flex-end",
            backgroundImage: bgImage ? `url(${bgImage})` : "none",
            backgroundSize: "cover",
            backgroundPosition: "center"
        }}>
        <Typography variant='h5' sx={{
            width: "100%",
            height: "50%",
            background: "linear-gradient(to top, rgba(0, 0, 0, 0.95), transparent)",
            p: 1,
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
            color: 'white'
        }}>{title}</Typography>
    </Paper>
}

const HomeMenu: React.FunctionComponent<IHomeMenuProps> = () => {
    return <Slide in direction='up' timeout={250}>
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                pb: 1,
                pt: 3
            }}>
            {items.filter(i => i.enable).map((item, idx) => <MenuItem key={idx} {...item} />)}
        </Box>
    </Slide>;
};

export default HomeMenu;
