import { AnyAction, createListenerMiddleware } from "@reduxjs/toolkit";
import { setToken } from "./FirebaseSlice";
import { changeAccent, changePalette, changeXmasTheme, disableXmasTheme } from "./ThemeSlice";
import { startTimer, stopTimer, tickTimer } from "./TimerSlice";
import { startWorkout, completeWorkout } from "./WorkoutSlice";
import { login, logout } from "./AuthSlice";
import { addFavoriteDish, removeFavoriteDish } from "./UserSlice";

export const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
    actionCreator: startWorkout,
    effect: async (action: AnyAction, listenerApi) => {
        await listenerApi.delay(1000);
        localStorage.setItem("app-settings", JSON.stringify(listenerApi.getState()))
    }
})

listenerMiddleware.startListening({
    actionCreator: completeWorkout,
    effect: async (action: AnyAction, listenerApi) => {
        await listenerApi.delay(1000);
        localStorage.setItem("app-settings", JSON.stringify(listenerApi.getState()))
    }
})

listenerMiddleware.startListening({
    actionCreator: changePalette,
    effect: async (action: AnyAction, listenerApi) => {
        await listenerApi.delay(1000);
        localStorage.setItem("app-settings", JSON.stringify(listenerApi.getState()))
    }
})

listenerMiddleware.startListening({
    actionCreator: changeAccent,
    effect: async (action: AnyAction, listenerApi) => {
        await listenerApi.delay(1000);
        localStorage.setItem("app-settings", JSON.stringify(listenerApi.getState()))
    }
})

listenerMiddleware.startListening({
    actionCreator: changeXmasTheme,
    effect: async (action: AnyAction, listenerApi) => {
        await listenerApi.delay(1000);
        localStorage.setItem("app-settings", JSON.stringify(listenerApi.getState()))
    }
})


listenerMiddleware.startListening({
    actionCreator: disableXmasTheme,
    effect: async (action: AnyAction, listenerApi) => {
        await listenerApi.delay(1000);
        localStorage.setItem("app-settings", JSON.stringify(listenerApi.getState()))
    }
})

listenerMiddleware.startListening({
    actionCreator: startTimer,
    effect: async (action: AnyAction, listenerApi) => {
        await listenerApi.delay(1000);
        localStorage.setItem("app-settings", JSON.stringify(listenerApi.getState()))
    }
})

listenerMiddleware.startListening({
    actionCreator: tickTimer,
    effect: async (action: AnyAction, listenerApi) => {
        await listenerApi.delay(1000);
        localStorage.setItem("app-settings", JSON.stringify(listenerApi.getState()))
    }
})

listenerMiddleware.startListening({
    actionCreator: stopTimer,
    effect: async (action: AnyAction, listenerApi) => {
        await listenerApi.delay(1000);
        localStorage.setItem("app-settings", JSON.stringify(listenerApi.getState()))
    }
})

listenerMiddleware.startListening({
    actionCreator: setToken,
    effect: async (action: AnyAction, listenerApi) => {
        await listenerApi.delay(1000);
        localStorage.setItem("app-settings", JSON.stringify(listenerApi.getState()))
    }
})

listenerMiddleware.startListening({
    actionCreator: login,
    effect: async (action: AnyAction, listenerApi) => {
        await listenerApi.delay(1000);
        localStorage.setItem("app-settings", JSON.stringify(listenerApi.getState()))
    }
})

listenerMiddleware.startListening({
    actionCreator: logout,
    effect: async (action: AnyAction, listenerApi) => {
        await listenerApi.delay(1000);
        localStorage.setItem("app-settings", JSON.stringify(listenerApi.getState()))
    }
})

listenerMiddleware.startListening({
    actionCreator: addFavoriteDish,
    effect: async (action: AnyAction, listenerApi) => {
        await listenerApi.delay(1000);
        localStorage.setItem("app-settings", JSON.stringify(listenerApi.getState()))
    }
})

listenerMiddleware.startListening({
    actionCreator: removeFavoriteDish,
    effect: async (action: AnyAction, listenerApi) => {
        await listenerApi.delay(1000);
        localStorage.setItem("app-settings", JSON.stringify(listenerApi.getState()))
    }
})