import { Delete, Edit, Save } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import * as React from 'react';
import ShareProgram from '../program/ShareProgram';

export enum Kind {
    CREATE,
    INFO
}

interface IEntityActionButtonGroupProps {
    onRemove?(): void,
    onSave(): void,
    onEdit?(): void,
    onShare?(): void,
    kind: Kind,
    shareItem: any,
    editable: boolean
}

const EntityActionButtonGroup: React.FunctionComponent<IEntityActionButtonGroupProps> = ({onEdit, onRemove, onSave, onShare, kind, shareItem, editable}) => {
    return <Box sx={{
        gap: 1,
        display: 'flex',
        flexDirection: "row",
        justifyContent: kind == Kind.INFO ? "space-between" : "space-evenly",
        pb: 1,
        pt: 1
    }}>
        {editable && <Button variant='outlined' color="secondary" onClick={onEdit}>
            <Edit />
        </Button>}

        <Button variant='outlined' color="secondary" onClick={onSave}>
            <Save />
        </Button>

        {kind == Kind.INFO && <Button
            variant='outlined'
            onClick={onRemove}
            color="error">
            <Delete />
        </Button>}

        {kind == Kind.INFO && <ShareProgram item={shareItem} onShare={onShare} name={shareItem.title}/>}
    </Box>

};

export default EntityActionButtonGroup;
