import { Box, Button, Menu, MenuItem } from '@mui/material';
import * as React from 'react';

interface IFinishButtonProps {
    onFinish(): void,
    onCancel(): void
}

const FinishButton: React.FunctionComponent<IFinishButtonProps> = ({ onCancel, onFinish }) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return <Box>
        <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={handleClick}
            id="finish-btn"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
        >Завершить</Button>

        <Menu
            id="finish-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'finish-btn',
            }}
        >
            <MenuItem onClick={() => {
                onFinish()
                handleClose()
            }}>Завершить тренировку</MenuItem>
            <MenuItem onClick={() => {
                onCancel()
                handleClose()
            }}>Отменить тренировку</MenuItem>
        </Menu>
    </Box>;
};

export default FinishButton;
