import { ListItemButton, ListItemText, List, Typography, Box, useTheme } from '@mui/material';
import React from 'react';
import { GetExercisesByMuscle } from '../../api/ExercisesAPI';
import MainButton from '../base/MainButton';
import { MuscleGroupEnum, MuscleGroupInfo, MuscleGroupsInfo, } from '../Constants';
import CheckIcon from '@mui/icons-material/Check';
import { GetMuscleByGroup } from '../../service/MuscleService';

import { ExerciseItem, MuscleEnum } from '../../types/types';
import { GetMuscleEnumArray } from '../../utils/EnumUtils';

interface ExercisesSelectorProps {
    selected: ExerciseItem[],
    handleCheckItem(id: string): void,
    isSelected(id: string): boolean,
    oneElementSelector?: boolean
}

const Span = (value: any) => {
    const theme = useTheme();
    return <Box sx={{
        height: "30px",
        width: "30px",
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        backgroundColor: theme.palette.secondary.main
    }}>
        {value.value}
    </Box>
}

const ExercisesSelector = (props: ExercisesSelectorProps) => {
    return <div>
        <Box sx={{ }}>
            {GetMuscleEnumArray().map((muscle, idx) => <Box key={idx}>
                <Box>
                    <Typography sx={{ pl: 0, pr: 0 }} variant="h6">{MuscleGroupsInfo[muscle].locale}</Typography>
                </Box>
                <List>
                        {GetExercisesByMuscle(MuscleEnum[muscle]).map((exercise, idx) => <ListItemButton
                            key={idx}
                            disabled={props.isSelected(exercise.id) && props.oneElementSelector}
                            onClick={(e) => props.handleCheckItem(exercise.id)}
                            sx={{ pl: 1, pr: 1 }}
                        >
                            <ListItemText primary={exercise.title} />
                            {props.isSelected(exercise.id) ? 
                            <Span value={props.selected.map(i => i.id).indexOf(exercise.id) + 1}/> : <div></div>}
                        </ListItemButton>)
                    }
                </List>
            </Box>
            )}
        </Box>
    </div>
}

export default ExercisesSelector;