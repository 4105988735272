import { createSlice } from "@reduxjs/toolkit"


export type AppState = {
    loading: boolean
}

export const AppInitialState : AppState= {
    loading: false
}

export const AppSlice = createSlice({
    name: "App",
    initialState: AppInitialState,
    reducers: {
        startLoading: (state: AppState) => {
            state.loading = true;
        },
        stopLoading: (state: AppState) => {
            state.loading = false
        }
    }
}) 

export const { startLoading, stopLoading } = AppSlice.actions;
export default AppSlice.reducer;