import { v4 } from "uuid";
import { ProgramStoreItem, StoredTraningProgram, TraningGoal } from "./types/ProgramStoreTypes";
import { MuscleEnum, TypeOfActivity } from "./types/types";

export const FiveXFive: ProgramStoreItem = {
    id: "7dff8f75-482f-4b3a-b642-f38348b8c02e",
    shortDescription: "Базовые упражнения с большими весами",
    title: "5x5",
    traningGoal: TraningGoal.MUSCLE_GAIN,
    price: 0,
    duration: 90,
    imgUrl: "https://images.unsplash.com/photo-1556817411-31ae72fa3ea0?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    description: `По мнению многих спортсменов базовые упражнения - это путь к массе. Программа 5x5 построена на циклическом выполнении небольшого кол-ва базовых упражнений.
    При этом веса нужно подбирать так, чтобы было тяжело сделать +-5 повторов в каждом упражнении. И таких подходов необходимо сделать 5. 
    Это не конечные значения, они могут варьироваться. Все зависит в первую очередь от вашей подготовленности. Отдых между подходами должен быть достаточно большим - 3-7 минут. 
    Например, при тяжелом приседе можно отдыхать и 10 минут. Это необходимо для лучшего восстановления мышц после подхода. Чем больше веса - тем больше отдых.
    В данном тренировочном сете представленны 2 программы с акцентом на верх и них. Тренируйтесь 2-3 раза в неделю, чередуя их вы полностью прокачиваете наиболее большие группы мышц, что позволит вам набрать общую мышечную массу. 
    На каждой тренировке старайтесь по чуть-чуть поднимать рабочий вес.
    Данный тренировочный сет не нужно делать на постоянной основе. Идеальный цикл - 5-8 недель. После этого следует сменить программу, и дать отдохнуть ЦНС от тяжелых весов.`,
    programs: [
        {
            title: "5x5 (1)",
            description: "Тяжелая тренировка с акцентом на грудь и плечи",
            typeOfActivity: TypeOfActivity.STREINGTH_TRANING,
            exercises: [
                {
                    exercise: {
                        title: "Жим штанги лежа",
                        muscle: MuscleEnum.CHEST
                    },
                    sets: [
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Жим штанги стоя",
                        muscle: MuscleEnum.SHOULDERS
                    },
                    sets: [
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Приседания со штангой",
                        muscle: MuscleEnum.QUADS
                    },
                    sets: [
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Подтягивания на перекладине",
                        muscle: MuscleEnum.LATS
                    },
                    sets: [
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        }
                    ]
                }
            ]
        },
        {
            title: "5x5 (2)",
            description: "Тяжелая тренировка с акцентом на грудь и плечи",
            typeOfActivity: TypeOfActivity.STREINGTH_TRANING,
            exercises: [
                {
                    exercise: {
                        title: "Приседания со штангой",
                        muscle: MuscleEnum.QUADS
                    },
                    sets: [
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Становая тяга",
                        muscle: MuscleEnum.LOWER_BACK
                    },
                    sets: [
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Жим лежа на наклонной скамье (45)",
                        muscle: MuscleEnum.CHEST
                    },
                    sets: [
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Подтягивания на перекладине",
                        muscle: MuscleEnum.LATS
                    },
                    sets: [
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        }
                    ]
                }
            ]
        }
    ]
}

export const PushAndPull: ProgramStoreItem = {
    id: "a96ca3db-5c03-4ba9-ad80-d70721d5223c",
    title: "Тяни-толкай",
    imgUrl: "https://images.unsplash.com/photo-1605640107219-93fdd6e33e5d?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    shortDescription: "Сплит, основанный на делении движений на тянущие и толкательные",
    traningGoal: TraningGoal.MUSCLE_GAIN,
    price: 0,
    description: `Тренировочная программа направлена на наращивание мышечной массы. Её основное приемущество - более частая тренировка каждой мышцы. 
    В отличии от классического сплита кажная мышечная группа тренируется раз в 3-5 дней, тогда как в классическом сплите - раз в 7 дней.
    Более частая стимуляция мышц дает больший стимул для роста. Но тут важно дозировать нагрузку. Поэтому в программе 4 тренировочных сета. В каждом из них смещается акцент на целевую группу.
    Это необходимо для того, чтобы мышцы успевали восстановиться, но при этом получали постоянный стимул для роста. 
    Предполагается тренировка либо по 4-ех дневной программе(пн, вт, чт, пт), либо треровка с чередованием программ (напр. вт, чт, вск). 
    Важно чередовать тренировочные сеты для того, чтобы не перенагрузить одну мышечную группу и не словить состояние перетренированности.`,
    programs: [
        {
            title: "Толкай 1 (Грудь+Трицепс)",
            description: "Тренировка мышц, осуществляющих толкательные движения. Акцент на груди и трицепс",
            typeOfActivity: TypeOfActivity.STREINGTH_TRANING,
            exercises: [
                {
                    exercise: {
                        title: "Жим штанги лежа",
                        muscle: MuscleEnum.CHEST
                    },
                    sets: [
                        {
                            repeats: 6,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 6,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 6,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Бабочка",
                        muscle: MuscleEnum.CHEST
                    },
                    sets: [
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Французский жим",
                        muscle: MuscleEnum.TRICEPS
                    },
                    sets: [
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Разгибания в верхнем блоке на трицепс",
                        muscle: MuscleEnum.TRICEPS
                    },
                    sets: [
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Махи с гантелями в стороны",
                        muscle: MuscleEnum.SHOULDERS
                    },
                    sets: [
                        {
                            repeats: 15,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 15,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 15,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Жиж платформы ногами",
                        muscle: MuscleEnum.QUADS
                    },
                    sets: [
                        {
                            repeats: 15,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 15,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 15,
                            weight: 0,
                            completed: false
                        }
                    ]
                }
            ]
        },
        {
            title: "Толкай 2 (Ноги + Дельты)",
            description: "Тренировка мышц, осуществляющих толкательные движения. Акцент на переднюю поверхность бедра и дельты.",
            typeOfActivity: TypeOfActivity.STREINGTH_TRANING,
            exercises: [
                {
                    exercise: {
                        title: "Жим гантелей на наклонной скамье (30)",
                        muscle: MuscleEnum.CHEST
                    },
                    sets: [
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Отжимания на брусьях в грудном стиле",
                        muscle: MuscleEnum.CHEST
                    },
                    sets: [
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Мажи с гантелями в стороны",
                        muscle: MuscleEnum.SHOULDERS
                    },
                    sets: [
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Отведение руки в сторону в блоке",
                        muscle: MuscleEnum.SHOULDERS
                    },
                    sets: [
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Приседания со штангой",
                        muscle: MuscleEnum.QUADS
                    },
                    sets: [
                        {
                            repeats: 6,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 6,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 6,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Выпады с гантелями",
                        muscle: MuscleEnum.QUADS
                    },
                    sets: [
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        }
                    ]
                }
            ]
        },
        {
            title: "Тяни 1 (Спина + Бицепс)",
            description: "Тренировка мышц, осуществляющих тянущие движения. Акцент на мышцы спины и бицепс",
            typeOfActivity: TypeOfActivity.STREINGTH_TRANING,
            exercises: [
                {
                    exercise: {
                        title: "Тяга Т-грифа",
                        muscle: MuscleEnum.MIDDLE_BACK
                    },
                    sets: [
                        {
                            repeats: 6,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 6,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 6,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Подтягивания с доп.весом",
                        muscle: MuscleEnum.LATS
                    },
                    sets: [
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Пуловер в блоке",
                        muscle: MuscleEnum.LATS
                    },
                    sets: [
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Подъем штанги на бицепс",
                        muscle: MuscleEnum.BICEPS
                    },
                    sets: [
                        {
                            repeats: 6,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 6,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 6,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Подъем гантелей сидя с отрицательным углом",
                        muscle: MuscleEnum.BICEPS
                    },
                    sets: [
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Обратная бабочка",
                        muscle: MuscleEnum.SHOULDERS
                    },
                    sets: [
                        {
                            repeats: 15,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 15,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 15,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Сгибания ног в тренажере",
                        muscle: MuscleEnum.HAMSTRINGS
                    },
                    sets: [
                        {
                            repeats: 15,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 15,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 15,
                            weight: 0,
                            completed: false
                        }
                    ]
                }
            ]
        },
        {
            title: "Тяни 2 (Ноги + Дельты)",
            description: "Тренировка мышц, осуществляющих тянущие движения. Акцент на заднюю поверхность бедра и заднюю дельту",
            typeOfActivity: TypeOfActivity.STREINGTH_TRANING,
            exercises: [
                {
                    exercise: {
                        title: "Подтягивания на перекладине",
                        muscle: MuscleEnum.LATS
                    },
                    sets: [
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Тяга в нижнем хамере",
                        muscle: MuscleEnum.LATS
                    },
                    sets: [
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Махи с гантелями в наклоне",
                        muscle: MuscleEnum.SHOULDERS
                    },
                    sets: [
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Обратная бабочка",
                        muscle: MuscleEnum.SHOULDERS
                    },
                    sets: [
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Румынская тяга на прямых ногах",
                        muscle: MuscleEnum.HAMSTRINGS
                    },
                    sets: [
                        {
                            repeats: 6,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 6,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 6,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Сгибания ног в тренажере",
                        muscle: MuscleEnum.HAMSTRINGS
                    },
                    sets: [
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        }
                    ]
                }
            ]
        },
    ],
    duration: 90
}

export const ClassicSplit: ProgramStoreItem = {
    id: "04999cdf-0b1c-48f6-b3d9-610f7b5b5c47",
    title: "Классический сплит",
    imgUrl: "https://images.unsplash.com/photo-1590771998996-8589ec9b5ac6?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    shortDescription: "Один из самых популярных в бодибилденге сплит",
    traningGoal: TraningGoal.MUSCLE_GAIN,
    price: 0,
    description: `Данная программа предполагает тренировку отдельных мышечных групп в разные дни. Можно придумать множество различных комбинаций мышечных групп, но классической является следующая:
    грудь-бицепс, спина-трицепс, ноги-плечи. В каждый день тренировки вы концентрировано прокачиваете определенные мышечные группы, что позволяет полностью выложиться и дать большой стимул к росту.
    Предполагается делать 3-4 упражнения по 2-3 рабочих подхода на более крупную мышечную группу в тренировке, и 2-3 упражнения по 2-3 подхода на более маленьую. В данной программе используются как 
    базовые многосуставные движения, так и изоляция. Желательно делать по 2-3 отказных подхода на группу мышц за одну тренировку.
    Вес в упражнениях подбирается так, чтобы было тяжело делать указанное в программе количество раз. На каждой тренировке стараемся увеличивать рабочие веса или количество повторов. Делать более 12 повторов в подходе большого смысла не имеет. 
    В приоритете увеличение рабочего веса.`,
    programs: [
        {
            title: "Грудь-бицепс",
            description: "Сплит-тренировка на прокачку грудных мышц и двуглавой мышцы плеча",
            typeOfActivity: TypeOfActivity.STREINGTH_TRANING,
            exercises: [
                {
                    exercise: {
                        title: "Жим штанги лежа",
                        muscle: MuscleEnum.CHEST
                    },
                    sets: [
                        {
                            repeats: 6,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 6,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 6,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Жим гантелей на наклонной скамье (30)",
                        muscle: MuscleEnum.CHEST
                    },
                    sets: [
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Бабочка",
                        muscle: MuscleEnum.CHEST
                    },
                    sets: [
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Подъем штанги на бицепс",
                        muscle: MuscleEnum.BICEPS
                    },
                    sets: [
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Подъем гантелей с отрицательным углом",
                        muscle: MuscleEnum.BICEPS
                    },
                    sets: [
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        }
                    ]
                }
            ]
        },
        {
            title: "Спина-трицепс",
            description: "Сплит-тренировка на прокачку мышц спины и трицепса",
            typeOfActivity: TypeOfActivity.STREINGTH_TRANING,
            exercises: [
                {
                    exercise: {
                        title: "Тяга штанги в наклоне",
                        muscle: MuscleEnum.MIDDLE_BACK
                    },
                    sets: [
                        {
                            repeats: 6,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 6,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 6,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Подтягивания на перекладине с отягощением",
                        muscle: MuscleEnum.LATS
                    },
                    sets: [
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Тяга горизонтального блока",
                        muscle: MuscleEnum.MIDDLE_BACK
                    },
                    sets: [
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Шраги со штангой",
                        muscle: MuscleEnum.MIDDLE_BACK
                    },
                    sets: [
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Французский жим на наклонной скамье (45)",
                        muscle: MuscleEnum.TRICEPS
                    },
                    sets: [
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Разгибания в блоке",
                        muscle: MuscleEnum.TRICEPS
                    },
                    sets: [
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        }
                    ]
                }
            ]
        },
        {
            title: "Ноги-плечи",
            description: "Сплит-тренировка на прокачку мышц ног и дельт",
            typeOfActivity: TypeOfActivity.STREINGTH_TRANING,
            exercises: [
                {
                    exercise: {
                        title: "Приседания со штангой",
                        muscle: MuscleEnum.QUADS
                    },
                    sets: [
                        {
                            repeats: 6,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 6,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 6,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Разгибания в тренажере на квадрицепс",
                        muscle: MuscleEnum.QUADS
                    },
                    sets: [
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Выпады",
                        muscle: MuscleEnum.QUADS
                    },
                    sets: [
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Сгрибания ног в тренажере на заднюю поверхность бедра",
                        muscle: MuscleEnum.HAMSTRINGS
                    },
                    sets: [
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 12,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Махи гантелей в стороны",
                        muscle: MuscleEnum.SHOULDERS
                    },
                    sets: [
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Жим гантелей сидя",
                        muscle: MuscleEnum.SHOULDERS
                    },
                    sets: [
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        }
                    ]
                }
            ]
        }
    ],
    duration: 90
}

export const ClerkStory: ProgramStoreItem = {
    id: "98977f27-e65a-485a-8e12-3c0645bd8d2b",
    shortDescription: "Каждому клерку нужно следить за своим физическим здоровьем (по возможности)",
    title: "Clerk Story",
    traningGoal: TraningGoal.KEEPING_FIT,
    price: 0,
    duration: 60,
    imgUrl: "https://firebasestorage.googleapis.com/v0/b/gym-tracker-8d7ac.appspot.com/o/photo_2024-01-16_16-50-13.jpg?alt=media&token=2d56189b-607e-491e-b472-d675fa3353b2",
    description: `Тренировка для клерков`,
    programs: [
        {
            title: "Прокачка клерка",
            description: "Тренировка на все тело, чтобы кровушка не застаивалась",
            typeOfActivity: TypeOfActivity.STREINGTH_TRANING,
            exercises: [
                {
                    exercise: {
                        title: "Подтягивания на перекладине",
                        muscle: MuscleEnum.LATS
                    },
                    sets: [
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Жим штанги лежа",
                        muscle: MuscleEnum.CHEST
                    },
                    sets: [
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Приседания со штангой",
                        muscle: MuscleEnum.QUADS
                    },
                    sets: [
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 8,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Подъем штанги на бицепс",
                        muscle: MuscleEnum.BICEPS
                    },
                    sets: [
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 10,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Отжимания от пола",
                        muscle: MuscleEnum.TRICEPS
                    },
                    sets: [
                        {
                            repeats: 15,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 15,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 15,
                            weight: 0,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Скручивания на полу",
                        muscle: MuscleEnum.CORE
                    },
                    sets: [
                        {
                            repeats: 20,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 20,
                            weight: 0,
                            completed: false
                        },
                        {
                            repeats: 20,
                            weight: 0,
                            completed: false
                        }
                    ]
                }
            ]
        },
        {
            title: "Мобильность клерка",
            description: "Легкая тренировка для улучшения подвижности суствавов и растяжки мышц и связок",
            typeOfActivity: TypeOfActivity.STRECHING,
            exercises: [
                {
                    exercise: {
                        title: "Приседания со штангой",
                        muscle: MuscleEnum.QUADS
                    },
                    sets: [
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Становая тяга",
                        muscle: MuscleEnum.LOWER_BACK
                    },
                    sets: [
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Жим лежа на наклонной скамье (45)",
                        muscle: MuscleEnum.CHEST
                    },
                    sets: [
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        }
                    ]
                },
                {
                    exercise: {
                        title: "Подтягивания на перекладине",
                        muscle: MuscleEnum.LATS
                    },
                    sets: [
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        },
                        {
                            repeats: 5,
                            weight: 10,
                            completed: false
                        }
                    ]
                }
            ]
        }
    ]
}