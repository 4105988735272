import { Box, Grid, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { TimerState } from '../../redux/TimerSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/Store';
import { useNavigate } from 'react-router-dom';

interface IActiveWorkoutBarProps {
}

const ActiveWorkoutBar: React.FunctionComponent<IActiveWorkoutBarProps> = (props) => {

    const timer: string = useSelector((state: RootState) => state.workoutTimer.value)
    const activeWorkoutId = useSelector((state: RootState) => state.workout.activeWorkoutId)
    const [navbarHeight, setNavbarHeight] = React.useState(56)
    const theme = useTheme();
    const navigate = useNavigate();

    React.useEffect(() => {
        let element = document.getElementById("navbar");
        if (element != null) {
            let height = element.clientHeight;
            setNavbarHeight(height || 56)
        }
    }, [])

    return <Box
        onClick={() => navigate(`/workout-process/${activeWorkoutId}`)}
        id="active-workout-bar"
        sx={{
            position: "fixed",
            bottom: navbarHeight,
            width: "100%",
            right: 0,
            height: 60,
            zIndex: 1200,
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            boxShadow: 3
        }}>
        <Box sx={{
            height: "100%",
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center"
        }}>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column"
            }}>
                <Typography variant='caption' align='center'>Активная тренировка</Typography>
                <Typography variant='h4'>{timer}</Typography>
            </Box>
        </Box>
    </Box>;
};

export default ActiveWorkoutBar;
