import { Box, Grid, Typography } from "@mui/material";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { GetTraningPrograms } from "../../../api/TraningProgramAPI";
import Modal from "../../base/Modal";
import TraningProgramCard from "../../program/TraningProgramCard";
import { CreateWorkout, GetGroupedWorkoutHistory } from "../../../service/WorkoutService";
import { GetTraningProgramById } from "../../../service/TraningProgramService";
import TraningCard from "../../traning/TraningCard";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { startTimer } from "../../../redux/TimerSlice";
import { TraningProgram, Workout } from "../../../types/types";
import NoData from "../../base/NoData";

export const TraningScene = () => {
    const [traningHistory, setTraningHistory] = useState<{ [key: string]: Workout[] }>({})
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        const traningHistory = GetGroupedWorkoutHistory()
        setTraningHistory(traningHistory)
    }, [])

    const onStart = async (e: SyntheticEvent, traningProgram: TraningProgram) => {
        const workout = await CreateWorkout(traningProgram);
        dispatch(startTimer());
        navigateToWorkout(workout.id)
    }

    const handleChangeOpen = () => {
        setIsOpen(!isOpen)
    }

    const closeModal = () => {
        handleChangeOpen();
    }

    const onChangeModal = () => {
        if (isOpen) {
            closeModal();
        } else {
            handleChangeOpen();
        }
    }

    const navigateToWorkout = (id: string) => {
        navigate(`/workout-process/${id}`)
    }

    return <Box sx={{
        mb: 2,
        height: "100%"
    }}>
        {Object.keys(traningHistory).length > 0
            ? <Grid container spacing={2} sx={{ mt: 1 }}>
                {Object.keys(traningHistory).map((month: any, idx) => <Grid xs={12} item key={idx}>
                    <Typography variant="h6">{month}</Typography>
                    <Grid container spacing={2} mt={1}>
                        {traningHistory[month].map((workout: Workout, index: number) => {
                            let program: TraningProgram = GetTraningProgramById(workout.traningProgramId)!;
                            if (program !== undefined) {
                                return <Grid item key={index} xs={12}>
                                    <TraningCard
                                        title={program.title}
                                        onClick={() => { navigateToWorkout(workout.id) }}
                                        {...workout}
                                    />
                                </Grid>
                            }
                        })}
                    </Grid>
                </Grid>)}
            </Grid>
            : <NoData title="Тренировок пока нет" />}


        <Modal
            open={isOpen}
            onClose={onChangeModal}
            title="Выбери программу тренировок">
            <Grid container spacing={2}>
                {GetTraningPrograms().map((item, idx) => <Grid item xs={12} key={idx}>
                    <TraningProgramCard
                        onClick={onStart}
                        key={idx}
                        item={item}
                        sx={{ minHeight: "80px" }} />
                </Grid>
                )}
            </Grid>
        </Modal>
    </Box>
}

export default TraningScene;