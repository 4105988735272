import { Box, Typography, keyframes } from '@mui/material';
import * as React from 'react';

interface ILogoProps {
}


const myKeyframes = keyframes`
0% {
    background-position: 0% 50%;
},
50% {
    background-position: 100% 50%;
},
100% {
    background-position: 0% 50%;
}
`

const Logo: React.FunctionComponent<ILogoProps> = (props) => {
    return <Box sx={{
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    }}>
        <Box sx={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/logo_red.svg)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: 140,
            width: 140,
            zIndex: 10001,
        }} />
    </Box>
};

export default Logo;
