import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/Store';
import { Box, Button, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import FinishButton from '../traning/FinishButton';

export const WORKOUT_PROCESS_BAR_HEIGHT = "72px";

interface IWorkoutProcessBarProps {
    onFinish(): void,
    onEdit(): void,
    onCancel(): void
}

const WorkoutProcessBar: React.FunctionComponent<IWorkoutProcessBarProps> = (props) => {
    const timerValue: string = useSelector((state: RootState) => state.workoutTimer.value)
    const [appbarHeight, setAppbarHeight] = React.useState(56)
    const theme = useTheme();
    React.useEffect(() => {
        let element = document.getElementById("appBar");
        if (element != null) {
            let height = element.clientHeight;
            setAppbarHeight(height || 56)
        }
    }, [])


    return <Box sx={{
        backgroundColor: theme.palette.background.default,
        height: appbarHeight,
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        display: "flex",
        zIndex: 1200,
        p: 1,
        boxShadow: "none"
    }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={4} sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center"
            }}>
                <IconButton color='secondary' onClick={props.onEdit}>
                    <EditOutlined />
                </IconButton>
            </Grid>
            <Grid item xs={4} sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Typography>{timerValue}</Typography>
            </Grid>
            <Grid item xs={4} sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center"
            }}>
                <FinishButton onFinish={props.onFinish} onCancel={props.onCancel}/>
            </Grid>
        </Grid>
    </Box>;
};

export default WorkoutProcessBar;
