import { Box, Slide, Paper, Typography, Collapse, Button, useTheme } from '@mui/material';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProgramStoreItem } from '../../../../types/ProgramStoreTypes';
import { GetProgramStoreItemById } from '../../../../service/ProgramStoreService';
import PriceBadge from '../../../base/PriceBadge';

import AccessTimeIcon from '@mui/icons-material/AccessTime';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import HorizontalFeedLine from '../../../base/HorizontalElementLine';
import SubProgramCard from '../../../programStore/SubProgramCard';
import ShopingButton from '../../../base/ShopongButton';
import { CreateProgramsByStoreItem, FindProgramByStoreId } from '../../../../service/TraningProgramService';
import Dialog from '../../../base/Dialog';
import { useDialog, useModal } from '../../../../hooks/AppHooks';

const StoreTraningProgramInfoScene = () => {
    const { storeItemId } = useParams();
    const [item, setItem] = React.useState<ProgramStoreItem | undefined>(undefined)
    const [collapsed, setCollapsed] = React.useState(true);
    const theme = useTheme();
    const navigate = useNavigate();
    const { openDialog, handleCloseDialog, handleOpenDialog } = useDialog()
    const [purchased, setPurchased] = React.useState(false)

    const handleChangeCollapsed = () => {
        setCollapsed(!collapsed);
    }

    React.useEffect(() => {
        window.scrollTo()
        if (storeItemId) {
            GetProgramStoreItemById(storeItemId)
                .then(res => {
                    if (res) {
                        setItem(res)
                    }
                })
            setPurchased(FindProgramByStoreId(storeItemId) != undefined)
        }

    }, [storeItemId])

    const confirmBayProgram = () => {
        if (item) {
            CreateProgramsByStoreItem(item)
                .then(res => navigate("/traning-programs/favorite"))
                .catch(e => console.error(e))
        }
    }

    const bayProgram = () => {
        handleOpenDialog()
    }

    if (!item)
        return <div />

    return <Box sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        color: theme.palette.secondary.contrastText
    }}>
        <Slide direction='down' in={true} timeout={250}>
            <Box sx={{
                width: "100%",
                backgroundImage: item.imgUrl ? `url(${item.imgUrl})` : "none",
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: 1,
                height: "480px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: 1
            }}>
                <Box sx={{
                    background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.95), transparent);",
                    p: 1,
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8
                }}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <Typography variant='h4'>{item.title}</Typography>
                        <PriceBadge price={item.price} purchased={purchased} />
                    </Box>

                    <Typography variant='caption'>{item.shortDescription}</Typography>
                </Box>

                <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 1
                }}>
                    <Typography variant='caption'>Кол-во сетов: {item.programs.length}</Typography>

                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1
                    }}>
                        <AccessTimeIcon />
                        <Typography variant='caption'>{item.duration} мин.</Typography>
                    </Box>
                </Box>
            </Box>
        </Slide>

        <ShopingButton price={item.price} onClick={() => { bayProgram() }} disable={purchased} />

        <HorizontalFeedLine height={180}>
            {item.programs.map((p, idx) => <SubProgramCard key={idx} item={p} />)}
        </HorizontalFeedLine>

        <Slide direction='up' in timeout={250}>
            <Paper onClick={handleChangeCollapsed} sx={{
                p: 1,
                maxHeight: collapsed ? "100%" : "auto"
            }}>
                <Typography variant='body1' textAlign={"justify"} sx={{
                    whiteSpace: collapsed ? "nowrap" : "none",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }}>{item.description}</Typography>

                <Box sx={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    {collapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </Box>
            </Paper>
        </Slide>

        <Dialog
            title="Подтверждение покупки"
            open={openDialog}
            onClose={handleCloseDialog}
            actions={[
                <Button key={1} onClick={handleCloseDialog}>Отменить</Button>,
                <Button key={0} onClick={confirmBayProgram} color='secondary'>Продолжить</Button>

            ]}>
            <Typography>При совершении покупки в списке ваших программ и упражнений появятся приобретенные элементы.</Typography>

        </Dialog>

    </Box>;
};

export default StoreTraningProgramInfoScene;
