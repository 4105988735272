import { IRouterSlice } from "../reduxTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const RouterSliceInitialState: IRouterSlice = {
    path: "/",
    title: "Title",
    needBack: false
}

export const RouterSlice = createSlice({
    name: "ChangeRoute",
    initialState: RouterSliceInitialState,
    reducers: {
        changePath: (state: IRouterSlice, action: PayloadAction<string>) => {
            state.path = action.payload
        }
    }
})

export const { changePath } = RouterSlice.actions;
export default RouterSlice.reducer;