import React from 'react';
import { Grow, Paper, Typography } from "@mui/material"
import { MuscleGroupsInfo } from '../Constants';
import { Box } from '@mui/system';
import { GetUniqueMuscleGroups, JoinUniqueMuscleGroups } from "../../service/TraningProgramService"
import { MuscleEnum, TraningProgram } from '../../types/types';
import ProgramIcon from './ProgramIcon';

interface TraningProgramCardProps {
    item: TraningProgram,
    sx?: object,
    onClick(e: any, item: TraningProgram): void,
    withCount?: boolean,
    withMuscles?: boolean
}

const TraningProgramCard = (props: TraningProgramCardProps) => {
    const muscleGroups: string = JoinUniqueMuscleGroups(props.item.exercises)
    return <Grow in timeout={250}>
        <Paper
            sx={{
                display: "flex",
                width: "100%",
                minHeight: "160px",
                maxHeight: "160px",
                flexDirection: 'column',
                p: 1,
                ...props.sx
            }}
            onClick={(e) => props.onClick(e, props.item)}>
            {props.withCount && <Box sx={{ flexGrow: 4 }}>

                <Typography variant='caption'>Упражнений {props.item.exercises.length || 0}</Typography>

            </Box>}
            <Box sx={{ flexGrow: 1 }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <Typography variant='subtitle2'>{props.item.title}</Typography>
                    <ProgramIcon typeOfActivity={props.item.typeOfActivity} />
                </Box>

            </Box>
            {(props.withMuscles == undefined || props.withMuscles) && <Box sx={{ flexGrow: 1, display: 'flex', alignItems: "end", width: '100%' }}>
                <Typography variant='caption' sx={{ textOverflow: "ellipsis", overflow: 'hidden' }}>{muscleGroups}</Typography>
            </Box>}
        </Paper>
    </Grow>
}

export default TraningProgramCard;