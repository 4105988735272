import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from '@mui/material';
import * as React from 'react';
import { GetDaysOfWeekArray } from '../../utils/EnumUtils';
import { DaysOfWeekInfo } from '../Constants';
import { DaysOfWeek, Workout } from '../../types/types';
import { GetWorkoutByWeek } from '../../service/WorkoutService';
import { isToday as IS_TODAY, addDays } from 'date-fns';
import { StartOfWeek } from '../../utils/DateUtils';
import { useNavigate } from 'react-router-dom';

interface IWeeklyWorkoutsStatsProps {
}

const WeeklyWorkoutsStats: React.FunctionComponent<IWeeklyWorkoutsStatsProps> = (props) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [workoutsByWeek, setWorkoutsByWeek] = React.useState<Workout[]>([]);

    React.useEffect(() => {
        setWorkoutsByWeek(GetWorkoutByWeek())
    }, [])

    const dayWithWorkout = (dayOfWeek: number): Workout[] => {
        return workoutsByWeek.filter(w => {
            return new Date(w.startAt).getDay() - 1 == dayOfWeek
        })
    }

    return <Box sx={{
        display: 'flex',
        width: "100%",
        flexDirection: "row"
    }}>
        <TableContainer component={Paper} sx={{
            mt: 1,
            mb: 2,
            backgroundColor: theme.palette.background.paper
        }}>
            <Table>
                <TableHead>
                    <TableRow>
                        {GetDaysOfWeekArray().map((day, idx) => {
                            const date = addDays(StartOfWeek(), idx)
                            const isToday = IS_TODAY(date);
                            return <TableCell key={idx} sx={{

                            }}>{DaysOfWeekInfo[DaysOfWeek[day]].localShort}</TableCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>

                    <TableRow>
                        {GetDaysOfWeekArray().map((day, idx) => {
                            const workouts = dayWithWorkout(idx)
                            return <TableCell key={idx}>
                                <Box onClick={() => {
                                    if (workouts.length > 0)
                                        navigate(`/workout-process/${workouts[workouts.length - 1].id}`)
                                }} sx={{
                                    borderRadius: "50%",
                                    width: workouts.length > 0 ? "15px" : "7px",
                                    height: workouts.length > 0 ? "15px" : "7px",
                                    backgroundColor: workouts.length > 0 ? theme.palette.secondary.main : theme.palette.background.default,
                                    border: workouts.length > 0 ? "none" : `2px solid ${theme.palette.secondary.main}`
                                }}></Box>
                            </TableCell>
                        })}
                    </TableRow>

                </TableBody>
            </Table>

        </TableContainer>
    </Box>;
};

export default WeeklyWorkoutsStats;
