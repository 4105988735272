import * as React from "react";
import { Box, Typography } from "@mui/material";

interface INoDataProps {
    title: string
}

const NoData = (props: INoDataProps) => <Box sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%"
}}>
    <Typography variant='caption'>{props.title}</Typography>
</Box>

export default NoData;