import { FormControl, InputLabel, MenuItem, Paper, Select, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts';
import { MuscleGroupsInfo } from '../Constants';
import { MuscleData, MuscleEnum, SetsInfo } from '../../types/types';
import { GetMuscleEnumArray } from '../../utils/EnumUtils';

interface IMultilineChartProps {
    title: string,
    data: MuscleData[]
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface TooltipProps {
    selectedMuscle: MuscleEnum,
    payload?: any
}

const CustomTooltip = (props: TooltipProps) => {

    if (props.payload == null || props.payload == 'undefined')
        return <div></div>

    return <Box sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
        {props.payload.length > 0 && <Typography variant='caption' color="secondary">{props.payload[0].payload.date}</Typography>}
        {props.payload.length > 0 && <Typography variant='caption' color="secondary">{props.selectedMuscle} - {props.payload[0].payload.value}</Typography>}
    </Box>
}

const NoData = () => <Box sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "160px"
}}>
    <Typography variant='caption'>Данных нет</Typography>
</Box>

const MultilineChartCard = (props: IMultilineChartProps) => {
    const [selectedMuscle, setSelectedMuscle] = useState<MuscleEnum>(MuscleEnum.BICEPS)
    const [setsData, updateSetsData] = useState<SetsInfo[]>([]);
    useEffect(() => {
        if (props.data != undefined) {
            const newData = props.data.find((item) => item.muscle == selectedMuscle);
            if (newData && newData.data)
                updateSetsData(newData.data)
        }

    }, [selectedMuscle, props.data])
    const theme = useTheme();

    return <Paper sx={{ p: 1, height: 300 }}>
        <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
            <Typography variant="h6">{props.title}</Typography>
            <FormControl
                variant='standard'
                sx={{ m: 1, ml: 0, mr: 0, pt: 1, pb: 1 }}
                fullWidth>
                <InputLabel id="demo-multiple-name-label">Мышечные группы</InputLabel>
                <Select
                    onChange={(e) => {
                        const muscle = e.target.value as unknown as MuscleEnum
                        setSelectedMuscle(muscle)
                    }}
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={selectedMuscle}
                    MenuProps={MenuProps}>
                    {GetMuscleEnumArray().map((name, idx) => {
                        return <MenuItem
                            key={idx}
                            value={MuscleEnum[name]}>
                            {MuscleGroupsInfo[MuscleEnum[name]].locale}
                        </MenuItem>
                    })}
                </Select>
            </FormControl>
        </Box>
        {setsData.length == 0 && <NoData />}
        {setsData.length > 0 && <ResponsiveContainer height={160} width="100%">

            <AreaChart
                data={setsData}>
                <Tooltip
                    position={{ y: 0 }}
                    content={<CustomTooltip selectedMuscle={selectedMuscle} />}
                    wrapperStyle={{ backgroundColor: theme.palette.secondary.light, outlineColor: theme.palette.secondary.dark, borderRadius: 1 }} />
                <Area
                    type="monotone"
                    dataKey="value"
                    strokeWidth={2}
                    stroke={theme.palette.secondary.dark}
                    fill={theme.palette.secondary.light} />
            </AreaChart>
        </ResponsiveContainer>}
    </Paper>
}

export default MultilineChartCard;