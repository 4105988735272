import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Workout } from "../types/types"

export type WorkoutState = {
    activeWorkoutId: string | undefined
}

export const WorkoutInitialState: WorkoutState = {
    activeWorkoutId: undefined
}

export const WorkoutSlice = createSlice({ 
    name: "Workout",
    initialState: WorkoutInitialState,
    reducers: {
        startWorkout: (state: WorkoutState, action: PayloadAction<Workout>) => {
            state.activeWorkoutId = action.payload.id;
        },
        completeWorkout: (state: WorkoutState) => {
            state.activeWorkoutId = undefined
        }
    }
})

export const {startWorkout, completeWorkout} = WorkoutSlice.actions;
export default WorkoutSlice.reducer;