import { Box, Divider, Paper, Typography } from '@mui/material';
import * as React from 'react';
import { Step } from '../../types/RecipeTypes';

interface IStepsProps {
    steps: Step[]
}

interface IStepCardProps {
    step: Step
}

const StepCard: React.FunctionComponent<IStepCardProps> = ({ step }) => {
    return <Paper sx={{
        display: "flex",
        flex: "1 1 50%",
        p: 4
    }}>
        <Typography>{step.description}</Typography>
    </Paper>
}

const Steps: React.FunctionComponent<IStepsProps> = ({ steps }) => {
    return <Box sx={{
        display: "flex",
        gap: 1,
        flexDirection: "column"
    }}>
        {steps.map((item, idx) => <Box key={idx} sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1
        }}>
            <StepCard step={item} />
            {idx < steps.length - 1 && <Divider variant="middle" />}
        </Box>)}
    </Box>;
};

export default Steps;
