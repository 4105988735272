import * as React from 'react';
import MenuBox from './MenuBox';
import { Box, FormControlLabel, FormGroup, ListItem, Switch } from '@mui/material';
import { ThemeAccent, ThemePalette } from '../../reduxTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/Store';
import { changeAccent, changePalette } from '../../redux/ThemeSlice';
import { ThemeColors } from '../Constants';
import { Color } from '../../types/types';
import XmasThemeSettingItem from './XmasThemeSettingItem';


const ColorSelector = ({ }) => {
    const accent: ThemeAccent = useSelector((state: RootState) => state.themePalette.accent) || ThemeAccent.orange
    const dispatch = useDispatch();

    return <Box sx={{
        display: "flex",
        height: "50px",
        width: "100%",
        overflow: "hidden",
        gap: 1,
        justifyContent: "space-between",
        alignItems: "center"
    }}>
        {(Object.keys(ThemeAccent) as (keyof typeof ThemeAccent)[]).map((value, idx) => {
            let color: Color = ThemeColors[value];
            return <Box key={idx} sx={{
                backgroundColor: color.main,
                height: value == accent ? "50px" : "30px",
                width: value == accent ? "50px" : "30px",
                borderRadius: "50%",
                transition: "height 0.2s, width 0.2s",
                m: "auto"
            }}
                onClick={() => dispatch(changeAccent(ThemeAccent[value]))}></Box>
        })}

    </Box>
}

const ThemeSettingsBox: React.FunctionComponent = () => {
    const palette: ThemePalette = useSelector((state: RootState) => state.themePalette.palette)
    const xmasThemeEnabled = useSelector((state: RootState) => state.themePalette.enableXmaxTheme);
    const dispatch = useDispatch();

    return <MenuBox title="Оформление">
        <XmasThemeSettingItem />
        {!xmasThemeEnabled && <ListItem disablePadding>
            <FormGroup>
                <FormControlLabel
                    control={<Switch color='secondary' checked={palette === ThemePalette.dark} onChange={() => dispatch(changePalette())} />}
                    label="Темная тема" />
            </FormGroup>

        </ListItem>}
        {!xmasThemeEnabled && <ListItem>
            <ColorSelector />
        </ListItem>}

        
    </MenuBox>;
};

export default ThemeSettingsBox;
