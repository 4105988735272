import { colors, createTheme } from "@mui/material";
import { ThemeAccent } from "../reduxTypes";
import { ThemeColors } from "../components/Constants";
import { access } from "fs";
import { Color } from "../types/types";
import { light } from "@mui/material/styles/createPalette";
import { ruRU } from '@mui/x-date-pickers/locales';
import { ruRU as RU } from '@mui/material/locale';

const BuildTheme = (dark: boolean, secondary: Color) => {
    return createTheme({
        palette: {
            mode: dark ? "dark" : "light",
            primary: {
                main: "#EAE1DF",
                light: "#f7f3f3",
                dark: "#be9d9d",
                contrastText: "#322E18"
            },
            secondary: secondary
        },
        typography: {
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
        shape: {
            borderRadius: 8
        },
        components: {
            MuiPaper: {
                defaultProps: {
                    elevation: 4
                }
            }
        }
    }, ruRU)
}

export const CreateLightTheme = (accent: ThemeAccent) => {
    return BuildTheme(false, ThemeColors[accent]);
}

export const CreateDarkTheme = (accent: ThemeAccent) => {
    return BuildTheme(true, ThemeColors[accent]);
}