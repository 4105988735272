import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React from 'react';
import { MuscleGroupInfo, MuscleGroupsEnum, MuscleGroupsInfo } from '../Constants';
import { MuscleEnum } from '../../types/types';
import EntityActionButtonGroup, { Kind } from '../base/EntityActionButtonGroup';
import Snackbar from '../base/Snackbar';
import Dialog from '../base/Dialog';
import { useSnackbar } from '../../hooks/SnackbarHooks';
import { useDialog } from '../../hooks/AppHooks';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface ExerciseFormProps {
    onChange(e: any, s: string): void,
    exercise: any,
    kind: Kind,
    onSave(): void,
    onRemove?(): void
}

const ExerciseFrom = (props: ExerciseFormProps) => {
    const { open, handleClose, handleOpen, message, hideDuration } = useSnackbar();
    const { openDialog, handleCloseDialog, handleOpenDialog } = useDialog()

    return <Box>

        <TextField
            onChange={(e) => { props.onChange(e, "title") }}
            fullWidth
            id="exercise-title"
            label="Название"
            variant="standard"
            value={props.exercise.title}
            sx={{ pt: 1, pb: 1 }} />

        <FormControl
            variant='standard'
            sx={{ m: 1, ml: 0, mr: 0, pt: 1, pb: 1 }}
            fullWidth>
            <InputLabel id="demo-multiple-name-label">Мышечные группы</InputLabel>
            <Select
                onChange={(e) => { props.onChange(e, "muscle") }}
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={props.exercise.muscle}
                MenuProps={MenuProps}>
                {MuscleGroupsEnum.map((key: any) => {
                    var muscle: keyof typeof MuscleEnum = key;
                    return <MenuItem
                        key={key}
                        value={muscle}>
                        {MuscleGroupsInfo[muscle].locale}
                    </MenuItem>
                })}
            </Select>
        </FormControl>


        <EntityActionButtonGroup
            kind={props.kind}
            onRemove={handleOpenDialog}
            onSave={() => {
                props.onSave();
                handleOpen("Данные успешно сохранены", 3000)
            }}
            shareItem={props.exercise}
            editable={false}
            onShare={() => console.debug("Shared")}
        />


        <Snackbar hideDuration={hideDuration} message={message} handleClose={handleClose} open={open} />

        <Dialog
            open={openDialog}
            onClose={handleClose}
            title="Удаление программы"
            actions={[
                <Button key={0} onClick={handleCloseDialog}>Отмена</Button>,
                <Button key={1} color='secondary' onClick={() => {
                    if (props.onRemove) {
                        props.onRemove()
                    }
                }}>Удалить</Button>

            ]}>
            <Typography>Вы уверены, что хотите удалить упражнение?</Typography>
        </Dialog>
    </Box>
}

export default ExerciseFrom;