import { useNavigate } from "react-router-dom";
import MainButton from "./components/base/MainButton";
import { Feed } from "./types/types";
import { Link, Typography } from "@mui/material";
import * as React from 'react';

export const TraningProgramStore = (navigate: any): Feed => {
    return {
        id: 10,
        title: "Магазин программ",
        text: <Typography sx={{
            background: "rgba(0, 0, 0, 0.75)",
            p: 1,
            textAlign: "justify",
            color: 'white'
        }}>
            Магазин тренировочных программ доступен уже сейчас! Заходи и выбирай программу по душе.
        </Typography>,
        img: "https://images.unsplash.com/photo-1556817411-31ae72fa3ea0?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        imgFull: "https://images.unsplash.com/photo-1556817411-31ae72fa3ea0?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        enable: true,
        action: <MainButton text="Магазин программ" onClick={() => navigate("/store/traning-programs")}/>,
        useGrayFilter: false
    }
}