import { PayloadAction, createSlice } from "@reduxjs/toolkit"


export type UserState = {
    favoriteDishes: string[]
}

export const UserInitialState: UserState = {
    favoriteDishes: []
}

export const UserSlice = createSlice({
    name: "User",
    initialState: UserInitialState,
    reducers: {
        addFavoriteDish: (state: UserState, payload: PayloadAction<string>) => {
            let items = state.favoriteDishes;
            items.push(payload.payload)
            state.favoriteDishes = items;
        },
        removeFavoriteDish: (state: UserState, payload: PayloadAction<string>) => {
            state.favoriteDishes = state.favoriteDishes.filter(i => i != payload.payload);
        }
    }
})

export const { addFavoriteDish, removeFavoriteDish } = UserSlice.actions;
export default UserSlice.reducer;