import { Exercise, MuscleEnum, TraningProgram, TypeOfActivity, WorkoutSet } from "./types"

export interface StoreItem {
    title: string,
    id: string,
    shortDescription: string,
    description: string,
    price: number,
    imgUrl?: string,
    author?: string,
    traningGoal: TraningGoal,
    duration: number
}

export interface ProgramStoreItem extends StoreItem {
    programs: StoredTraningProgram[]
}

export interface ExerciseStoreItem extends StoreItem {
    exercise: StoredExercise
}

export interface StoredTraningProgram {
    title: string,
    description?: string,
    exercises: StoredExerciseItem[],
    typeOfActivity?: TypeOfActivity,
    duration?: number
}

export interface StoredExercise {
    title: string,
    muscle: MuscleEnum,
    description?: string,
    imgUrls?: string[]
}

export type StoredExerciseItem = {
    exercise: StoredExercise,
    sets: WorkoutSet[]
}

export enum TraningGoal {
    MUSCLE_GAIN = "MUSCLE_GAIN",
    REDUCING_BODY_FAT_LEVELS = "REDUCING_BODY_FAT_LEVELS",
    KEEPING_FIT = "KEEPING_FIT"
}