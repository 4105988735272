import { Box } from '@mui/material';
import * as React from 'react';

const EMOJI_LIST = ["🍛", "🍲", "🍴", "🥘", "🥩", "🍖", "🥄", "🍎", "🍌"]
var i = 0;

interface IRandomizeRecipeLoaderProps {
}

const RandomizeRecipeLoader: React.FunctionComponent<IRandomizeRecipeLoaderProps> = (props) => {
    const [index, setIndex] = React.useState<number>(0)
    React.useEffect(() => {
        let interval: NodeJS.Timer;
        interval = setInterval(() => {
            changeIndex()
        }, 100);
        return () => clearInterval(interval)
    }, [])

    const changeIndex = () => {
        let next = i + 1;
        if (next == EMOJI_LIST.length) {
            next = 0
        }
        setIndex(next)
        i = next;
    }

    return <Box sx={{
        fontSize: 60
    }}>{EMOJI_LIST[i]}</Box>;
};

export default RandomizeRecipeLoader;
