
import { Box, useTheme } from '@mui/material';
import * as React from 'react';

interface IPriceBadgeProps {
    price: number,
    purchased: boolean
}

const PriceBadge: React.FunctionComponent<IPriceBadgeProps> = ({ price, purchased }) => {
    const theme = useTheme();
    const getPrice = () => price == 0 ? "Бесплатно" : `${price} ₽`
    return <Box sx={{
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        p: 1,
        borderRadius: 4,
        backgroundColor: purchased ? theme.palette.grey[500] : theme.palette.secondary.dark
    }}>
        {purchased ? "Добавлено" : getPrice()}
    </Box>;
};

export default PriceBadge;
