import React, { useEffect, useState } from "react";
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { DefaultExercises, DefaultTraningPrograms } from "./components/Constants";
import { createHashRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";

import TraningScene from "./components/scenes/traningProcess/TraningScene";
import ExercisesScene from "./components/scenes/exercise/ExercisesScene";
import TraningProgramScene from "./components/scenes/programs/TraningProgramScene";
import Layout from "./components/base/Layout";
import WorkoutProcessScene from "./components/scenes/traningProcess/TraningProcessScene";
import ProgressScene from "./components/scenes/progress/ProgressScene";
import DebugScene from "./components/scenes/settings/DebugScene";
import SettingsScene from "./components/scenes/settings/SettingsScene";
import Splash from "./components/base/Splash";
import { Box, Container, CssBaseline, Grid, ThemeProvider } from "@mui/material";
import SignInScene from "./components/scenes/auth/SignInScene";
import { ThemeAccent, ThemePalette } from "./reduxTypes";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { RootState } from "./redux/Store";
import ProfileScene from "./components/scenes/settings/ProfileScene";
import HomeScene from "./components/scenes/HomeScene";
import { CreateDarkTheme, CreateLightTheme } from "./service/ThemeService";
import SchedulesScene from "./components/scenes/schedule/SchedulesScene";
import { FeatureAuthIsEnable, FeatureFoodIsEnable, FeatureSchedulesIsEnable, FeatureXmasThemeIsEnable } from "./utils/ProfileUtils";
import { changeXmasTheme, disableXmasTheme } from "./redux/ThemeSlice";
import FoodScene from "./components/scenes/food/FoodScene";
import TraningProgramInfo from "./components/program/TraningProgramInfo";
import TraningProgramInfoScene from "./components/scenes/programs/TraningProgramInfoScene";
import TraningProgramStoreScene from "./components/scenes/programs/TraningProgramStoreScene";
import MyTraningProgramScene from "./components/scenes/programs/MyTraningProgramScene";
import CreateTraningProgramScene from "./components/scenes/programs/CreateTraningProgramScene";
import StoreTraningProgramScene from "./components/scenes/store/program/StoreTraningProgramScene";
import StoreTraningProgramInfoScene from "./components/scenes/store/program/StoreTraningProgramInfoScene";

const initExercises = () => {
  if (localStorage.getItem("exercises") == null)
    localStorage.setItem("exercises", JSON.stringify(DefaultExercises))
  if (localStorage.getItem("traning_programs") == null)
    localStorage.setItem("traning_programs", JSON.stringify(DefaultTraningPrograms))
}

const router = createHashRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomeScene />} />
        <Route path="home" element={<HomeScene />} />
        <Route path="exercises" element={<ExercisesScene />} />
        <Route path="traning-programs" element={<TraningProgramScene />} />
        <Route path="traning-programs/store" element={<TraningProgramStoreScene />} />
        <Route path="traning-programs/favorite" element={<MyTraningProgramScene />} />
        <Route path="traning-programs/create" element={<CreateTraningProgramScene />} />
        <Route path="traning-programs/:programId" element={<TraningProgramInfoScene />} />
        <Route path="store/traning-programs" element={<StoreTraningProgramScene />} />
        <Route path="store/traning-programs/:storeItemId" element={<StoreTraningProgramInfoScene />} />
        <Route path="traning" element={<TraningScene />} />
        <Route path="workout-process/:workoutId" element={<WorkoutProcessScene />} />
        <Route path="progress" element={<ProgressScene />} />
        <Route path="settings" element={<SettingsScene />} />
        <Route path="debug" element={<DebugScene />} />
        <Route path="profile" element={<ProfileScene />} />
        {FeatureSchedulesIsEnable && <Route path="schedules" element={<SchedulesScene />} />}
        {FeatureAuthIsEnable && <Route path="sign-in" element={<SignInScene />} />}
        {FeatureFoodIsEnable && <Route path="food/random-recipe" element={<FoodScene />} />}
      </Route>

    </Route>

  )
);

const App = () => {
  const palette: ThemePalette = useSelector((state: RootState) => state.themePalette.palette)
  const accent: ThemeAccent = useSelector((state: RootState) => state.themePalette.accent) || ThemeAccent.orange;
  const [showSpalash, setShowSplash] = useState(false);
  const dispatch = useDispatch();

  const xmasThemeEnabled = useSelector((state: RootState) => state.themePalette.enableXmaxTheme)

  const promise = new Promise((resolve, reject) => {
    initExercises()
    setTimeout(resolve, 2500)
  })

  useEffect(() => {
    promise.then(() => {
      setShowSplash(false)
    })
      .catch((e) => setShowSplash(false))

    if (!FeatureXmasThemeIsEnable && xmasThemeEnabled) {
      dispatch(disableXmasTheme())
    }
  }, [])

  return (<Container maxWidth="md" disableGutters sx={{
    height: "100%"
  }}>
    <Box sx={{ height: "100%" }}>
      <CssBaseline />
      <ThemeProvider theme={palette === ThemePalette.light ? CreateLightTheme(accent) : CreateDarkTheme(accent)}>
        {showSpalash && <Splash />}
        <RouterProvider router={router} />
      </ThemeProvider>

    </Box>
  </Container>);
}

export default App;