import { Box, Button, useTheme } from '@mui/material';
import * as React from 'react';
import html2canvas from 'html2canvas';
import { Share } from '@mui/icons-material';

export interface IShareContainerProps {
    children?: JSX.Element
}

const ShareContainer: React.FunctionComponent<IShareContainerProps> = (props) => {
    const theme = useTheme();
    const ref = React.useRef(null);

    const onShare = () => {
        if (ref.current) {
            html2canvas(ref.current).then(async (canvas) => {
                const dataUrl = canvas.toDataURL();
                const blop = await (await fetch(dataUrl)).blob();
                const filesArray = [
                    new File(
                        [blop],
                        "test.png",
                        {
                            type: blop.type,
                            lastModified: new Date().getTime()
                        }
                    )
                ]

                const shareData = {
                    files: filesArray
                }

                if (navigator.share) {
                    navigator.share(shareData)
                        .then(() => {
                            console.debug("Спасибо")
                        })
                        .catch(console.error)
                }
            })
        }

    }

    return <Box sx={{
        height: "100%",
        width: "100%",
        display: 'flex',
        flexDirection: "column",
        gap: 1
    }}>
        <Box sx={{
            display: 'flex',
            width: "100%",
            border: `4px dashed ${theme.palette.secondary.main}`,
            borderRadius: 4,
            overflow: "hidden"
        }}>
            <div ref={ref} style={{
                height: "100%",
                width: "100%",
                padding: "20px",
                backgroundColor: theme.palette.background.default
            }}>
                {props.children}
            </div>

        </Box >
        <Button fullWidth variant='contained' color='secondary' onClick={onShare} endIcon={<Share />}>Поделиться</Button>
    </Box>
};

export default ShareContainer;
