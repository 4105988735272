import { v4 as uuidv4 } from 'uuid';
import { Color, MuscleEnum, DaysOfWeek, ScheduleType, WeekNumber, TypeOfActivity } from '../types/types';
import { ThemeAccent } from '../reduxTypes';

export const DaysOfWeekInfo: { [key in keyof typeof DaysOfWeek]: any } = {
    MONDAY: {
        "locale": "Понедельник",
        "localShort": "Пн"
    },
    THUESDAY:  {
        "locale": "Вторник",
        "localShort": "Вт"
    },
    WEDNESDAY:  {
        "locale": "Среда",
        "localShort": "Ср"
    },
    THURSDAY:  {
        "locale": "Четверг",
        "localShort": "Чт"
    },
    FRIDAY:  {
        "locale": "Пятница",
        "localShort": "Пт"
    },
    SATURDAY:  {
        "locale": "Суббота",
        "localShort": "Сб"
    },
    SUNDAY:  {
        "locale": "Воскресенье",
        "localShort": "Вс"
    }
}

export const ScheduleTypeInfo: { [key in keyof typeof ScheduleType]: any} = {
    ONE_WEEK: {
        locale: "Недельное расписание"
    },
    TWO_WEEK: {
        locale: "Двухнедельное расписание"
    }
}

export const WeekNumberInfo: { [key in keyof typeof WeekNumber] : any} = {
    FIRST_WEEK: {
        locale: "Первая неделя"
    },
    SECOND_WEEK: {
        locale: "Вторая неделя цикла"
    }
}

export const TypeOfActivityInfo: { [key in keyof typeof TypeOfActivity] : any} = {
    STRECHING: {
        locale: "Растяжка и мобильность"
    },
    STREINGTH_TRANING: {
        locale: "Силовая тренировка"
    },
    FUNCTIONAL_TRANING: {
        locale: "Функциональная тренировка"
    },
    CARDIO: {
        locale: "Кардио тренировка"
    }
}

export const MuscleGroupsInfo: { [key in keyof typeof MuscleEnum]: any } = {
    BICEPS: {
        "locale": "Бицепс",
        "group": "ARM"
    },
    TRICEPS: {
        "locale": "Трицепс",
        "group": "ARM"
    },
    FOREARMS: {
        "locale": "Предплечья",
        "group": "ARM"
    },
    QUADS: {
        "locale": "Передняя поверхность бедра",
        "group": "LEGS"
    },
    GLUTES: {
        "locale": "Ягодицы",
        "group": "LEGS"
    },
    HAMSTRINGS: {
        "locale": "Задняя поверхность бедра",
        "group": "LEGS"
    },
    CORE: {
        "locale": "Пресс",
        "group": "CORE"
    },
    CHEST: {
        "locale": "Грудь",
        "group": "CHEST"
    },
    SHOULDERS: {
        "locale": "Плечи",
        "group": "SHOULDERS"
    },
    NECK: {
        "locale": "Шея",
        "group": "NECK"
    },
    LATS: {
        "locale": "Широчайшие",
        "group": "BACK"
    },
    LOWER_BACK: {
        "locale": "Разгибатели спины/Поясница",
        "group": "BACK"
    },
    MIDDLE_BACK: {
        "locale": "Середина спины",
        "group": "BACK"
    },
    TRAPS: {
        "locale": "Трапеции",
        "group": "BACK"
    }
};

export const MuscleGroupsEnum = [
    "BICEPS",
    "TRICEPS",
    "FOREARMS",
    "QUADS",
    "GLUTES",
    "HAMSTRINGS",
    "CORE",
    "CHEST",
    "SHOULDERS",
    "NECK",
    "LATS",
    "LOWER_BACK",
    "MIDDLE_BACK",
    "TRAPS"
];

export const MuscleGroupEnum = [
    "ARM",
    "LEGS",
    "CORE",
    "CHEST",
    "SHOULDERS",
    "BACK",
    "NECK"
]

export const MuscleGroupInfo = {
    "ARM": {
        locale: "Руки"
    },
    "LEGS": {
        locale: "Ноги"
    },
    "CORE": {
        locale: "Мышцы кора"
    },
    "CHEST": {
        locale: "Грудные мышцы"
    },
    "SHOULDERS": {
        locale: "Дельты"
    },
    "BACK": {
        locale: "Спина"
    },
    "NECK": {
        locale: "Шея"
    }
}

export const DefaultExercises = [
    {
        "id": "100",
        "title": "Подъем штанги на бицепс",
        "muscle": "BICEPS"
    },
    {
        "id": "101",
        "title": "Подтягивания обратным хватом",
        "muscle": "BICEPS"
    },
    {
        "id": "102",
        "title": "Подъем гантелей в скамье Скотта",
        "muscle": "BICEPS"
    },
    {
        "id": "103",
        "title": "Подъем гантелей с трицательным углом",
        "muscle": "BICEPS"
    },
    {
        "id": "104",
        "title": "Молотки с гантелями",
        "muscle": "BICEPS"
    },


    {
        "id": "200",
        "title": "Отжимания от пола",
        "muscle": "TRICEPS"
    },
    {
        "id": "201",
        "title": "Французский жим со штангой",
        "muscle": "TRICEPS"
    },
    {
        "id": "202",
        "title": "Французский жим с гантелями",
        "muscle": "TRICEPS"
    },
    {
        "id": "203",
        "title": "Жим лежа узким хватом",
        "muscle": "TRICEPS"
    },
    {
        "id": "204",
        "title": "Разгибания в верхнем блоке",
        "muscle": "TRICEPS"
    },


    {
        "id": "300",
        "title": "Приседания со штангой",
        "muscle": "QUADS"
    },
    {
        "id": "301",
        "title": "Жим ногами",
        "muscle": "QUADS"
    },
    {
        "id": "302",
        "title": "Разгибания в блоке на квадрицепс",
        "muscle": "QUADS"
    },
    {
        "id": "303",
        "title": "Приседания в Смитте",
        "muscle": "QUADS"
    },
    {
        "id": "304",
        "title": "Выпады с гантелями",
        "muscle": "QUADS"
    },

    {
        "id": "400",
        "title": "Отведения ноги в блоке",
        "muscle": "GLUTES"
    },
    {
        "id": "401",
        "title": "Ягодичный мост",
        "muscle": "GLUTES"
    },
    {
        "id": "402",
        "title": "Глубокие приседания с широкой постановкой ног",
        "muscle": "GLUTES"
    },
    {
        "id": "403",
        "title": "Отведение ног в стороны в тренажере",
        "muscle": "GLUTES"
    },


    {
        "id": "500",
        "title": "Скручивания в римском стуле",
        "muscle": "CORE"
    },
    {
        "id": "501",
        "title": "Скручивания в висе на перекладине",
        "muscle": "CORE"
    },
    {
        "id": "502",
        "title": "Скручивания на полу",
        "muscle": "CORE"
    },


    {
        "id": "600",
        "title": "Жим со штангой лежа",
        "muscle": "CHEST"
    },
    {
        "id": "601",
        "title": "Жим со штангой лежа на наклонной скамье (45%)",
        "muscle": "CHEST"
    },
    {
        "id": "602",
        "title": "Бабочка",
        "muscle": "CHEST"
    },
    {
        "id": "603",
        "title": "Сведение рук в блоке",
        "muscle": "CHEST"
    },
    {
        "id": "604",
        "title": "Отжимания на брусья в грудном стиле",
        "muscle": "CHEST"
    },
    {
        "id": "605",
        "title": "Отжимания от пола",
        "muscle": "CHEST"
    },
    {
        "id": "606",
        "title": "Разведение зантелей",
        "muscle": "CHEST"
    },

    {
        "id": "700",
        "title": "Махи с гантелями в стороны",
        "muscle": "SHOULDERS"
    },
    {
        "id": "701",
        "title": "Армейский жим",
        "muscle": "SHOULDERS"
    },
    {
        "id": "702",
        "title": "Жим гантелей сидя",
        "muscle": "SHOULDERS"
    },
    {
        "id": "703",
        "title": "Протяжка к подбородку",
        "muscle": "SHOULDERS"
    },
    {
        "id": "704",
        "title": "Отведение руки в сторону в блоке",
        "muscle": "SHOULDERS"
    },
    {
        "id": "705",
        "title": "Махи с гантелями вперед",
        "muscle": "SHOULDERS"
    },
    {
        "id": "706",
        "title": "Махи с в стороны в наклоне",
        "muscle": "SHOULDERS"
    },
    {
        "id": "707",
        "title": "Обратная бабочка",
        "muscle": "SHOULDERS"
    },


    {
        "id": "800",
        "title": "Румынская тяга",
        "muscle": "HAMSTRINGS"
    },
    {
        "id": "801",
        "title": "Румынская тяга с гантелями",
        "muscle": "HAMSTRINGS"
    },
    {
        "id": "802",
        "title": "Сгибания ног в тренажере лежа",
        "muscle": "HAMSTRINGS"
    },
    {
        "id": "803",
        "title": "Тяга сумо",
        "muscle": "HAMSTRINGS"
    },
    {
        "id": "804",
        "title": "Сведение в тренажере на внут. поверхность",
        "muscle": "HAMSTRINGS"
    },


    {
        "id": "900",
        "title": "Обезьяний хват",
        "muscle": "FOREARMS"
    },
    {
        "id": "901",
        "title": "Скручивания кистей со штангой",
        "muscle": "FOREARMS"
    },
    {
        "id": "902",
        "title": "Подъем штанги кистями вниз",
        "muscle": "FOREARMS"
    },

    {
        "id": "1000",
        "title": "Скричивания шеи с блином",
        "muscle": "NECK"
    },


    {
        "id": "1100",
        "title": "Подтягивания",
        "muscle": "LATS"
    },
    {
        "id": "1101",
        "title": "Тяга Т-грифа",
        "muscle": "LATS"
    },
    {
        "id": "1102",
        "title": "Тяга гантели в наклоне",
        "muscle": "LATS"
    },
    {
        "id": "1104",
        "title": "Тяга в нижнем хаммере",
        "muscle": "LATS"
    },
    {
        "id": "1105",
        "title": "Тяга в верхнем хаммере",
        "muscle": "LATS"
    },
    {
        "id": "1106",
        "title": "Тяга в верхнего блока",
        "muscle": "LATS"
    },


    {
        "id": "1200",
        "title": "Экстензия",
        "muscle": "LOWER_BACK"
    },
    {
        "id": "1201",
        "title": "Становая тяга",
        "muscle": "LOWER_BACK"
    },


    {
        "id": "1300",
        "title": "Тяга нижнего",
        "muscle": "MIDDLE_BACK"
    },
    {
        "id": "1301",
        "title": "Тяга штанги в наклоне",
        "muscle": "MIDDLE_BACK"
    },

    {
        "id": "1400",
        "title": "Шраги с гантелями",
        "muscle": "TRAPS"
    },
    {
        "id": "1401",
        "title": "Шраги со штангой",
        "muscle": "TRAPS"
    }
]

export const ModalTypeInfo = "INFO";
export const ModalTypeAdd = "ADD";

export const DefaultExercise = () => {
    return {
        "id": uuidv4(),
        title: "",
        muscle: "BICEPS"
    }
}

export const DefaultModalState = {
    open: false,
    type: null,
    title: null
};

export const DefaultTraningProgram = () => {
    return {
        "id": uuidv4(),
        "title": "",
        "description": "",
        "exercises": []
    }
}

export const DefaultTraningPrograms = [
    {
        "id": "81e9883c-e41f-454a-97dd-be67d1a521fa",
        "title": "Ноги",
        "description": "Полноценнтая тренировка для прокачки всех мышц ног",
        "exercises": [
            {
                "id": "800",
                "sets": [
                    {
                        "repeats": 10,
                        "completed": false
                    },
                    {
                        "repeats": 10,
                        "completed": false
                    },
                    {
                        "repeats": 10,
                        "completed": false
                    }
                ]
            },
            {
                "id": "300",
                "sets": [
                    {
                        "repeats": 10,
                        "completed": false
                    },
                    {
                        "repeats": 10,
                        "completed": false
                    },
                    {
                        "repeats": 10,
                        "completed": false
                    }
                ]
            },
            {
                "id": "302",
                "sets": [
                    {
                        "repeats": 10,
                        "completed": false
                    },
                    {
                        "repeats": 10,
                        "completed": false
                    },
                    {
                        "repeats": 10,
                        "completed": false
                    }
                ]
            },
            {
                "id": "304",
                "sets": [
                    {
                        "repeats": 10,
                        "completed": false
                    },
                    {
                        "repeats": 10,
                        "completed": false
                    },
                    {
                        "repeats": 10,
                        "completed": false
                    }
                ]
            },
            {
                "id": "804",
                "sets": [
                    {
                        "repeats": 10,
                        "completed": false
                    },
                    {
                        "repeats": 10,
                        "completed": false
                    },
                    {
                        "repeats": 10,
                        "completed": false
                    }
                ]
            }
        ]
    }
]

export const DefaultTraning = () => {
    return {
        "id": uuidv4(),
        "notes": "",
        "traningProgramId": "",
        "duration": "",
        "startAt": "",
        "endAt": "",
        "exercises": [],
        "status": "ACTIVE",
        "rejectCause": "",
        "volume": 0,
        "progress": 0
    }
}

export const Statuses =
{
    NEW: {
        locale: "Новая"
    },
    ACTIVE: {
        locale: "В процессе"
    },
    COMPLETED: {
        locale: "Завершена"
    },
    REJECTED: {
        locale: "Отменена"
    }
}


export const DefaultSet = () => {
    return {
        repeats: 10,
        weight: 0,
        completed: false
    }
}

export const GenerateProgramExercise = (id: number) => {
    let setCount = 3;
    let sets = [];
    for (let i = 0; i < setCount; i++) {
        sets.push(DefaultSet())
    }
    return {
        id: id,
        sets: sets
    }
}

export const AppRoutes = [
    {
        path: "\/home|\/$",
        title: "Главная",
        needBack: false
    },
    {
        path: "\/traning$",
        title: "История тренировок",
        needBack: true
    },
    {
        path: "exercises$",
        title: "Упражнения",
        needBack: true,
        actionLink: "/exercises?create"
    },
    {
        path: "settings$",
        title: "Настройки",
        needBack: false
    },
    {
        path: "\/store\/traning-programs$",
        title: "Магазин",
        needBack: true
    },
    {
        path: "\/traning-programs$",
        title: "Программы",
        needBack: true,
        actionLink: "/traning-programs/create"
    },
    {
        path: "traning-programs\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$",
        title: "Программа тренировки",
        needBack: true
    },
    {
        path: "traning-programs\/create$",
        title: "Создание программы",
        needBack: true
    },
    {
        path: "traning-programs\/favorite$",
        title: "Мои программы",
        needBack: true
    },
    {
        path: "progress$",
        title: "Статистика",
        needBack: false
    },
    {
        path: "workout-process\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$",
        title: "Тренировка",
        needBack: true
    },
    {
        path: "\/debug",
        title: "Для разработчика",
        needBack: true
    },
    {
        path: "\/sign-in",
        title: "Авторизация",
        needBack: false
    },
    {
        path: "\/profile",
        title: "Профиль",
        needBack: true
    },
    {
        path: "\/schedules",
        title: "Расписания",
        needBack: true,
        actionLink: "/schedules?create"
    },
    {
        path: "\/food/random-recipe",
        title: "Случайный рецепт",
        needBack: false
    }
]

export const ThemeColors: { [key in keyof typeof ThemeAccent]: Color } = {
    yellow: {
        main: "#FFD400",
        light: "#FFEE99",
        dark: "#A38800"
    },
    orange: {
        main: "#FF9800",
        light: "#FFE0B2",
        dark: "#F57C00"
    },
    blue: {
        main: "#253C78",
        light: "#B1C0E7",
        dark: "#131F3E"
    },
    purple: {
        main: "#5F5980",
        light: "#BAB7CD",
        dark: "#353248"
    },
    green: {
        main: "#40531B",
        light: "#C5DC93",
        dark: "#242E0F"
    },
    red: {
        main: "#D6001C",
        light: "#E4938B",
        dark: "#74231B"
    }
}