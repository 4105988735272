import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FirebaseState = {
    token?: string
}

export const FirebaseInitialState: FirebaseState = {
    token: ""
}

export const FirebaseSlice = createSlice({
    name: "Firebase",
    initialState: FirebaseInitialState,
    reducers: {
        setToken: (state: FirebaseState, action: PayloadAction<string>) => {
            state.token = action.payload;
        }
    }
})

export const { setToken } = FirebaseSlice.actions;
export default FirebaseSlice.reducer;