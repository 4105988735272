import { Grid, Paper, Typography } from '@mui/material';
import * as React from 'react';
import { Workout } from '../../types/types';
import { FormatDate, FormatDateTime, FormatTime, GetHHmmSSFromDuration } from '../../utils/DateUtils';

interface IWorkoutInfoProps {
    workout: Workout
}

const WorkoutInfo: React.FunctionComponent<IWorkoutInfoProps> = ({workout}) => {
    return <Grid item xs={12}>
        <Paper sx={{
            p: 2,
            display: 'flex',
            flexDirection: "column"
        }}>
            <Typography variant='h6'>Дата: {FormatDate(new Date(workout.startAt))}</Typography>
            {workout.endAt && <Typography variant='caption'>{`${FormatTime(new Date(workout.startAt))} - ${FormatTime(new Date(workout.endAt))}`}</Typography>}
            {workout.duration &&<Typography variant='h6'>{`Продолжительность: ${GetHHmmSSFromDuration(workout.duration)}`}</Typography>}
        </Paper>
    </Grid>;
};

export default WorkoutInfo;
