import { DialogActions, DialogContent, DialogTitle, Dialog as MuiDialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';

interface IDialogProps {
    open: boolean,
    title: string,
    onClose(): void,
    children: any,
    actions?: any[]
}

const Transition = React.forwardRef<unknown, TransitionProps & {
    children: React.ReactElement
}>((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Dialog: React.FunctionComponent<IDialogProps> = ({open, onClose, title, children, actions}) => {
  return <MuiDialog open={open} TransitionComponent={Transition} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
        {children}
    </DialogContent>
    {actions && <DialogActions>
        {actions}
        </DialogActions>}
  </MuiDialog>;
};

export default Dialog;
