import { DaysOfWeek, MuscleEnum, ScheduleDuration, ScheduleType, TypeOfActivity } from "../types/types"
import { RecipeFilter } from "../types/RecipeTypes";

export const GetMuscleEnumArray = (): (keyof typeof MuscleEnum)[] => {
    return (Object.keys(MuscleEnum) as Array<keyof typeof MuscleEnum>);
}

export const GetScheduleTypeArray = (): (keyof typeof ScheduleType)[] => {
    return (Object.keys(ScheduleType) as Array<keyof typeof ScheduleType>);
}

export const GetSchedulerDurationArray = (): (keyof typeof ScheduleDuration)[] => {
    return (Object.keys(ScheduleDuration) as Array<keyof typeof ScheduleDuration>);
}

export const GetDaysOfWeekArray = (): (keyof typeof DaysOfWeek)[] => {
    return (Object.keys(DaysOfWeek) as Array<keyof typeof DaysOfWeek>);
}

export const GetRecipeFiltersArray = (): (keyof typeof RecipeFilter)[] => {
    return (Object.keys(RecipeFilter) as Array<keyof typeof RecipeFilter>);
}

export const GetTypeOfActivityArray = (): (keyof typeof TypeOfActivity)[] => {
    return (Object.keys(TypeOfActivity) as Array<keyof typeof TypeOfActivity>);
}