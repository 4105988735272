import { Box } from '@mui/system';
import React from 'react';
import MainButton from '../base/MainButton';
import RemoveEntityButton from '../base/RemoveEntityButton';
import ExerciseFrom from './ExerciseForm';
import { Kind } from '../base/EntityActionButtonGroup';

interface ExerciseInfoPros {
    onChange(e: any, s: string): void,
    exercise: any,
    onRemove(): void,
    onSave(): void
}

const ExerciseInfo = (props: ExerciseInfoPros) => {
    return <div>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: "column" }}>
            <ExerciseFrom
                kind={Kind.INFO}
                onRemove={props.onRemove}
                onSave={props.onSave}
                exercise={props.exercise}
                onChange={props.onChange} />
        </Box>
    </div>
};

export default ExerciseInfo;