import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ThemeAccent, ThemeInitialState, ThemePalette } from "../reduxTypes";
import { FeatureXmasThemeIsEnable } from "../utils/ProfileUtils";

const initialState: ThemeInitialState = {
    palette: ThemePalette.light,
    accent: ThemeAccent.green,
    enableXmaxTheme: false
}

export const ThemeSlice = createSlice({
    name: "ChangeThemePalette",
    initialState: initialState,
    reducers: {
        changePalette: (state) => {
            console.log("state", state.palette);
            if (state.palette === ThemePalette.light)
                state.palette = ThemePalette.dark;
            else
                state.palette = ThemePalette.light
        },
        changeAccent: (state, action: PayloadAction<ThemeAccent>) => {
            console.debug("STATE BEFORE", state.accent)
            state.accent = action.payload;
            console.debug("STATE AFTER", state.accent)
        },
        changeXmasTheme: (state) => {
            
            if (!state.enableXmaxTheme) {
                state.enableXmaxTheme = true
                state.accent = ThemeAccent.red
                state.palette = ThemePalette.dark
            } else {
                state.enableXmaxTheme = false
                state.accent = initialState.accent
                state.palette = initialState.palette
            }
        },
        disableXmasTheme: (state) => {
            state.enableXmaxTheme = false
            state.accent = initialState.accent
            state.palette = initialState.palette
        }

    }
})

export const { changePalette, changeAccent, changeXmasTheme, disableXmasTheme } = ThemeSlice.actions;

export default ThemeSlice.reducer;