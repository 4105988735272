import { Grow, Paper, Slide, Typography } from '@mui/material';
import * as React from 'react';
import { Feed } from '../../types/types';
import { UploadImage } from '../../utils/ImageUtils';

export interface IFeedItemProps {
    feed: Feed,
    onClick(): void
}

const FeedItem: React.FunctionComponent<IFeedItemProps> = (props) => {

    const [bgImage, setBgImage] = React.useState(null)
    UploadImage(props.feed.img)
        .then((path) => setBgImage(path))
        .catch(console.debug)

    return <Grow in timeout={250}>
        <Paper
            sx={{
                height: "100%",
                width: "100px",
                backgroundImage: bgImage ? `url(${props.feed.img})` : "none",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundColor: props.feed.color,
                filter: props.feed.imgFull && props.feed.useGrayFilter == undefined || props.feed.useGrayFilter == true ? "brightness(1) saturate(0%)" : "none",
                display: 'flex',
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",

            }}
            onClick={props.onClick}
        >
            <Typography sx={{
                width: "100%",
                color: "white",
                background: "rgba(0, 0, 0, 0.75)",
            }}>{props.feed.title}</Typography>
        </Paper>
    </Grow>;
};

export default FeedItem;
