import * as React from 'react';
import CreateTraningProgram from '../../program/CreateTraningProgram';
import { useNavigate } from 'react-router-dom';

const CreateTraningProgramScene = () => {
    const navigate = useNavigate();

    const onComplete = () => {
        navigate("/traning-programs/favorite")
    }

    return <CreateTraningProgram onComplete={onComplete} />;
};

export default CreateTraningProgramScene;
