import { Box, Typography, styled, useTheme } from '@mui/material';
import * as React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';



interface IInputFIleProps {
    onSelect(file: File): void
}

const InputFIle: React.FunctionComponent<IInputFIleProps> = ({ onSelect }) => {
    const theme = useTheme()
    const fileInputRef = React.useRef<HTMLInputElement>(null)
    const [file, setFile] = React.useState<File | null>()
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const files: FileList | null = (e.target as HTMLInputElement).files;
        if (files && files[0]) {
            setFile(files[0])
            onSelect(files[0])
        }
    };
    return <Box sx={{
        height: 200,
        width: "100%",
        border: `4px dashed ${theme.palette.secondary.main}`,
        borderRadius: 4,
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 1
    }}
        onClick={() => {
            if (fileInputRef && fileInputRef.current) {
                fileInputRef.current.click()
            }
        }}>
        <input ref={fileInputRef} hidden type="file" accept='application/JSON' onChange={handleFileChange} />
        {!file && <Box sx={{
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
        }}>
            <CloudUploadIcon color="secondary" />
            <Typography variant='caption'>Выберите файл</Typography>
        </Box>}
        {file && <Typography>{file.name}</Typography>}
    </Box>;
};

export default InputFIle;
