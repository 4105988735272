import { ListItem, ListItemButton, Slide } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux/Store';
import { useSelector } from 'react-redux/es/exports';
import MenuBox from '../../settings/MenuBox';
import { Box } from '@mui/system';
import { AuthState } from '../../../redux/AuthSlice';
import ThemeSettingsBox from '../../settings/ThemeSettingsBox';
import { FeatureAuthIsEnable, FeatureDonateIsEnable } from '../../../utils/ProfileUtils';
import DonateButton from '../../donate/DonateButton';


const AccountScene = () => {
    const navigation = useNavigate();

    const auth: AuthState = useSelector((state: RootState) => state.auth)
    return <div>
        <Slide in direction='up' timeout={250}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                {FeatureAuthIsEnable == true &&
                    <MenuBox title='Обо мне'>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigation("/profile")}>Профиль</ListItemButton>
                        </ListItem>
                        {!auth.authorized && <ListItem disablePadding>
                            <ListItemButton onClick={() => navigation("/sign-in")}>Войти</ListItemButton>
                        </ListItem>}
                    </MenuBox>
                }

                <ThemeSettingsBox />

                <MenuBox title="Для разработчика">
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigation("/debug")}>Данные для отладки</ListItemButton>
                    </ListItem>
                </MenuBox>

                {FeatureDonateIsEnable && <DonateButton />}
            </Box>
        </Slide>

    </div>
}

export default AccountScene;