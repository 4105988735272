import { Box, Grow, Paper, Typography } from '@mui/material';
import * as React from 'react';
import { StoredTraningProgram } from '../../types/ProgramStoreTypes';
import { JoinUniqueMuscleGroupsFromStoredExerciseItem } from '../../service/TraningProgramService';
import ProgramIcon from '../program/ProgramIcon';

interface ISubProgramCardProps {
    item: StoredTraningProgram
}

const SubProgramCard: React.FunctionComponent<ISubProgramCardProps> = (props) => {
    const muscleGroups: string = JoinUniqueMuscleGroupsFromStoredExerciseItem(props.item.exercises)
    return <Grow in timeout={250}>
        <Paper
            sx={{
                display: "flex",
                width: "260px",
                minHeight: "160px",
                maxHeight: "160px",
                flexDirection: 'column',
                p: 1
            }}>
            <Box sx={{ flexGrow: 4 }}>

                <Typography variant='caption'>Упражнений {props.item.exercises.length || 0}</Typography>

            </Box>
            <Box sx={{ flexGrow: 1 }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <Typography variant='subtitle2'>{props.item.title}</Typography>
                    <ProgramIcon typeOfActivity={props.item.typeOfActivity} />
                </Box>

            </Box>
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: "end", width: '100%' }}>
                <Typography variant='caption' sx={{ textOverflow: "ellipsis", overflow: 'hidden' }}>{muscleGroups}</Typography>
            </Box>
        </Paper>
    </Grow>;
};

export default SubProgramCard;
