import * as React from 'react';
import { Snackbar as MuiSnackbar, Slide, SlideProps } from '@mui/material';

interface ISnackbarProps {
    open: boolean,
    handleClose(): void,
    hideDuration?: number,
    message: string
}

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

const Snackbar: React.FunctionComponent<ISnackbarProps> = ({ open = false, handleClose, hideDuration = 0, message }) => {
    return <MuiSnackbar
        TransitionComponent={SlideTransition}
        open={open}
        onClose={handleClose}
        autoHideDuration={hideDuration}
        message={message}
    />;
};

export default Snackbar;
