import { Box, Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { GetExerciseById } from '../../api/ExercisesAPI';
import Modal from '../base/Modal';
import { DefaultSet, TypeOfActivityInfo } from '../Constants';
import ExercisesSelector from '../exercise/ExercisesSelector';
import SetsTable from './SetsTable';
import EntityActionButtonGroup, { Kind } from '../base/EntityActionButtonGroup';
import Selector from '../base/Selector';
import { Exercise, ExerciseItem, TraningProgram, TypeOfActivity } from '../../types/types';
import { GetTypeOfActivityArray } from '../../utils/EnumUtils';
import { handleChangeInput } from '../../hooks/InputHooks';
import { ICreateTraningProgram } from '../../service/TraningProgramService';
import { GenerateExercise } from '../../service/ExerciseService';
import { useSnackbar } from '../../hooks/SnackbarHooks';
import Snackbar from '../base/Snackbar';
import Dialog from '../base/Dialog';
import { useDialog } from '../../hooks/AppHooks';

interface ITraningProgramFormProps {
    traningProgram?: TraningProgram,
    kind: Kind,
    onRemove?(): void,
    onSave(command: ICreateTraningProgram): void,
}

const TraningProgramForm = ({ traningProgram, kind, onRemove, onSave }: ITraningProgramFormProps) => {
    const { values, handleChange, handleChangeSelect, setValues } = handleChangeInput<ICreateTraningProgram>({
        title: "",
        description: "",
        typeOfActivity: TypeOfActivity.STREINGTH_TRANING,
        exercises: []
    });
    const [isOpen, setOpen] = useState(false)
    const { open, handleClose, handleOpen, message, hideDuration } = useSnackbar();
    const { openDialog, handleCloseDialog, handleOpenDialog } = useDialog()

    React.useEffect(() => {
        if (traningProgram) {
            setValues({
                title: traningProgram.title,
                description: traningProgram.description,
                typeOfActivity: traningProgram.typeOfActivity ? traningProgram.typeOfActivity : TypeOfActivity.STREINGTH_TRANING,
                exercises: traningProgram.exercises
            })
        }
    }, [traningProgram])

    const isSelected = (id: string) => {
        return values.exercises.find(e => e.id == id) == null ? false : true
    }

    const handleCheckItem = (id: string) => {
        if (!isSelected(id)) {
            setValues({
                ...values,
                exercises: [...values.exercises, GenerateExercise(id)]
            })
        } else {
            setValues({
                ...values,
                exercises: values.exercises.filter(e => e.id != id)
            })
        }
    }

    const onClose = () => {
        handleChangeOpen();
    }

    const handleChangeOpen = () => {
        setOpen(!isOpen)
    }


    const handleChangeRepeats = (e: any, exercise: Exercise, index: number) => {
        let target: ExerciseItem | undefined = values.exercises.find(e => e.id == exercise.id);
        if (!e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/) || !target)
            return;
        target.sets[index].repeats = e.target.value
        setValues({
            ...values,
            exercises: values.exercises.map((e: ExerciseItem) => {
                if (target && e.id == target.id)
                    return target;

                return e;
            })
        })
    }

    const addSet = (exercise: ExerciseItem) => {
        let target = values.exercises.find(e => e.id == exercise.id);
        if (!target) {
            return;
        }
        target.sets.push(DefaultSet());
        setValues({
            ...values,
            exercises: values.exercises.map(e => {
                if (target && e.id == target.id)
                    return target;

                return e;
            })
        })
    }

    const onSaveProgram = () => {
        console.log("on save")
        onSave(values);
        handleOpen("Данные успешно обновлены", 3000)
    }

    return <Box>
        <EntityActionButtonGroup
            kind={kind}
            onEdit={handleChangeOpen}
            onRemove={handleOpenDialog}
            onSave={onSaveProgram}
            shareItem={values}
            editable
            onShare={() => console.debug("Shared")}
        />

        <TextField
            onChange={handleChange}
            name="title"
            fullWidth
            id="exercise-title"
            label="Название"
            variant="standard"
            value={values.title}
            sx={{ pt: 1, pb: 1 }} />

        <Selector
            label="Тип"
            name="typeOfActivity"
            value={values.typeOfActivity}
            handleChange={handleChangeSelect}
            items={GetTypeOfActivityArray().map((type, idx) => {
                return <MenuItem
                    key={idx}
                    value={type}>
                    {TypeOfActivityInfo[type].locale}
                </MenuItem>
            })} />

        {values.exercises.length == 0 && <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 2 }}>
            <Typography variant='caption'>Упражнения не добавлены</Typography>
        </Box>}


        {values.exercises.length > 0 && <Grid container spacing={2}>
            {values.exercises.map((exercise, idx) => {
                const item = GetExerciseById(exercise.id);
                return <Grid item xs={12} key={idx}>
                    <SetsTable
                        item={item}
                        sets={exercise.sets}
                        handleChangeInput={handleChangeRepeats}
                        handleChangeFloat={() => { }}
                        handleChangeCompleted={() => { }}
                        infoMode={false}
                        disableWeigth disableCompleted inputMode
                        addSet={addSet}
                        replaceExercise={undefined}
                    />
                </Grid>
            })}
        </Grid>
        }

        <Modal
            open={isOpen}
            onClose={onClose}
            title="Добавление упражнений">
            <ExercisesSelector
                handleCheckItem={handleCheckItem}
                isSelected={isSelected}
                selected={values.exercises}
            />
        </Modal>

        <Snackbar hideDuration={hideDuration} message={message} handleClose={handleClose} open={open} />

        <Dialog
            open={openDialog}
            onClose={handleClose}
            title="Удаление программы"
            actions={[
                <Button key={0} onClick={handleCloseDialog}>Отмена</Button>,
                <Button key={1} color='secondary' onClick={() => {
                    if (onRemove) {
                        onRemove()
                    }
                }}>Удалить</Button>

            ]}>
            <Typography>Вы уверены, что хотите удалить программу тренировок?</Typography>
        </Dialog>

    </Box >
}

export default TraningProgramForm;