export type Recipe = {
    id: string,
    title: string,
    portions: number,
    mainImgUrl?: string,
    ingridients: Ingridient[],
    steps: Step[],
    duration: number,
    filters: RecipeFilter[],
    authorId?: string
}

export type Step = {
    description: string,
    imgUrl?: string
}

export type Ingridient = {
    foodProduct: FoodProduct,
    quantity: number,
    unit: Unit
}

export type FoodProduct = {
    id: string,
    title: string,
    nutrition: Nutrition
}

export enum Unit {
    GRAMMS = "гр",
    PIECE = "шт",
    SPOONS = "ложка",
    LITER = "л",
    CUP = "ст",
    TASTE = "по вкусу"
}

export enum RecipeFilter {
    GLUTEN_FREE = "без глютена",
    LACTOSE_FREE = "без лактозы",
    // SUGAR_FREE = "без сахара",
    HIGHT_PROTEIN = "много белка",
    // NOT_HOT = "не остро",
    // HOT = "остро",
    // SOUR = "кисло",
    SWEET = "сладко",
    CHEAT_MEAL = "читмил"
}

export type Nutrition = {
    proteins: number,
    fats: number,
    carbs: number,
    calories: number
}