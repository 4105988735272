import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Fade, Typography, keyframes, useTheme } from "@mui/material"
import { FeatureXmasThemeIsEnable, IsProd } from '../../utils/ProfileUtils';

const myKeyframes = keyframes`
0% {
    background-position: 0% 50%;
},
50% {
    background-position: 100% 50%;
},
100% {
    background-position: 0% 50%;
}
`

const Splash = () => {
    const theme = useTheme();
    return <Box
        sx={{
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            position: 'absolute',
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            zIndex: 10000,
            backgroundColor: `#343434`,
            backgroundImage: FeatureXmasThemeIsEnable ? `url(https://firebasestorage.googleapis.com/v0/b/gym-tracker-8d7ac.appspot.com/o/emoji-d6001c-390x8443x.png?alt=media&token=02ead866-97ee-434e-a2bc-9e6c3c50cb78)` 
            : `none`,
            backgroundSize: 'cover',
            backgroundPosition: "center",
            filter: `blur(0,1)`

        }}>
        <Fade
            in={true}
            timeout={1000}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                <Box sx={{
                    backgroundImage: FeatureXmasThemeIsEnable ? `url(${process.env.PUBLIC_URL}/assets/logo_black.svg)` : `url(${process.env.PUBLIC_URL}/assets/logo_red.svg)`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: 200,
                    width: 200,
                    zIndex: 10001
                }} />
                <CircularProgress color="inherit" size={230} thickness={2.0} sx={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    margin: "0 auto"
                }} />
                <Typography variant='caption' sx={{
                    position: "absolute",
                    bottom: 60,
                    color: "#edf2f4"
                }}>produced by <b>Vladimir Kozlov</b></Typography>
            </Box>
        </Fade>
    </Box>
}

export default Splash;