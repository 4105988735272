import { ThemePalette } from "../reduxTypes"
import { ProgramStoreItem } from "./ProgramStoreTypes"

export enum TypeOfActivity {
    CARDIO = "CARDIO",
    STREINGTH_TRANING = "STREINGTH_TRANING",
    FUNCTIONAL_TRANING = "FUNCTIONAL_TRANING",
    STRECHING = "STRECHING"
}

export type Workout = {
    id: string,
    notes?: string,
    traningProgramId: string,
    duration?: number,
    startAt: Date,
    endAt?: Date,
    exercises: ExerciseItem[],
    status: WorkoutStatus,
    rejectCause?: string,
    volume?: number,
    progress?: number,
    typeOfActivity?: TypeOfActivity
}

export type Exercise = {
    id: string,
    title: string,
    muscle: MuscleEnum,
    imgUrls?: string[],
    description?: string
}

export enum WorkoutStatus {
    ACTIVE = "ACTIVE",
    NEW = "NEW",
    COMPLETED = "COMPLETED",
    REJECTED = "REJECTED"
}

export type TraningProgram = {
    id: string,
    title: string,
    description?: string,
    exercises: ExerciseItem[],
    typeOfActivity?: TypeOfActivity,
    duration?: number,
    storeItemId?: string
}

export type ExerciseItem = {
    id: string,
    sets: WorkoutSet[],
    volume: number
}

export type WorkoutSet = {
    repeats: number,
    weight: number,
    completed: boolean
}

export type DateRange = {
    from: Date,
    to: Date
}

export enum MuscleEnum {
    BICEPS = "BICEPS",
    TRICEPS = "TRICEPS",
    FOREARMS = "FOREARMS",
    QUADS = "QUADS",
    GLUTES = "GLUTES",
    HAMSTRINGS = "HAMSTRINGS",
    CORE = "CORE",
    CHEST = "CHEST",
    SHOULDERS = "SHOULDERS",
    NECK = "NECK",
    LATS = "LATS",
    LOWER_BACK = "LOWER_BACK",
    MIDDLE_BACK = "MIDDLE_BACK",
    TRAPS = "TRAPS"
}

export enum MuscleGroupEnum {
    ARM = 1,
    LEGS = 2,
    CORE = 3,
    CHEST = 4,
    SHOULDERS = 5,
    BACK = 6
}

export type AppSettings = {
    palette: ThemePalette
}

export type Feed = {
    id: number,
    img?: string,
    imgFull?: string,
    color?: string,
    text?: any,
    title: string,
    action?: any,
    enable: boolean,
    useGrayFilter?: boolean
}

export type FeedAction = {
    link: string,
    text?: string
}

export type Color = {
    light: string,
    dark: string,
    main: string,
    contrastText?: string
}

export type Statistic = {
    workoutCount: number,
    fullCompleteCount: number,
    fullVolume: number,
    fullDuration: number,
    fullSets: number,
    rejectedCount: number,
    tonnageData: TonnageData,
    durationData: DurationData,
    setsData: MuscleData[],
    volumeData: MuscleData[],
    exerciseData: ExerciseData[],
    avgDuration: number
}

export type WorkoutStatistic = {
    muscles: ExerciseStatistic[]
}

export type ExerciseStatistic = {
    muscle: MuscleEnum,
    volume: number,
    setsCount: number
}


export type ExerciseData = {
    exercise: Exercise,
    data?: VolumeInfo[]
}

export type VolumeData = {
    muscle: MuscleEnum,
    data?: VolumeInfo[]
}

export type VolumeInfo = {
    date?: string,
    value: number
}

export type MuscleData = {
    muscle: MuscleEnum,
    data?: SetsInfo[]
}

export type SetsInfo = {
    date?: string,
    value: number
}

export type DurationData = {
    max: number,
    data?: DurationInfo[]
}

export type DurationInfo = {
    date?: string,
    duration?: number
}

export type TonnageData = {
    max?: number,
    data?: TonnageInfo[]
}

export type TonnageInfo = {
    date?: string,
    volume?: number
}

export type Schedule = {
    id: string,
    title: string,
    type: ScheduleType,
    enableNotify: boolean,
    status: ScheduleStatus,
    startAt: Date,
    endAt: Date,
    programs: ScheduleWorkoutProgram[],
    description: string | ""
}

export enum WeekNumber {
    FIRST_WEEK = "FIRST_WEEK",
    SECOND_WEEK = "SECOND_WEEK"
}

export type ScheduleWorkoutProgram = {
    id: string,
    dayOfWeek: DaysOfWeek,
    weekNumber: WeekNumber
}

export enum ScheduleStatus {
    INACTIVE = "INACTIVE",
    STARTED = "STARTED",
    COMPLETED = "COMPLETED"
}

export enum ScheduleType {
    ONE_WEEK = "ONE_WEEK",
    TWO_WEEK = "TWO_WEEK"
}

export enum ScheduleDuration {
    ONE_WEEK = "ONE_WEEK",
    TWO_WEEK = "TWO_WEEK",
    MONTH = "MONTH",
    TWO_MONTH = "TWO_MONTH",
    THREE_MONTH = "THREE_MONTH",
    SIX_MONTH = "SIX_MONTH",
    YEAR = "YEAR",
    INFINITY = "INFINITY"
}

export enum DaysOfWeek {
    MONDAY = "MONDAY",
    THUESDAY = "THUESDAY",
    WEDNESDAY = "WEDNESDAY",
    THURSDAY = "THURSDAY",
    FRIDAY = "FRIDAY",
    SATURDAY = "SATURDAY",
    SUNDAY = "SUNDAY"
}
