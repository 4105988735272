import { Box, Button, IconButton, Link } from '@mui/material';
import * as React from 'react';
import ImportExportIcon from '@mui/icons-material/Download';
import { FeatureExportProgramIsEnable } from '../../utils/ProfileUtils';

interface IShareProgramProps {
    item: any,
    name?: string,
    onShare?(): void
}

const ShareProgram: React.FunctionComponent<IShareProgramProps> = ({ item, onShare, name }) => {
    const fileName = name ? name : item + ".json";
    const json = JSON.stringify(item);
    const blob = new Blob([json], { type: "application/json" })
    const href = URL.createObjectURL(blob);

    FeatureExportProgramIsEnable

    return FeatureExportProgramIsEnable? <Link href={href} download={fileName} onClick={onShare}>
        <Button variant='outlined' color='secondary'>
            <ImportExportIcon />
        </Button>
    </Link> : <div></div>
};

export default ShareProgram;
