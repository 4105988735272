import { Box, Button } from '@mui/material';
import * as React from 'react';
import { isIOS } from 'react-device-detect';

interface IInstallPWAProps {
}

const InstallPWA: React.FunctionComponent<IInstallPWAProps> = (props) => {

    const [supportsPWA, setSupportsPWA] = React.useState(false);
    const [promptInstall, setPromptInstall] = React.useState<any>(null);

    React.useEffect(() => {
        const handler = (e: any) => {
            e.preventDefault();
            console.log("we are being triggered :D");
            setSupportsPWA(true);
            setPromptInstall(e);
        };
        window.addEventListener("beforeinstallprompt", handler);

        return () => window.removeEventListener("transitionend", handler);
    }, []);

    const onClick = (evt: any) => {
        evt.preventDefault();
        if (!promptInstall) {
            return;
        }
        promptInstall.prompt();
    };

    const isInstalled = () => {
        if (('standalone' in window.navigator) && (window.navigator['standalone']) || window.matchMedia("(display-mode: standalone)").matches) {
            console.debug("isInstalled: true. Already in standalone mode");
            return true;
        }
        console.debug("isInstalled: false.");
        return false;
    }

    if (!supportsPWA && isInstalled() && isIOS) {
        return null;
    }

    return <Box sx={{
        display: 'flex',
        justifyContent: "end",
        pb: 1
    }}>
        <Button onClick={onClick} variant='contained' color='secondary'>Установить</Button>
    </Box>;
};

export default InstallPWA;
