import { Box, Button } from '@mui/material';
import * as React from 'react';
import InputFIle from './InputFile';
import { useModal } from '../../hooks/AppHooks';
import Modal from './Modal';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { ModalType } from '../../appTypes';
import { ImportByEntityName, ImportResult } from '../../service/ImportEntityService';


interface IImportEntityProps {
    title: string,
    entityName: string,
    afterImport(): void
}

const ImportEntity: React.FunctionComponent<IImportEntityProps> = ({ title, entityName, afterImport }) => {

    const { modal, handleOpen, handleClose } = useModal();
    const [entityJSON, setEntityJSON] = React.useState<string>("{}")

    const handleChangeFile = (file: File) => {
        const fileReader = new FileReader();
        fileReader.readAsText(file, "UTF-8");
        fileReader.onload = (e: ProgressEvent<FileReader>) => {
            if (e.target) {
                console.log("e.target.result", e.target.result);
                if (typeof e.target.result === "string") {
                    setEntityJSON(e.target.result);
                }
            }

        };
    }

    const onConfirm = () => {
        ImportByEntityName(entityName, entityJSON)
            .then(res => {
                handleClose()
                afterImport()
            })
            .catch((e: any) => {
                setEntityJSON("{}")
            })
    }

    return <Box p={1}>
        <Button
            fullWidth
            variant='contained'
            onClick={() => handleOpen(title, ModalType.INFO)}
            color="secondary"
            endIcon={<CloudUploadIcon />}>
            Импортировать
        </Button>

        <Modal
            open={modal.open}
            onClose={() => handleClose()}
            title={modal.title}
        >
            <InputFIle onSelect={handleChangeFile} />

            <Button fullWidth variant="contained" color="secondary" sx={{mt: 1}} onClick={onConfirm}>Подтвердить</Button>
        </Modal>
    </Box>;
};

export default ImportEntity;
