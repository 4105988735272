import * as React from 'react';
import { GetFavoritePrograms, GetPopularProgramsFormStore } from '../../service/TraningProgramService';
import { TraningProgram } from '../../types/types';
import { Box, Grow, Typography } from '@mui/material';
import SeeAll from '../base/SeeAll';
import HorizontalFeedLine from '../base/HorizontalElementLine';
import TraningProgramCard from './TraningProgramCard';
import { useNavigate } from 'react-router-dom';

interface IFavoriteProgramsProps {
}

const FavoritePrograms: React.FunctionComponent<IFavoriteProgramsProps> = (props) => {
    const [programs, setPrograms] = React.useState<TraningProgram[]>([])
    const navigate = useNavigate();

    React.useEffect(() => {
        setPrograms(GetFavoritePrograms(5))
    }, [])

    return <Box sx={{
        display: 'flex',
        gap: 1,
        flexDirection: "column"
    }}>
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between'
        }}>
            <Typography variant='h6'>Мои программы</Typography>
            <SeeAll navigateTo='/traning-programs/favorite' />

        </Box>
        <HorizontalFeedLine height={180}>
            {programs.map((program, idx) =>
                <TraningProgramCard sx={{
                    width: "280px"
                }} withCount key={idx} item={program} onClick={() => { navigate(`/traning-programs/${program.id}`) }} />
            )}
        </HorizontalFeedLine>
    </Box>
};

export default FavoritePrograms;
