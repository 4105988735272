import { Box, Grid, Typography } from '@mui/material';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import TraningProgramCard from "./TraningProgramCard";
import { TraningProgram } from '../../types/types';
import { useNavigate } from 'react-router-dom';
import { GetGroupedPrograms } from '../../service/TraningProgramService';
import { GetProgramStoreItemById } from '../../api/ProgramStoreAPI';

export interface TraningProgramListProps {
}

const TraningProgramList = ({ }: TraningProgramListProps) => {
    const navigate = useNavigate();
    const [groupedPrograms, setGroupedPrograms] = useState<{ [key in string]: TraningProgram[] }>({})

    useEffect(() => {
        setGroupedPrograms(GetGroupedPrograms())
    }, [])

    const onClickToProgram = (program: TraningProgram) => {
        navigate(`/traning-programs/${program.id}`)
    }

    return <Box sx={{
        display: 'flex',
        flexDirection: "column",
        gap: 1
    }}>
        {Object.keys(groupedPrograms) && Object.keys(groupedPrograms).map((storeItemId, idx) => {
            const storeItem = GetProgramStoreItemById(storeItemId);
            return <Box key={idx} sx={{
                display: 'flex',
                gap: 1,
                flexDirection: "column"
            }}>
                {storeItem ? <Typography variant='h5'>{storeItem.title}</Typography> : <Typography variant='h5'>Пользовательские</Typography>}
                <Grid container spacing={2}>
                    {groupedPrograms[storeItemId].map((program: TraningProgram, idx: number) => <Grid key={idx} item xs={6}>
                        <TraningProgramCard item={program} onClick={(e: SyntheticEvent) => { onClickToProgram(program) }} />
                    </Grid>)}
                </Grid>
            </Box>
        })}
    </Box>
}

export default TraningProgramList;