import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux/es/exports';
import { RootState } from '../../redux/Store';
import { IRouterSlice } from '../../reduxTypes';
import { getRouteInfo } from '../../utils/RoutesUtils';
import { useNavigate } from 'react-router-dom';
import { AddCircle } from '@mui/icons-material';

export interface INavBarProps {
}

export function NavBar(props: INavBarProps) {
    const routeInfo: IRouterSlice = useSelector((state: RootState) => state.router)
    const navigate = useNavigate();
    const [title, setTitle] = useState("")
    const [needBack, setNeedBack] = useState(false)
    const [actionLink, setActionLink] = useState("")
    useEffect(() => {
        const r: IRouterSlice = getRouteInfo(routeInfo.path);
        setTitle(r.title);
        setNeedBack(r.needBack);
        if (r.actionLink)
            setActionLink(r.actionLink)
        else {
            setActionLink("");
        }
    }, [routeInfo])

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" sx={{ bgcolor: 'background.default', boxShadow: "none" }}>
                <Toolbar sx={{ justifyContent: "space-between" }}>
                    {needBack && <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={() => navigate(-1)}>
                        <ArrowBackIcon />
                    </IconButton>}
                    <Typography variant="h6" color="inherit" component="div">
                        {title}
                    </Typography>
                    {actionLink.length > 0 && <IconButton edge='end' onClick={() => navigate(actionLink)}>
                        <AddCircle />
                    </IconButton>
                    }
                </Toolbar>
            </AppBar>
        </Box>

    );
}
