import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { Ingridient } from '../../types/RecipeTypes';

interface IIngridientsTableProps {
    ingridients: Ingridient[]
}

const IngridientsTable: React.FunctionComponent<IIngridientsTableProps> = ({ingridients}) => {
  return <TableContainer component={Paper}>
    <Table>
        <TableBody>
            {ingridients.map((item, idx) => <TableRow key={idx}>
                <TableCell>
                    {item.foodProduct.title}
                </TableCell>
                <TableCell>
                    {item.quantity} - {item.unit}
                </TableCell>
            </TableRow>)}
        </TableBody>
    </Table>
  </TableContainer>;
};

export default IngridientsTable;
