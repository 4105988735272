import { Typography, List, ListItemButton, ListItemText, TextField, InputAdornment } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";

import { Box } from "@mui/system";
import { ModalTypeInfo, MuscleGroupsInfo } from "../Constants";
import { GetExercises, SearchExcersises } from "../../api/ExercisesAPI";
import SearchIcon from '@mui/icons-material/Search';
import { Exercise, ExerciseItem, MuscleEnum } from "../../types/types";

interface ExerciseListProps {
    onClick(type: any, exercice: any): void,
    query: string,
    exercisesByMuscle: any,
    handleChange(e: any): void
}

const ExerciseList = (props: ExerciseListProps) => {

    const onClickToExercise = async (e: ChangeEvent<HTMLInputElement>, exercise: any) => {
        e.stopPropagation();
        await props.onClick(ModalTypeInfo, exercise);
    }


    return <Box sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        justifyContent: "center"
    }}>
        <Box>
            <TextField
                InputProps={{
                    startAdornment: <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                }}
                value={props.query}
                placeholder="Поиск"
                fullWidth
                name="query"
                onChange={props.handleChange} />
        </Box>
        {Object.keys(props.exercisesByMuscle).length !== 0 && Object.entries(props.exercisesByMuscle).map((key: any, value: number) => {
            var k: keyof typeof MuscleEnum = key[0];
            return <Box key={value}>
                <Box>
                    <Typography variant="h5">{MuscleGroupsInfo[k].locale}</Typography>
                </Box>
                <List>
                    {key[1].map((v: any, idx: number) => <ListItemButton key={idx} onClick={(e: any) => onClickToExercise(e, v)}>
                        <ListItemText primary={v.title} />
                    </ListItemButton>)
                    }
                </List>
            </Box>
        })}
    </Box>
}

export default ExerciseList;