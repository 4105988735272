import { Box, Button, IconButton, Typography } from '@mui/material';
import * as React from 'react';
import Slider, { Settings } from "react-slick";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { current } from '@reduxjs/toolkit';

interface ICarouselProps {
    children: any[],
    index: number,
    updateIndex(index: number): void
}

const Carousel: React.FunctionComponent<ICarouselProps> = ({ children, index, updateIndex }) => {
    let sliderRef = React.useRef() as React.MutableRefObject<Slider>

    const next = () => {
        if (sliderRef.current != null)
            sliderRef.current.slickNext();
    };
    const previous = () => {
        if (sliderRef.current != null)
            sliderRef.current.slickPrev();
    };

    var settings : Settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        vertical: false,
        centerPadding: "60px",
        afterChange: current => updateIndex(current + 1)
    };
    return <div className="slider-container">
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        }}>
            <IconButton onClick={previous}>
                <ArrowBackIosNewIcon />
            </IconButton>

            <Typography>{index}/{children.length}</Typography>

            <IconButton onClick={next}>
                <ArrowForwardIosIcon />
            </IconButton>
        </Box>
        <Slider ref={sliderRef} {...settings}>

            {children}

        </Slider>


    </div>
};

export default Carousel;
