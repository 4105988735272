import { TraningProgram } from "../types/types";

export const GetTraningPrograms = () => {
    var items: string = localStorage.getItem("traning_programs") || "";
    var result: TraningProgram[] = items.length > 0 ? JSON.parse(items) : []
    return result;
}

export const SetTraningPrograms = (traningPrograms: TraningProgram[]) => {
    console.debug("Update traning program in local storage...")
    localStorage.setItem("traning_programs", JSON.stringify(traningPrograms))
}

export const AddTraningProgram = (traningProgram: TraningProgram) => {
    const items = GetTraningPrograms();
    items.push(traningProgram);
    SetTraningPrograms(items);
}

export const RemoveTraningProgram = (id: string) => {
    const items = GetTraningPrograms()
        .filter(exercise => exercise.id != id);
    SetTraningPrograms(items);
}

export const UpdateTraningProgram = (traningProgram: TraningProgram) => {
    const items = GetTraningPrograms().map(e => {
        if (e.id == traningProgram.id) {

            return traningProgram;
        }

        return e;
    })
    SetTraningPrograms(items);
}


export const GetTraningProgramById = (id: string) => {
    const exercises = GetTraningPrograms();
    return exercises.find(e => e.id == id);
}

export const SaveTraningProgram = (program: TraningProgram) => {
    let items: TraningProgram[] = GetTraningPrograms()
    let target = items.find(s => s.id == program.id);

    if (!target) {
        items.push(program)
    } else {
        items = items
            .map(s => {
                if (s.id == program.id) {
                    return program;
                }
                return s;
            });
    }
    SetTraningPrograms(items);
}