import { Snackbar } from '@mui/material';
import { MessagePayload } from 'firebase/messaging';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setToken } from '../../redux/FirebaseSlice';
import { onMessageListener, RequestForToken } from '../../service/FirebaseService';

type Notify = {
  title?: string,
  description?: string,
  icon?: string
}

const initialState: Notify = {
  title: "",
  description: ""
}

const NotificationComponent = () => {
  const [showNotify, setShowNotify] = useState(false)
  const [notification, setNotification] = useState(initialState)
  const dispatch = useDispatch();

  useEffect(() => {

    if (!("Notification" in window)) {
      console.debug("This browser does not support desktop notification")
    } else {
      Notification.requestPermission()
        .then((permission) => {
          if (permission === 'granted')
            RequestForToken()
              .then((currentToken: any) => {
                dispatch(setToken(currentToken))
              });
        })
        .catch((err) => {
          console.debug("Permission not allowed", err)
        })
    }
  }, [])

  onMessageListener()
    .then((payload: MessagePayload) => {
      if (payload.data != undefined) {
        setNotification({ title: payload.data.title, description: payload.data.body })
        setShowNotify(true)
      }
    })
    .catch((err) => console.debug('failed: ', err));

  const onClose = () => {
    setNotification(initialState)
    setShowNotify(!showNotify)
  }
  return <Snackbar
    autoHideDuration={3000}
    anchorOrigin={{ horizontal: "center", vertical: "top" }}
    open={showNotify}
    onClose={onClose}
    message={notification.description} />
}

export default NotificationComponent;