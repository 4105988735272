import * as React from 'react';
import { FeatureXmasThemeIsEnable } from '../../utils/ProfileUtils';
import { FormControlLabel, FormGroup, ListItem, Switch } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { changeXmasTheme } from '../../redux/ThemeSlice';
import { RootState } from '../../redux/Store';

interface IXmasThemeSettingBoxProps {
}

const XmasThemeSettingItem: React.FunctionComponent<IXmasThemeSettingBoxProps> = (props) => {
    if (!FeatureXmasThemeIsEnable)
        return <div></div>;

    const dispatch = useDispatch();
    const xmasThemeEnabled: boolean = useSelector((state: RootState) => state.themePalette.enableXmaxTheme);

    return <ListItem disablePadding>
        <FormGroup>
            <FormControlLabel
                control={<Switch color='secondary' checked={xmasThemeEnabled} onChange={() => dispatch(changeXmasTheme())} />}
                label="Новогодняя тема" />
        </FormGroup>
    </ListItem>;
};

export default XmasThemeSettingItem;
