import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import * as React from 'react';
import { Nutrition, Recipe } from '../../types/RecipeTypes';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import NutritionTable from './NutritionTable';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import { CalculateNutrition } from '../../service/RecipeService';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/Store';
import { addFavoriteDish, removeFavoriteDish } from '../../redux/UserSlice';
import Favorite from '../base/Favorite';

interface IRecipeCommonInfoProps {
    recipe: Recipe
}

const RecipeCommonInfo: React.FunctionComponent<IRecipeCommonInfoProps> = ({ recipe }) => {
    const dispatch = useDispatch();
    const favoriteDishes = useSelector((state: RootState) => state.user.favoriteDishes);
    const [nutrition, setNutrition] = React.useState<Nutrition>({
        fats: 0,
        proteins: 0,
        calories: 0,
        carbs: 0
    })

    React.useEffect(() => {
        setNutrition(CalculateNutrition(recipe))
    }, [])

    const handleChangeFavorite = () => {
        if (favoriteDishes.includes(recipe.id)) {
            dispatch(removeFavoriteDish(recipe.id))
        } else {
            dispatch(addFavoriteDish(recipe.id))
        }
    }

    return <Box sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2
    }}>
        <Box sx={{
            display: 'flex',
            alignItems: "center",
            justifyContent: "center",
            gap: 4
        }}>
            <Box sx={{
                display: 'flex',
                alignItems: "center",
                gap: 1
            }}>
                <SoupKitchenIcon />
                <Typography variant='caption'>{recipe.portions} шт.</Typography>
            </Box>

            <Favorite isFavorite={favoriteDishes.includes(recipe.id)} onClick={handleChangeFavorite}/>

            <Box sx={{
                display: 'flex',
                alignItems: "center",
                gap: 1
            }}>
                <AccessTimeIcon />
                <Typography variant='caption'>{recipe.duration} мин.</Typography>
            </Box>
        </Box>

        {recipe.mainImgUrl && <Box sx={{
            height: "200px",
            width: "100%",
            backgroundImage: `url(${recipe.mainImgUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center"
        }}></Box>}
        <Box>
            <NutritionTable nutrition={nutrition} />
        </Box>
    </Box>;
};

export default RecipeCommonInfo;
